import { RELOAD_MANAGE_DOCS_GRID } from "@AppConstants";
import { ProgressBarByChunksDialog } from "@Components/Dialogs/ProgressBarDialogs/ProgressBarByChunksDialog";
import { useModalContext } from "@Components/Modal/ModalContext";
import { modalService } from "@Components/Modal/ModalService";
import { Form, useForm } from "@Libs/Form";
import { DocumentInfoModel, DocumentUpdateModel } from "@Models";
import { checkFormValid } from "@Pages/Project/SubPages/ProjectDetails/Actions";
import { AxiosPromise } from "axios";
import { memo, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { DocumentOwnerFullName } from "../../../DocumentOwnerFullName/DocumentOwnerFullName";
import { FinancialEntryDocumentStore } from "../../../LabOfferRequests/FinancialEntryDocumentStore";
import { DocType, DocumentForm } from "../DocumentForm/DocumentForm";
import { documentFormLabels, DocumentFormModel, documentFormValidationSchema, initUpdateDocumentForm } from "../DocumentForm/DocumentFormModel";
import { EditDocumentDialogProps } from "./EditDocumentDialog";

type EditDocumentDialogBodyProps = EditDocumentDialogProps & {
    document: DocumentInfoModel;
    updateDocument: (model: DocumentUpdateModel) => AxiosPromise<DocumentInfoModel>;
};

export const EditDocumentDialogBody = memo(({ document: doc, store, documentOwnerId, documentTypeName, updateDocument, defaultState, onUpdate }: EditDocumentDialogBodyProps) => {
    const form = useForm(() => initUpdateDocumentForm(doc), documentFormValidationSchema, documentFormLabels);
    const [ownerName, setOwnerName] = useState('');
    const { close } = useModalContext();
    const isFinancialEntryDocument = store instanceof FinancialEntryDocumentStore;

    useEffect(() => {
        store.loadDocumentOwnerFullName(documentOwnerId).then(({ data }) => setOwnerName(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <DocumentOwnerFullName name={ownerName} />
            <DocumentForm form={form} defaultState={defaultState} isNameHidden={isFinancialEntryDocument} />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="sm"
                    style={{ marginRight: 10 }}
                    onClick={close}
                >
                    Close
                </Button>
                <Button
                    size="sm"
                    color="success"
                    onClick={async () => {
                        if (isFinancialEntryDocument)
                            documentFormValidationSchema.documentName = undefined;
                        if (!checkFormValid(form)) return;
                        const updateddocument = await handleUpdateClick(doc, documentTypeName, form, updateDocument);
                        if (form.formFields.docType === DocType.File && updateddocument) {
                            if (form.formFields.docType === DocType.File && form.formFields.documentFile) {
                                await modalService.show(ProgressBarByChunksDialog, {
                                    file: form.formFields.documentFile,
                                    request: (lastChunk, guid) => store.uploadFile(guid, updateddocument.id, { data: lastChunk, fileName: form.formFields.documentFile!.name })
                                });
                            }
                        }
                        document.dispatchEvent(new Event(RELOAD_MANAGE_DOCS_GRID));
                        onUpdate?.();
                        close();
                    }}
                >
                    Save
                </Button>
            </div>
        </>
    );
});

async function handleUpdateClick(document: DocumentInfoModel, documentTypeName: string, form: Form<DocumentFormModel>, updateDocument: (model: DocumentUpdateModel) => AxiosPromise<DocumentInfoModel>) {
    const updateModel: DocumentUpdateModel = {
        documentId: document.id,
        documentOwnerId: document.documentOwnerId,
        documentName: form.formFields.documentName,
        state: form.formFields.state,
        isLink: form.formFields.docType === DocType.Link,
        link: form.formFields.link,
        isUploadAwaiting: !!form.formFields.documentFile,
        documentTypeName
    };

    const { data } = await updateDocument(updateModel);
    return data;
}
