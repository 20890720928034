import React, { createContext, useContext } from 'react';

type EditContext = {
    editing?: boolean;
    showControls?: boolean;
    onEdit?: () => void;
    onSave?: () => void;
    onCancel?: () => void;
    onDelete?: () => void;
}

type EditContextProps = EditContext;

const Context = createContext<EditContext>({ editing: false });
const { Provider, Consumer } = Context;

const EditContextProvider: React.FC<EditContextProps> = (props) => {
    const { children, ...editContex} = props;

    return (
        <Provider value={editContex}>
            {children}
        </Provider>
    )
}

const useEditContext = () => useContext(Context);

export { EditContextProvider, Consumer as EditContextConsumer, useEditContext };
