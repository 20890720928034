import { Tabs, NavTab } from "../Tabs"
import { useNavigate } from "react-router-dom";

type NavTabsProps = {
    tabs: NavTab[];
    activeTab?: NavTab;
    actions?: () => JSX.Element;
};

export function NavTabs({ tabs, activeTab, actions } : NavTabsProps) {
    const navigate = useNavigate();

    return (
        <Tabs
            actions={actions}
            tabs={tabs}
            activeTab={activeTab}
            onTabSelected={(tab) => navigate(tab.to!)}
        />
    )
}
