import { memo } from "react";

type DocumentOwnerFullNameProps = {
    name: string
};

export const DocumentOwnerFullName = memo(({ name }: DocumentOwnerFullNameProps) => {
    return (
        <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 20 }}>
            {name}
        </div>
    );
})