import { memo } from 'react';
import { AiOutlineSave } from "react-icons/ai";
import { IconButtonWrapper } from "@Components/IconButtonWrapper/IconButtonWrapper";

type SaveIconButtonProps = {
    onClick: () => void;
    disabled?: boolean;
    size?: number;
};

function SaveIconButton({ onClick, disabled, size }: SaveIconButtonProps) {
	return (
        <IconButtonWrapper 
            disabled={disabled}
            onClick={onClick}
            IconType={AiOutlineSave}
            size={size}
        />
	);
}

const Memoized = memo(SaveIconButton);
export { Memoized as SaveIconButton };