import * as React from 'react';
import { observer } from 'mobx-react';
import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow } from '@Components';
import { TestReportForm } from '../Components/TestReportForm/TestReportForm';
import { ITestReportFormStoreBase } from '../../TestReportFormStoreBase';

export type UpdateTestReportDialogProps = {
    store: ITestReportFormStoreBase;
    isLabOfferRequest: boolean;
    isDisabledByTechnologyCenterRule?: boolean;
    productId?: number;
    isReadOnly?: boolean;
    onUpdate?: () => void;
};

@observer
export class UpdateTestReportDialog extends React.Component<UpdateTestReportDialogProps> implements IModalDialogContent<void> {
    store: ITestReportFormStoreBase;

    constructor(props: UpdateTestReportDialogProps) {
        super(props);
        this.store = props.store;
        this.store.initForm();
    }

    render() {
        const { form } = this.store;
        const { isLabOfferRequest, productId, isDisabledByTechnologyCenterRule, isReadOnly = false } = this.props;
        return (
            <div className="tms-form-wrapper">
                <TestReportForm
                    form={form}
                    isLabOfferRequest={isLabOfferRequest}
                    productId={productId}
                    isDisabledByTechnologyCenterRule={isDisabledByTechnologyCenterRule}
                    isReadOnly={isReadOnly}
                />
            </div>
        );
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: 'Test Report',
            buttons: [
                {
                    title: 'Save',
                    type: ModalButtonType.Save,
                    isDisabled: this.props.isReadOnly,
                    onClick: async () => {
                        await this.store.update(() => {
                            window.close();
                            this.props.onUpdate?.();
                        });
                    }
                }
            ],
            width: '600px',
            bodyClassName: 'create-rule-dialog'
        };
    }
}
