
import React, { memo } from 'react';
import { withFormFeedback } from '../FormFeedback/FormFeedback';
import './textarea.scss';

// TODO: check diff:
// type TextareaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {}

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLTextAreaElement>;
	validationErrors?: string[];
};

export const Textarea = memo((props: TextareaProps) => {
	const {innerRef, className, invalid, validationErrors, style, value, ...rest} = props;
	const cls = ['textarea-wrapper'];

	if (className) cls.push(className);
	if (invalid || validationErrors?.length) cls.push('is-invalid');

	return (
		<textarea
			{...rest}
			ref={innerRef}
			className={cls.join(' ')}
			style={style}
			title={value?.toString()}
			value={value}
		/>
	);
});

export const TextAreaWithFeedback = withFormFeedback<TextareaProps>(Textarea);
