import {observable, computed, makeObservable, action, runInAction} from 'mobx';

export enum CompletionType {
    //to track that at least one of operations is still pending 
    // => (use it if you don't know what to choose)
    // eslint-disable-next-line no-unused-vars
    Pending,
    //that operation was completed at least once
    // eslint-disable-next-line no-unused-vars
    Completed
}

export class PromiseCompletion {
    @observable private _promiseList: PromiseLike<unknown>[] = [];
    @observable private _executedOnce: boolean = false;
    private _type: CompletionType;
    private _waiters: (() => void)[] = [];

    constructor(type: CompletionType = CompletionType.Pending) {
        makeObservable(this);
        this._type = type;
    }

    @computed
    get isCompleted() {
        let result = !this._promiseList.length;
        if (this._type === CompletionType.Completed) {
            result = result || this._executedOnce;
        }
        return result;
    }

    @computed
    get isPending() {
        return !!this._promiseList.length;
    }

    public add<T>(callback: () => PromiseLike<T>) {
        const promise = callback();
        void this.subscribe(promise);
        return promise;
    }

    @action
    public subscribe<T>(promise: PromiseLike<T>): PromiseLike<T> {
        if (this._promiseList.indexOf(promise) !== -1) {
            throw new Error('Promise is already registered!');
        }

        this._promiseList.push(promise);
        promise.then(() => this._complete(promise), () => this._complete(promise));
        return promise;
    }

    private _complete(promise: PromiseLike<unknown>) {
        window.setTimeout(() => {
            runInAction(() => {
                const index = this._promiseList.indexOf(promise);
                if (index === -1)
                    throw new Error('Promise is not registered!');

                this._promiseList.splice(index, 1);
                this._executedOnce = true;

                if (!this._promiseList.length) {
                    const waiters = this._waiters.slice(0);
                    this._waiters.length = 0;
                    waiters.forEach(w => w());
                }
            });
        }, 0);
    }

    public wait(): Promise<void> {
        if (!this._promiseList.length && this._executedOnce) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            this._waiters.push(resolve);
        });
    }
}
