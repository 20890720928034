import * as React from 'react';
import styles from './Loading.module.scss';

type LoadingProps = {
    loading?: boolean;
    isSuspense?: boolean;
    className?: string;
} & LoadingSize;

type LoadingSize = {
    size16?: boolean;
    extraSmall?: boolean;
    small?: boolean;
    medium?: boolean;
    large?: boolean;

    fullPage?: boolean;
    scale?: number;
    fillContainer?: boolean;
};

export class Loading extends React.PureComponent<LoadingProps, {}> {
    render() {
        const {
            size16,
            extraSmall,
            small,
            medium,
            large,
            fullPage,
            fillContainer = true,
            className,
            loading,
            isSuspense
        } = this.props;

        const visible = loading || isSuspense;
        const containerCls = [className, styles.loader];
        isSuspense && containerCls.push(styles.suspense);
        fillContainer && containerCls.push(styles.container);
        fullPage && containerCls.push(styles.page);

        const iconCls = [styles.icon];
        if (size16) {
            iconCls.push(styles.size16);
        } else if (extraSmall) {
            iconCls.push(styles.extraSmall);
        } else if (small) {
            iconCls.push(styles.small);
        } else if (medium) {
            iconCls.push(styles.medium);
        } else if (large || fullPage || isSuspense) {
            iconCls.push(styles.large);
        } else {
            iconCls.push(styles.default);
        }

        return (
            <div hidden={!visible} className={containerCls.join(' ')}>
                <div className={iconCls.join(' ')}/>
            </div>
        );
    }
}