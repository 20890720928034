import React from 'react';
import {Tab} from '@Components';
import classNames from 'classnames';
import styles from './tabs.module.scss';

export type NavTab = {
	id: string;
	tabName: string;
	to?: string;
	disabled?: boolean;
};

type TabsProps = {
	tabs: NavTab[];
	activeTab?: NavTab;
	className?: string;
	actions?: () => JSX.Element;
	onTabSelected: (tab: NavTab) => void;
};

export const Tabs: React.FunctionComponent<TabsProps> = ({ onTabSelected, actions, tabs, activeTab, className }) =>  {
	const onClick = (tab: NavTab) => {
		onTabSelected(tab);
	};

	return (
		<div className={classNames(styles.container, className)}>
			<div className={styles.tabs}>
				{tabs.map((tab, i) =>
					<Tab
						key={i}
						active={tab.id === activeTab?.id}
						onClick={() => onClick(tab)}
						disabled={tab.disabled}
					>
						<span className="tab-title">{tab.tabName}</span>
					</Tab>)}
			</div>
			<div className={styles.actions}>
				{actions?.()}
			</div>
		</div>
	);
};