import { memo, useEffect, useRef, useState } from "react";
import {
    ActionButton,
    ActionCell,
    AddButton,
    CellInfo,
    DataGridColumn,
    DataGridColumnFieldType,
    DxDataGrid,
    ModalButtonType
} from "@Components";
import { DocumentConfigurationModel, DocumentInfoModel, DocumentState } from '@Models';
import { useDxDatagridStore } from '@Components/DataGrid/Hooks/Hooks';
import { modalService } from "@Components/Modal/ModalService";
import { IDocumentStore } from "./IDocumentStore";
import { AddDocumentDialog } from "./DocumentDialogs/AddDocumentDialog/AddDocumentDialog";
import { EditDocumentDialog } from "./DocumentDialogs/EditDocumentDialog/EditDocumentDialog";
import { FinancialEntryDocumentStore } from "../LabOfferRequests/FinancialEntryDocumentStore";
import { useEventListener } from "@Hooks";
import { RELOAD_MANAGE_DOCS_GRID } from "@AppConstants";
import { DataGrid } from "devextreme-react";
import { downloadService } from "@Services";

type ManageDocumentsGridProps = {
    documentOwnerId: number;
    defaultDocumentTypeName: string;
    store: IDocumentStore;
    defaultState?: DocumentState;
    onAdd?: () => void;
    onUpdate?: () => void;
    onDelete?: () => void;
    disabled?: boolean;
};

export const ManageDocumentsGrid = memo(({ documentOwnerId, defaultDocumentTypeName, store, defaultState, onAdd, onUpdate, onDelete, disabled }: ManageDocumentsGridProps) => {
    const gridRef = useRef<DataGrid<DocumentInfoModel, any>>(null);
    const [options, reloadGridOptions] = useDxDatagridStore<DocumentInfoModel>(() => store.loadGridData(documentOwnerId), gridRef);
    const [docConfig, setDocConfig] = useState<DocumentConfigurationModel>();
    const isFinancialEntryDocument = store instanceof FinancialEntryDocumentStore;

    useEffect(() => {
        store.loadDocumentConfig(defaultDocumentTypeName).then(({ data }) => setDocConfig(data));
    }, [store, defaultDocumentTypeName]);

    useEventListener(RELOAD_MANAGE_DOCS_GRID, reloadGridOptions);

    const openAddDocumentDialog = async () => {
        await modalService.show(AddDocumentDialog, {
            documentOwnerId: documentOwnerId,
            documentTypeName: defaultDocumentTypeName,
            store: store,
            defaultState: defaultState ?? DocumentState.Draft,
            onAdd
        });
    };

    const openManageDocumentDialog = async (model: DocumentInfoModel, documentOwnerId: number, store:  IDocumentStore) => {
        const { data } = await store.loadDocument(model.id);
        await modalService.show(EditDocumentDialog, {
            documentOwnerId: documentOwnerId,
            documentTypeName: model.documentTypeName,
            documentId: model.id,
            store: store,
            document: data,
            defaultState,
            onUpdate
        });
    };

    const _handleDeleteClick = async (model: DocumentInfoModel) => {
        const message = `Do you really want to delete the document?`;
        const confirmed = await modalService.showConfirmation(message);
        if (confirmed === ModalButtonType.Confirm) {
            await store.deleteDocument(model.id);
            reloadGridOptions();
            onDelete?.();
        }
    };

    const _handleDownloadClick = async (model: DocumentInfoModel) => {
        const docUrl = store.getDocumentRef(model);
        if (model.isLink) {
            window.open(docUrl, '_blank');
        }
        else {
            await downloadService.downloadFile(docUrl);
        }
    };

    const _renderGridToolbar = () => {
        if (options.length >= 1 && docConfig?.isSingleDocument) return <></>;
        const title = 'Add Document'
        return <AddButton onClick={openAddDocumentDialog} text={title} hint={title} />;
    };

    const _actionsCellRender = (cellData: CellInfo<DocumentInfoModel>) => {
        const { data } = cellData;
        if (!data) return null;

        const buttons: ActionButton[] = [
            { name: 'edit', onClick: () => openManageDocumentDialog(data, documentOwnerId, store), title: 'Edit', visible: !disabled },
            { name: 'download', onClick: () => _handleDownloadClick(data), title: 'Download' },
            { name: 'delete', onClick: () => _handleDeleteClick(data), title: 'Delete', visible: !disabled }
        ];

        return <ActionCell buttons={buttons}/>;
    };

    const columns = [];
    !isFinancialEntryDocument && columns.push(new DataGridColumn({ dataFieldName: 'name', caption: 'Name', width: 170, calculateCellValue: (m: DocumentInfoModel) => m.name ?? ''}));
    columns.push(...[
        new DataGridColumn({ dataFieldName: 'uploadedDate', caption: 'Uploaded', width: 120, dataFieldType: DataGridColumnFieldType.dateTime }),
        new DataGridColumn({ dataFieldName: 'uploadedByPerson', caption: 'Uploaded By', width: 170 }),
        new DataGridColumn({ dataFieldName: 'uploadedFileName', caption: 'Orig. File Name', width: 170, calculateCellValue: (m: DocumentInfoModel) => m.uploadedFileName || '' })
    ]);
    !defaultState && columns.push(new DataGridColumn({ dataFieldName: 'state', caption: 'State', width: 80 }));
    !isFinancialEntryDocument && columns.push(new DataGridColumn({ dataFieldName: 'versionText', caption: 'Version', width: 70, calculateCellValue: (cellData: DocumentInfoModel) => cellData?.versionText || '' }));
    columns.push(new DataGridColumn({ caption: 'Actions', cellRenderTemplate: _actionsCellRender, width: 80 }));

    return (
        <DxDataGrid<DocumentInfoModel>
            dataGridRef={gridRef}
            dataSource={options}
            columns={columns}
            disableGrouping
            disableExporting
            disableHeaderFilter
            disableFilterRow
            disableSearchPanel
            onRowDoubleClick={({ data }) => !disabled && openManageDocumentDialog(data!, documentOwnerId, store)}
            customToolBarRender={!disabled ? _renderGridToolbar : undefined}
        />
    );
});
