export class DateTime {
    public static readonly jsonDateFormat: string = 'YYYY-MM-DD';
    public static readonly viewDateFormat: string = 'DD MMM YYYY';
    public static readonly jsonFullFormat: string = 'YYYY-MM-DD HH:mm:ss';
    public static readonly jsonDateTimeFormat: string = 'YYYY-MM-DDTHH:mm:ssZ';
    public static readonly viewFullFormat: string = 'DD MMM YYYY HH:mm';
    public static readonly monthPeriodFormat: string = 'YYYY M';
    public static readonly viewMonthFormat: string = 'MM.YYYY';
    public static readonly monthPeriodFullFormat: string = 'YYYY-MM';
    public static readonly timeFormat: string = 'HH:mm';
    public static readonly minDate: string = '01.01.2017';
}

export class DateFormat {
    public static readonly JSON: string = 'YYYY-MM-DD';
    public static readonly DateTime: string = 'DD MMM YYYY HH:mm';
    public static readonly Time: string = 'HH:mm';
}