import React, { ClassType, FunctionComponent } from 'react';
import { observable, computed, makeObservable, action } from 'mobx';
import { AxiosError } from 'axios';
import { ConfirmationDialog, InformationDialog, ErrorDialog } from '@Components';
import * as ModalWindow from './ModalWindow';
import { ModalDialogOptions } from './Modal';
import { ButtonColor, ModalButtonType } from './ModalButton';
import { ConfirmationWithCommentDialog } from '@Components/Dialogs/ConfirmationWithCommentDialog/ConfirmationWithCommentDialog';

export enum DialogActionType {
    CANCEL,
    SUBMIT,
    CLOSE,
    ERROR
}

type ConfirmationOptions = {
    title?: string;
    color?: ButtonColor;
    isShowNoButton?: boolean;
    confirmButton?: ModalButtonType;
    dialogId?: string;
};

export type ModalResult<T> = {
    button: ModalWindow.ModalAction;
    result: T | null;
};

type ModalWindowProps<T> = ModalWindow.ModalOptions<T> & {
    onClose: (action: ModalWindow.ModalAction, result?: T) => void;
};

type ModalPromise<T> = Promise<ModalResult<T>> & {
    close: (action?: ModalWindow.ModalAction, result?: T) => void;
};

class ModalService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @observable private _modalData: ModalWindowProps<any>[] = [];

    constructor(){
        makeObservable(this);
    }

    @computed
    public get hasActiveModal() {
        return !!this._modalData.length;
    }

    public renderModals = () => {
        return this._modalData.map((m, index) => <ModalWindow.ModalWindow key={'m' + index} {...m} />);
    };

    public show<P, T>(dialog: ClassType<P, React.Component<P, {}, {}>, React.ComponentClass<P, {}>> | FunctionComponent<P>, props?: P, options?: Omit<ModalDialogOptions<T>, 'dialog'>): ModalPromise<T> {
        return this.showModal(Object.assign({}, {
            dialog: (window: ModalWindow.ModalWindow<T>) => {
                const content = React.createElement(dialog as any, Object.assign({}, props, {ref: window.contentRef}));
                return content;
            }
        }, options) as ModalWindow.ModalOptions<T>);
    }

    @action
    public showModal<T>(options: ModalWindow.ModalOptions<T>): ModalPromise<T> {
        let data: ModalWindowProps<T> | null = null;
        const result = new Promise<ModalResult<T>>((resolve) => {
            data = Object.assign(options, {
                onClose: (button: ModalWindow.ModalAction, result?: T) => {
                    resolve({
                        button: button,
                        result: result || null
                    });
                    const modalIndex = this._modalData.findIndex(d => d === data);
                    if (modalIndex !== -1) {
                        this._modalData.splice(modalIndex, 1);
                    }
                    if (!this._modalData.length) {
                        document.body.classList.remove('modal-open');
                    }
                }
            });
            if (!this._modalData.length) {
                document.body.classList.add('modal-open');
            }
            this._modalData.push(data);
            data = this._modalData[this._modalData.length - 1];
        });

        return Object.assign(result, {
            close: (action?: ModalWindow.ModalAction, result?: T) => data?.onClose(action || ModalButtonType.Ok, result)
        });
    }

    @action
    public closeModal(action = DialogActionType.CLOSE){
        if(action === DialogActionType.ERROR) {
            this._modalData.splice(-2, 1);
        }
        else this._modalData.pop();
        if (!this._modalData.length) {
            document.body.classList.remove('modal-open');
        }
    }

    @action
    public closeModalById(modalId: string){
        const modalData = this._modalData.filter(modal => modal.dialogId !== modalId);

        if (modalData.length === this._modalData.length) return;
        this._modalData = modalData;
    }

    public async showConfirmation(message: string | string[] | JSX.Element, titleOrOptions?: string | ConfirmationOptions, options?: ConfirmationOptions) {
        const confirmationOptions = typeof titleOrOptions === 'object' ? titleOrOptions : options;
        const result = await this.show(ConfirmationDialog, {
            message: message,
            title: typeof titleOrOptions === 'string' ? titleOrOptions : titleOrOptions?.title,
            color: confirmationOptions?.color || 'primary',
            isShowNoButton: confirmationOptions?.isShowNoButton,
            confirmButton: confirmationOptions?.confirmButton
        }, { dialogId: confirmationOptions?.dialogId });

        return result.button;
    }

    public async showConfirmationWithComment(message: string | string[] | JSX.Element, titleOrOptions?: string | ConfirmationOptions): Promise<{confirmed: boolean, comment: string | null}> {
        const result = await this.show(ConfirmationWithCommentDialog, {
            message: message,
            title: typeof titleOrOptions === 'string' ? titleOrOptions : titleOrOptions?.title,
        });
        return { confirmed: result.button === ModalButtonType.Confirm, comment: result.result as string };
    }

    public async showInformation(message: string | string[] | JSX.Element, title?: string, options?: { className?: string }) {
        await this.show(InformationDialog, {
            message: message,
            title: title,
            className: options?.className
        });
    }

    public showError = async (errorContent: string | string[] | JSX.Element | AxiosError, errorHeader?: string, hideErrorState?: boolean) => {
        await this.show(ErrorDialog, {
            error: errorContent,
            errorHeader: errorHeader,
            hideErrorState: hideErrorState
        });
    };
}

export const modalService = new ModalService();
