import axios from 'axios';
import React, { RefObject, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = (fun: React.EffectCallback) => useEffect(fun, []);


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useWindowResizeHook(cb: () => void) {
    useEffect(() => {
        window.addEventListener('resize', cb);
        return () => window.removeEventListener('resize', cb);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
};

export function useEventListener(event: string, handler: EventListener ) {
    const handlerRef = useRef(handler);

    useEffect(() => {
        handlerRef.current = handler;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });
    
    useEffect(() => {
        function internalHandler(e: any) {
            return handlerRef.current(e);
        }

        document.addEventListener(event, internalHandler);
        return () => document.removeEventListener(event, internalHandler);
    }, [event]);
}

export function useCustomEventListener<T>(event: string, handler: (event: CustomEvent<T>) => void) {
    const handlerRef = useRef(handler);

    useEffect(() => {
        handlerRef.current = handler;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
        function internalHandler(e: CustomEvent<T>) {
            return handlerRef.current(e);
        }

        document.addEventListener(event, internalHandler as EventListener);
        return () => document.removeEventListener(event, internalHandler as EventListener);
    }, [event]);
}

export const usePromiseCompletion = () => {
    const promiseList: PromiseLike<unknown>[] = [];
    const waiters: (() => void)[] = [];
    const [isCompleted, setIsCompleted] = useState(false);
    const [isPending, setIsPending] = useState(false);
    let executedOnce = false;

    let handleBeginLoading: (() => void) | null;
    let handleEndLoading: (() => void) | null; 

    function setBeginLoadingHandler(handler: () => void) {
        handleBeginLoading = handler;
    }

    function setEndLoadingHandler(handler: () => void) {
        handleEndLoading = handler;
    }
    
    function checkStatuses() {
        if (!!promiseList.length) {
            setIsPending(true);
            setIsCompleted(false);
        } else {
            setIsPending(false)
            setIsCompleted(true);
        }
    }

    function add<T>(callback: () => PromiseLike<T>) {
        const promise = callback();
        subscribe(promise);
        checkStatuses();
        return promise;
    }

    function subscribe<T>(promise: PromiseLike<T>): PromiseLike<T> {
        if (promiseList.indexOf(promise) !== -1) {
            throw new Error('Promise is already registered!');
        }

        promiseList.push(promise);

        handleBeginLoading?.();

        promise.then(() => complete(promise), () => complete(promise));
        return promise;
    }

    function complete(promise: PromiseLike<unknown>) {
        function timeout() {
            return new Promise((resolve) => {
                setTimeout(resolve, 0);
            });
        }

        timeout().then(() => {
            const index = promiseList.indexOf(promise);
            if (index === -1)
                throw new Error('Promise is not registered!');

            promiseList.splice(index, 1);
            executedOnce = true;

            handleEndLoading?.();

            if (!promiseList.length) {
                const slicedWaiters = waiters.slice(0);
                slicedWaiters.length = 0;
                slicedWaiters.forEach(w => w());
            }

            checkStatuses();
        });
    }

    function wait(): Promise<void> {
        if (!promiseList.length && executedOnce) {
            return Promise.resolve();
        }

        return new Promise((resolve) => {
            waiters.push(resolve);
        });
    }

    return { add, wait, setBeginLoadingHandler, setEndLoadingHandler, isPending, isCompleted }
}

export const useCancellation = () => {
    const cancellationSource = useRef(axios.CancelToken.source());

    const cancel = () => {
        cancellationSource.current.cancel();
        cancellationSource.current = axios.CancelToken.source();
    }

    useEffect(() => {
        return () => {
            cancel();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        cancellationToken: cancellationSource.current.token,
        cancel
    }
}

export const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: () => void) => {
    const handleClick = (event: Event) => {
        if (!ref.current || ref.current.contains(event.target as Node)) return;

        handler();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, handler]);
}
