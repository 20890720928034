import { useState, useRef, useMemo } from 'react';
import { Input, InputProps } from '../Input/Input';
import styles from './DropdownInput.module.scss';


export type DropdownInputProps = InputProps & {
    options: string[];
    onOptionSelect?: (option: string) => void;
}

export const DropdownInput: React.FC<DropdownInputProps> = (props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsDropdownOpen(true);

        props.onFocus?.(event);
    }
    
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsDropdownOpen(false);

        props.onBlur?.(event);
    }

    const handleOptionSelect = (option: string) => {
        props.onOptionSelect?.(option);
    };

    const visibleOptions = useMemo(() => {
        const { options, value } = props;
        if (!value) return options;

        return options.filter(option => !!option && option.toString().toLowerCase().includes(value.toString().toLowerCase()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.options, props.value]);

    const dropdownItems = useMemo(() => {
        return visibleOptions.map((option, index) => (
            <div
                key={index}
                className={styles.dropdownItem}
                onMouseDown={() => handleOptionSelect(option)}
            >
                {option}
            </div>
        ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleOptions]);

    return (
        <div ref={wrapperRef} className={styles.wrapper}>
            <Input
                {...props}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />

            {isDropdownOpen && !!visibleOptions.length && (
                <div className={styles.dropdown}>
                    {dropdownItems}
                </div>
            )}
        </div>
    );
}
