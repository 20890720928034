export class ApiUrls {
    public static readonly ServiceDownloadLogsUrl: string = '/api/service/download-logs';
    public static readonly ServiceDownloadFullLogsUrl: string = '/api/service/download-full-logs';
    public static readonly ServiceDownloadDayLogsUrl: string = '/api/service/download-day-logs';
    public static readonly ServiceDownloadMessagesUrl: string = '/api/service/download-messages'; //
    public static readonly ServiceDownloadDeadlettersUrl: string = '/api/service/download-deadletters'; //
    public static readonly ServiceQueueDeadlettersUrl: string = '/api/service/input-queue-deadletters'; //
    public static readonly MessageStatisticsUrl: string = '/api/service/message-statistics'; //

    public static readonly MonitoringUrl: string = '/api/monitoring';

    public static readonly SignalRUserUrl: string = '/api/signalruser/';

    public static readonly SelfTestJobsUrl: string = '/api/selftest/jobs';
    public static readonly SelfTestDatabaseUrl: string = '/api/selftest/database';
    public static readonly SelfTestSignalRUrl: string = '/api/selftest/signalr';
    public static readonly SelfTestPingUrl: string = '/api/selftest/ping';
    public static readonly ServiceRestartSchedulerUrl: string = '/api/service/restart-scheduler';

    public static readonly TestDocumentDownloadUrl: string = '/api/testdocument/download';
    public static readonly ProjectDocumentDownloadUrl: string = '/api/projectdocument/download'; //
    public static readonly MatrixDocumentDownloadUrl: string = '/api/typetestmatrixtestdocument/download'; //
    public static readonly FinancialEntryDocumentDownloadUrl: string = '/api/FinancialEntryDocument/download';
    public static readonly FinancialEntryDocumentLatestDownloadUrl: string = '/api/FinancialEntryDocument/download-latest';
    public static readonly StandardDocumentLatestDownloadUrl: string = '/api/standarddocument/download-latest';
    public static readonly StandardDocumentDownloadUrl: string = '/api/standarddocument/download';
    public static readonly TestObjectDocumentDownloadUrl: string = '/api/testobjectdocument/download';
    public static readonly TestObjectDocumentDownloadArchiveUrl: string = '/api/TestObjectDocument/download-archive';
    public static readonly TestReportExcelFileTemplateDownloadUrl: string = '/api/TestReport/excel-file-template-download';
    public static readonly TypeTestPlanComponentDocumentDownloadUrl: string = '/api/typetestplancomponentdocument/download';
    public static readonly TypeTestPlanApprovalSnapshotDocumentDownloadUrl: string = '/api/typetestplanapproval/document-download';
    public static readonly TypeTestPlanComponentsDownloadUrl: string = '/api/typetestplancomponent/components-download';

    public static readonly TestObjectDocumentDownloadTestRunArchiveUrl: string = '/api/TestObjectDocument/download-test-run-archive';
    public static readonly TestObjectDocumentDownloadTestRunArchiveForLor: string = '/api/TestObjectDocument/download-test-run-archive-for-lor';

    public static readonly TypeTestPlanDownload: string ='/api/TypeTestPlan/type-test-plan-download';
    public static readonly TypeTestPlanSnapshotDownloadGet: string ='/api/TypeTestPlan/type-test-plan-snapshot-download';
    public static readonly TypeTestMatrixDownloadUrl: string = '/api/TypeTestMatrix/type-test-matrix-download';
    public static readonly TechnicalRatingsDownloadUrl: string = '/api/TechnicalRating/technical-ratings-download';
    public static readonly DesignParametersDownloadUrl: string = '/api/DesignParameter/design-parameters-download';

    public static readonly LabOfferRequestDocumentDownloadUrl: string = '/api/LabOfferRequestDocument/download';
    public static readonly LabOfferRequestDocumentAsPdfDownloadUrl: string = '/api/LabOfferRequestDocument/download-as-pdf';

    public static readonly TypeTestMatrixVariantDocumentDownloadUrl: string = '/api/TypeTestMatrixVariantDocument/download';
    public static readonly TypeTestMatrixVariantDocumentPdfDownloadUrl: string = '/api/TypeTestMatrixVariantDocument/download-pdf';
    public static readonly TypeTestMatrixVariantDocumentTrdWordDownloadUrl: string = '/api/TypeTestMatrixVariantDocument/trd-download-word';
    public static readonly TypeTestMatrixVariantDocumentTrdPdfDownloadUrl: string = '/api/TypeTestMatrixVariantDocument/trd-download-pdf';
    public static readonly TypeTestMatrixVariantDocumentTrdAsPdfDownloadUrl: string = '/api/TypeTestMatrixVariantDocument/trd-download-as-pdf';
    public static readonly TypeTestMatrixVariantDocumentAsPdfDownloadUrl: string = '/api/TypeTestMatrixVariantDocument/download-as-pdf';
    public static readonly TypeTestMatrixVariantDocumentDownloadArchiveUrl: string = '/api/TypeTestMatrixVariantDocument/download-archive';
    public static readonly TypeTestMatrixVariantDocumentTrdTestReportsArchiveDownloadUrl: string = '/api/TypeTestMatrixVariantDocument/trd-download-test-reports-archive';
    public static readonly TypeTestMatrixVariantDocumentTrdExcelTemplateDownloadUrl: string = '/api/TypeTestMatrixVariantDocument/trd-excel-template-file--download';

    public static readonly LabOfferTemplateFileDownloadUrl: string = '/api/Lab/download-offer-template-file';
    public static readonly TCRequestTemplateFileDownloadUrl: string = '/api/TechnologyCenter/download-request-template-file';
    public static readonly TCDoCTemplateFileDownloadUrl: string = '/api/TechnologyCenter/download-declaration-of-conformity-template-file';

    public static readonly ConfigurationDefaultDocTemplateUrl: string = '/api/configuration/doc-template-download';

    public static readonly ProductMemberTrdDownloadUrl: string = '/api/ProductMember/trd-product-members-download';
    public static readonly ProductMemberTrdTemplateFileDownloadUrl: string = '/api/ProductMember/trd-product-members-template-download';
    
    public static readonly SProductFamilyMemberFileDownloadUrl: string = '/api/SProductFamilyMember/s-product-family-members-download';
    public static readonly SProductFamilyMemberTemplateFileDownloadUrl: string = '/api/SProductFamilyMember/s-product-family-members-template-download';

    public static readonly SProductFamilyVariantDocumentFileDownloadUrl: string = '/api/SProductFamilyVariantDocument/download-document-file';
    public static readonly SProductFamilyVariantDocumentCertificateFileDownloadUrl: string = '/api/SProductFamilyVariantDocument/download-certificate-file';
}
