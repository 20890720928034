import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import { Modifiers, Placement } from 'popper.js';

export type ToolTipProps = {
	targetId: string | HTMLDivElement;
	text: string | JSX.Element;
	modifiers?: Modifiers;
	className?: string;
  innerClassName?: string;
	placement?: Placement;
  autohide?:boolean
};

export const ToolTipItem: React.FC<ToolTipProps> = (props) => {
    const { targetId, text, modifiers, className, placement, autohide = true, innerClassName } = props;

    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <Tooltip
            target={targetId}
            isOpen={tooltipOpen}
            className={className}
            innerClassName={innerClassName}
            modifiers={modifiers ? modifiers : {}}
            placement={placement ? placement : 'right'}
            toggle={toggle}
            autohide={autohide}
        >
            {text}
        </Tooltip>
    );
};
