import { AxiosPromise } from "axios";
import { TestReportModel, AvailableTestReportModel } from "@Models";
import {
    ActionButton,
    ActionCell,
    DataGridColumnFieldType,
    DataGridRowRenderingMode,
    DataGridScrollMode
} from "@Components";
import { CellInfo, DataGridColumn, DataGridSelectionMode, DxDataGrid, OnSelectionChangedArgs } from "@Components/DataGrid";
import { useDxDatagridStoreWithTracking } from "@Components/DataGrid/Hooks/Hooks";
import { enumHelper } from "@Helpers";
import { useCallback, useRef, useState } from "react";
import { DataGrid } from "devextreme-react";
import { CustomInput, FormGroup } from "reactstrap";
import { TechnologyCenterPicker } from "@Components/Pickers";

type AvailableForReferenceTestReportsTableProps = {
    ownTcId: number;
    isSingleSelectionMode?: boolean;
    loadData: (isOtherTechnologyCenter: boolean, otherTechnologyCenterId?: number) => AxiosPromise<AvailableTestReportModel[]>;
    onAddClick?: (testReport: AvailableTestReportModel) => Promise<void>;
    onDeleteClick?: (testReport: AvailableTestReportModel) => Promise<void>;
    onSelect?: (testReport: AvailableTestReportModel) => void;
}

enum TcOptions {
    ownTC = 'OwnTC',
    otherTC = 'OtherTC'
}

export function AvailableForReferenceTestReportsTable({ ownTcId, isSingleSelectionMode, loadData, onAddClick, onDeleteClick, onSelect }: AvailableForReferenceTestReportsTableProps) {
    const [_selectedTcOption, setSelectedTcOptionType] = useState<TcOptions>(TcOptions.ownTC);
    const [_selectedTechnologyCenterId, setSelectedTechnologyCenterId] = useState<number | undefined>(undefined);
    const gridRef = useRef<DataGrid<AvailableTestReportModel, any>>(null);
    const loadTestReportData = useCallback(() => {
        if (_selectedTcOption === TcOptions.otherTC) {
            return loadData(true, _selectedTechnologyCenterId);
        }
        return loadData(false);
    }, [loadData, _selectedTcOption, _selectedTechnologyCenterId]);
    const [options, reloadGridOptions] = useDxDatagridStoreWithTracking<AvailableTestReportModel>(loadTestReportData, gridRef);
    const gridHeight = window.innerHeight * 0.6;

    const handleAddTestReportClick = async (testReport: AvailableTestReportModel) => {
        await onAddClick?.(testReport);
        reloadGridOptions();
    }

    const handleDeleteTestReportClick = async (testReport: AvailableTestReportModel) => {
        await onDeleteClick?.(testReport);
        reloadGridOptions();
    }

    const handleSelectionChanged = (args: OnSelectionChangedArgs<AvailableTestReportModel>) => {
        const testReport = args.selectedRowsData?.[0];

        if (testReport && !testReport.isApplicable && onSelect) onSelect(testReport);
    }

    const _actionsCellRender = (cellData: CellInfo<AvailableTestReportModel>) => {
        const { data } = cellData;
        if (!data) return null;

        const buttons: ActionButton[] = [
            { name: 'add', onClick: () => handleAddTestReportClick(data), title: 'Add', visible: !data.isApplicable },
            { name: 'remove', onClick: () => handleDeleteTestReportClick(data), title: 'Remove', visible: data.isApplicable && !data.isTestRunTestReportUsedInLabOfferRequestTestRun }
        ];

        return <ActionCell buttons={buttons} />;
    };

    const _testLabDisplayNameCellRender = (cellData: CellInfo<TestReportModel>) => {
        if (!cellData.data) return null;
        return cellData.data.testLabDisplayName?.toString();
    };

    const _issuedLabDisplayNameCellRender = (cellData: CellInfo<TestReportModel>) => {
        if (!cellData.data) return null;
        return cellData.data.issuedLabDisplayName?.toString();
    };

    const columns = [];
    if (_selectedTcOption === TcOptions.otherTC) {
        columns.push(new DataGridColumn<AvailableTestReportModel>({ dataFieldName: 'technologyCenterDisplayName', caption: 'TC' }));
    }
    columns.push(new DataGridColumn<AvailableTestReportModel>({ dataFieldName: 'number', caption: 'Number' }));
    columns.push(new DataGridColumn<AvailableTestReportModel>({
        dataFieldName: 'category', caption: 'Category', width: 100,
        calculateCellValue: (m: TestReportModel) => enumHelper.TestReportCategoryLabels[m.category]
    }));
    columns.push(new DataGridColumn<AvailableTestReportModel>({ dataFieldName: 'info', caption: 'Test Report Info' }));
    columns.push(new DataGridColumn<AvailableTestReportModel>({ dataFieldName: 'issuedDate', caption: 'Valid From', dataFieldType: DataGridColumnFieldType.date }));
    columns.push(new DataGridColumn<AvailableTestReportModel>({ dataFieldName: 'testLabDisplayName', caption: 'Test Lab', cellRenderTemplate: _testLabDisplayNameCellRender }));
    columns.push(new DataGridColumn<AvailableTestReportModel>({ dataFieldName: 'issuedLabDisplayName', caption: 'Issued Lab', cellRenderTemplate: _issuedLabDisplayNameCellRender }));
    if (!isSingleSelectionMode) 
        columns.push(new DataGridColumn<AvailableTestReportModel>({ caption: 'Actions', cellRenderTemplate: _actionsCellRender, width: 60 }));

    return (
        <>
            <FormGroup>
                <div className="radio-group">
                    <CustomInput
                        type="radio"
                        id="rOwnTC"
                        name="customTcRadio"
                        label="Own TC"
                        checked={_selectedTcOption === TcOptions.ownTC}
                        onChange={() => setSelectedTcOptionType(TcOptions.ownTC)}
                    />
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: 120 }}>
                            <CustomInput
                                type="radio"
                                id="rOtherTC"
                                name="customTcRadio"
                                label="Other TC"
                                checked={_selectedTcOption === TcOptions.otherTC}
                                onChange={() => setSelectedTcOptionType(TcOptions.otherTC)}
                            />
                        </div>
                        <div style={{ width: 240 }}>
                            <TechnologyCenterPicker
                                onChange={(technologyCenterId) => setSelectedTechnologyCenterId(technologyCenterId?.value)}
                                defaultValue={_selectedTechnologyCenterId}
                                disabled={_selectedTcOption !== TcOptions.otherTC}
                                exceptTechnologyCenterId={ownTcId}
                            />
                        </div>
                    </div>
                </div>
            </FormGroup>
            <DxDataGrid<AvailableTestReportModel>
                dataGridRef={gridRef}
                dataSource={options}
                overrideHeight={isSingleSelectionMode ? gridHeight : undefined}
                columns={columns}
                scrollMode={DataGridScrollMode.standard}
                selectionMode={isSingleSelectionMode ? DataGridSelectionMode.single : undefined}
                rowRenderingMode={DataGridRowRenderingMode.standard}
                disableGrouping
                disableExporting
                onSelectionChanged={isSingleSelectionMode ? handleSelectionChanged : undefined}
            />
        </>
    );
}
