import React from 'react';
import './simple-select.scss';

export type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLSelectElement>;
};

export type SimpleSelectProps = SelectProps & {
	options: string[];
	hasNoEmptyOption?: boolean;
};

export class SimpleSelect extends React.PureComponent<SimpleSelectProps> {
	public render() {
		const { options, className, hasNoEmptyOption } = this.props;
		const cls = ['simple-select'];
		if (className) cls.push(className);

		return (
			<select
				className={cls.join(' ')}
				{...this.props}
			>
				{!hasNoEmptyOption && <option value="" />}
				{options.map((t: string) => <option value={t} key={t}>{t}</option>)}
			</select>
		);
	}
}
