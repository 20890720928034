import { apiClient } from "@Models";
import { LookupModelPicker, LookupModelPickerBaseProps } from '@Components/Pickers/LookupModelPicker/LookupModelPicker';
import {useCallback} from "react";

export function StandardPicker(props: LookupModelPickerBaseProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.standardLookupGet.bind(apiClient), []);
    return (
        <LookupModelPicker
            {...props}
            loadData={loadData}
        />
    );
}