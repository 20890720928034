import React, { useCallback } from "react";
import { apiClient } from "@Models";
import { LookupModelPicker, LookupModelPickerBaseProps } from "../LookupModelPicker/LookupModelPicker";

type Props = LookupModelPickerBaseProps & {
    testId: number;
}


export function Picker({testId, defaultValue, ...rest }: Props) {
    const loadData = useCallback(() => {
        const testRunByTestIdLookupModelsGet = apiClient.testRunLookupGet.bind(apiClient);
        // TODO: refactor later - has to check default value as dependecy, but linter will not allow without usage
        if (defaultValue) {
            return testRunByTestIdLookupModelsGet({ testId })
        } else {
            return testRunByTestIdLookupModelsGet({ testId })
        }
    }, [testId, defaultValue]);

    return (
        <LookupModelPicker
            {...rest}
            defaultValue={defaultValue}
            loadData={loadData}
        /> 
    );
}

const TestRunNumberPicker = React.memo(Picker);
export { TestRunNumberPicker };