import { action, makeObservable, observable } from "mobx";
import { TestReportCategory, TestReportModel } from "@Models";
import { FormModel } from "./Dialogs/Components/TestReportForm/TestReportFormModel";
import { DateTimeService } from "@Services";
import { apiClient } from "../../../ApiClient";
import { DateTime } from "@AppConstants";

export interface ITestReportFormStoreBase {
    form: FormModel;
    defaultCategory?: TestReportCategory;
    initForm: () => void;
    create: (onCreate?: () => void) => Promise<void>;
    update: (onUpdate?: () => void) => Promise<void>;
    reinit: (testReportModel: TestReportModel) => void;
    reset: () => void;
    resetIsValidated: () => void;
}

export abstract class TestReportFormStoreBase implements ITestReportFormStoreBase{
    @observable testReportModel: TestReportModel | null = null;
    form = new FormModel();
    @observable defaultCategory?: TestReportCategory;
    @observable autoGenerateNumber?: boolean;
    labOfferRequestId?: number;
    labId?: number;
    testId?: number;
    testRunId?: number;

    constructor(labOfferRequestId?: number, labId?: number, testId?: number) {
        makeObservable(this);
        this.labOfferRequestId = labOfferRequestId;
        this.labId = labId;
        this.testId = testId;
    }

    reinit = (testReportModel: TestReportModel) => {
        this.testReportModel = testReportModel;
        this.initForm();
    }

    reset = () => {
        this.testReportModel = null;
        this.initForm();
    }

    @action
    resetIsValidated() {
        this.form.validated = false;
    }

    @action
    initForm() {
        if (this.testReportModel) {
            this.form.number = this.testReportModel.number;
            this.form.info = this.testReportModel.info;
            this.form.issuedDate = this.testReportModel.issuedDate ? DateTimeService.format(this.testReportModel.issuedDate, DateTime.viewDateFormat) : '';
            this.form.labOfferRequestId = this.testReportModel.labOfferRequestId ? this.testReportModel.labOfferRequestId.toString() : '';
            this.form.testLabId = this.testReportModel.testLabId ? this.testReportModel.testLabId.toString() : '';
            this.form.issuedLabId = this.testReportModel.issuedLabId ? this.testReportModel.issuedLabId.toString() : '';
            this.form.technologyCenterId = this.testReportModel.technologyCenterId ? this.testReportModel.technologyCenterId.toString() : '';
            this.form.category = this.testReportModel.category;
        }
        else {
            this.form.number = '';
            if (this.autoGenerateNumber) {
                apiClient.testReportNextNumberGet({ labOfferRequestId: this.labOfferRequestId, testId: this.testId, testRunId: this.testRunId })
                    .then((r) => {
                        this.form.number = r.data;
                    });
            }
            this.form.labOfferRequestId = this.labOfferRequestId ? this.labOfferRequestId.toString() : '';
            this.form.testLabId = this.labId ? this.labId.toString() : '';
            this.form.issuedLabId = this.labId ? this.labId.toString() : '';
            this.form.issuedDate = '';
            this.form.technologyCenterId = '';
            this.form.category = this.defaultCategory ? this.defaultCategory : TestReportCategory.Success;
            this.form.info = '';
        }
    }

    abstract create(onCreate?: () => void): Promise<void>;
    abstract update(onUpdate?: () => void): Promise<void>;
}