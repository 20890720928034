import { ActionButton, ActionCell } from '@Components/ActionCell/ActionCell';
import styles from './ActionsAddRemoveAllHeaderCell.module.scss';


type ActionsHeaderCellProps = {
    isAddDisabled?: boolean;
    isRemoveDisabled?: boolean;
    onAddAllClick?: () => Promise<void> | void;
    onRemoveAllClick?: () => Promise<void> | void;
}

export const ActionsAddRemoveAllHeaderCell = ({ isAddDisabled, isRemoveDisabled, onAddAllClick, onRemoveAllClick }: ActionsHeaderCellProps) => {
    const buttons: ActionButton[] = [
        { name: 'add', title: 'Add all', onClick: onAddAllClick, disabled: isAddDisabled },
        { name: 'remove', title: 'Remove all', onClick: onRemoveAllClick, disabled: isRemoveDisabled },
    ];

    return (
        <div className={styles.actionsHeader}>
            Actions
            <div className={styles.actions}>
                <ActionCell buttons={buttons} />
            </div>
        </div>
    );
};
