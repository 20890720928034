import { FaTrashAlt } from 'react-icons/fa';
import { IconButtonWrapper } from "@Components/IconButtonWrapper/IconButtonWrapper";
import { EditIconButton } from "@Components/Buttons/EditIconButton/EditIconButton";
import { SaveIconButton } from "@Components/Buttons/SaveIconButton/SaveIconButton";
import { CloseIconButton } from "@Components/Buttons/CloseIconButton/CloseIconButton";

type EditModeSwitcherProps = {
    editing?: boolean;
    hideCancel?: boolean;
    onEdit?: () => void;
    onSave?: () => void;
    onCancel?: () => void;
    onDelete?: () => void;
}

function EditModeSwitcher(props: EditModeSwitcherProps) {
    const { editing, hideCancel, onEdit, onSave, onCancel, onDelete } = props;
    return (
        <section
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 10,
                minHeight: 15
            }}
        >
            {
                !editing && onDelete &&
                <IconButtonWrapper
                    onClick={onDelete}
                    IconType={FaTrashAlt}
                    size={22}
                />
            }
            {
                !editing && onEdit &&
                <EditIconButton 
                    onClick={onEdit}
                    size={25}
                />
            }
            {
                editing && onSave &&
                <SaveIconButton
                    onClick={onSave}
                    size={25}
                />
            }
            {
                !hideCancel && editing && onCancel &&
                <CloseIconButton
                    onClick={onCancel}
                    size={25}
                />
            }
        </section>
    )
}

export { EditModeSwitcher };