import { memo, useMemo } from 'react';

import { Col, Row } from 'reactstrap';
import { Form } from '@Libs/Form';
import { DocumentState, LookupStringValueModel } from '@Models';
import { DocumentFormModel } from './DocumentFormModel';

import {
    FileUploader,
    Label,
    Select,
    InputWithFeedback as Input
} from '@Components';

type UploadDocumentFormProps = {
    form: Form<DocumentFormModel>;
    defaultState?: DocumentState;
    isNameHidden?: boolean;
};

export enum DocType {
    File = "File",
    Link = "Link"
};

const doctypeOptions = [
    { label: 'File', value: DocType.File },
    { label: 'Link', value: DocType.Link }
];

const options = Object.keys(DocumentState).map(st => ({ label: st, value: st }));


export const DocumentForm = memo(({ form, defaultState, isNameHidden }: UploadDocumentFormProps) => {
    const { labels, requieredFields, formFields, handleChange, getValidationErrorsFor } = form;
    const { docType, state } = formFields;

    const selectedDocType = useMemo(() => {
        return doctypeOptions.find(o => o.value === docType) || null;
    }, [docType]);

    const selectedState = useMemo(() => {
        return options.find(o => o.value === state) || null;
    }, [state]);

    return (
        <div className="tms-form-wrapper">
            <Row>
                <Col sm="4">
                    <Label
                        text={labels.docType}
                        required={requieredFields.docType}
                    />
                </Col>
                <Col>
                    <Select<LookupStringValueModel>
                        options={doctypeOptions}
                        value={selectedDocType}
                        onChange={(selected) => handleChange('docType', selected?.value)}
                    />
                </Col>
            </Row>
            {!isNameHidden &&
                <Row>
                    <Col sm="4">
                        <Label
                            text={labels.documentName}
                            required={requieredFields.documentName}
                        />
                    </Col>
                    <Col>
                        <Input
                            value={formFields.documentName}
                            validationErrors={!isNameHidden && !!getValidationErrorsFor('documentName') ? [getValidationErrorsFor('documentName') as string] : []}
                            onChange={e => handleChange('documentName', e.currentTarget.value)}
                        />
                    </Col>
                </Row>
            }
            {!defaultState && <Row>
                <Col sm="4">
                    <Label
                        text={labels.state}
                        required={requieredFields.state}
                    />
                </Col>
                <Col>
                    <Select
                        options={options}
                        value={selectedState}
                        onChange={(state) => {
                            handleChange('state', state?.value)
                        }}
                    />
                </Col>
            </Row>
            }
            {
                form.formFields.docType === DocType.File &&
                <Row>
                    <Col sm="4">
                        <Label text={'File'} />
                    </Col>
                    <Col>
                        <FileUploader
                            label={formFields.uploadedFileName}
                            onFileChanged={(file: File) => {
                                handleChange('documentFile', file);
                            }}
                        />
                    </Col>
                </Row>
            }
            {
                form.formFields.docType === DocType.Link &&
                <Row>
                    <Col sm="4">
                        <Label
                            text={labels.link}
                            required={requieredFields.link}
                        />
                    </Col>
                    <Col>
                        <Input
                            value={formFields.link}
                            validationErrors={!!getValidationErrorsFor('link') ? [getValidationErrorsFor('link') as string] : []}
                            onChange={e => handleChange('link', e.currentTarget.value)}
                        />
                    </Col>
                </Row>
            }
        </div>
    );
});
