import { memo, useState } from "react";
import { Button, FormGroup, Label } from 'reactstrap';

import { ImpersonationDialogProps } from "./ImpersonationDialog";
import { PersonSelect } from "@Components/Pickers";
import { PrincipalModel } from "@Models";
import { appStore } from "@GlobalStores";
import { useModalContext } from "@Components/Modal/ModalContext";

export type IImpersonationModel = {
    principalId?: number;
    displayName?: string;
    email: string;
    objectId?: string;
}

type ImpersonationDialogBodyProps = ImpersonationDialogProps;

export const ImpersonationDialogBody = memo((props: ImpersonationDialogBodyProps) => {
    const [impersonatedPrincipal, setImpersonatedPincipal] = useState<IImpersonationModel | null>(null);
    const { close } = useModalContext();

    const onUserSelect = (users: PrincipalModel[] | null) => {
        if (users) {
            const { id: principalId, displayName: dn, email, objectId } = users[0];
            const displayName = dn.trim().length > 0 ? dn : email;
            setImpersonatedPincipal({ displayName, principalId, email, objectId });
        }
    }

    return (
        <>
            <FormGroup>
                <Label>Principal</Label>
                <PersonSelect onUsersSelect={onUserSelect} />
            </FormGroup>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    size="sm"
                    onClick={close}
                    style={{ marginRight: 10 }}
                >
                    Close
                </Button>
                <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                        if (!impersonatedPrincipal) return;
                        appStore.setValue('impersonatedPrincipal', impersonatedPrincipal);     
                        close();
                        window.history.replaceState({}, document.title, window.location.pathname);
                        window.location.reload();
                    }}
                >
                    Impersonate
                </Button>
            </div>
        </>
    );
});
