import * as React from 'react';

type SpinnerProps = {}

export class Spinner extends React.PureComponent<SpinnerProps, {}> {

    render() {
        return <div className="spinner" />;
    }
}
