import React from 'react';
import styles from './Header.module.scss';

import {Logo} from '@Components/Logo/Logo';
import {Userbar} from '@Components/Userbar/Userbar';
import {Menu} from '@Components/Menu/Menu';
import {authStore, AuthStore} from '@GlobalStores';

export class Header extends React.PureComponent {
    render() {
        const displayName = authStore.impersonatedPrincipal?.displayName || authStore.currentUser?.name || '';
        return (
            <div className={styles.container}>
                <div className="d-flex">
                    <Logo/>
                    <div className="ml-auto">
                        <Userbar name={displayName} onLogout={AuthStore.logOut}/>
                    </div>
                </div>
                <div className={styles.menu}>
                    <Menu/>
                </div>
            </div>
        );
    }
}
