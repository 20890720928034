import { observable, action, makeObservable } from 'mobx';

class AppStore {
    @observable isLoading: boolean = false;
    ajaxCount: number = 0;

    constructor() {
        makeObservable(this);
    }

    // TODO: fix loading 
    @action
    showLoading() {
        this.ajaxCount++;
        if (!this.isLoading) {
            this.isLoading = true;
        }
    }

    @action
    hideLoading() {
        this.ajaxCount--;
        if (this.ajaxCount === 0) {
            this.isLoading = false;
        }
    }

    public getValue<T>(key: string): T | null {
        const value = localStorage.getItem(key);
        return value ? (JSON.parse(value) as T) : null;
    }
    
    public setValue<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }
}

export const appStore = new AppStore();