import { makeObservable, observable } from 'mobx';
import { BaseFormModel, displayName } from '@Helpers';
import { DocumentState } from '@Models';

export class FormModel extends BaseFormModel<FormModel> {
    constructor() {
        super();
        makeObservable(this);
    }

    @observable
    @displayName('Status')
    state: DocumentState = DocumentState.Approved;

    @observable
    documentFile: File;

    @observable
    uploadedFileName?: string;
}