import * as React from 'react';
import './tab.scss';

type TabProps = {
    active?: boolean;
    disabled?: boolean;
    onClick: (e: React.MouseEvent) => void;
};

export const Tab: React.FunctionComponent<TabProps> = React.memo(({ active, onClick, children, disabled }) => {
    const cls:string[] = ['tab'];
    if (active) cls.push('active');
    if (disabled) cls.push('disabled');

    const clickHandler = disabled ? undefined : onClick;

    return (
        <div
            className={cls.join(' ')}
            onClick={clickHandler}
        >
            {children}
        </div>
    );
});
