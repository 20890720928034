import { apiClient } from "@Models";
import {BaseFormModel} from "@Helpers";
import {useCallback} from "react";
import {LookupModelFormPicker, LookupModelFormPickerProps} from "@Components/Pickers/LookupModelPicker/LookupModelFormPicker";
import { LookupModelPicker, LookupModelPickerBaseProps } from "../LookupModelPicker/LookupModelPicker";

// DEPRECATED!
export function TechnologyCenterFormPicker<T extends BaseFormModel<T>>({...rest} : LookupModelFormPickerProps<T>) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.technologyCenterLookupGet.bind(apiClient), []);
    return (
        <LookupModelFormPicker
            {...rest}
            loadData={loadData}
        />
    );
}

type TechnologyCenterPickerProps = {
    exceptTechnologyCenterId?: number
} & LookupModelPickerBaseProps;

export function TechnologyCenterPicker(props: TechnologyCenterPickerProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(() => {
        var fn = apiClient.technologyCenterLookupGet.bind(apiClient);
        return fn({exceptTechnologyCenterId: props.exceptTechnologyCenterId});
    }, [props.exceptTechnologyCenterId]);
    return (
        <LookupModelPicker
            {...props}
            loadData={loadData}
        />
    );
}