import * as React from 'react';
import styles from './image-selector.module.scss';
import { CustomInput } from 'reactstrap'
import { useEffect, useState } from 'react';
import { Thumbnail } from '../Thumbnail/Thumbnail';
import { modalService } from '../../Modal/ModalService';
import { FileFormat } from '@AppConstants';

export type ImageSelectorProps = {
    initialImageLink?: string;
    hint: string;
    onFileChanged: (file: File) => void;
    width?: number;
    height?: number;
    disabled?: boolean;
    maxFileSizeInMb?: number;
};

export function ImageSelector({ initialImageLink, hint, onFileChanged, width, height, disabled, maxFileSizeInMb }: ImageSelectorProps) {
    const acceptedImageFormats: string = [FileFormat.PNG, FileFormat.GIF, FileFormat.JPEG].join(',');
    const maxFileSize: number = 1024 * 1024 * (maxFileSizeInMb ?? 100);
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
        setImageUrl(initialImageLink);
    }, [initialImageLink]);

    const changeFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const files = Array.from(event.target.files);

        if (files && files.length) {
            const byFormat = (file: File) => acceptedImageFormats.indexOf(file.type) > -1;
            const bySize = (file: File) => file.size <= maxFileSize;

            const acceptedFiles = files.filter(byFormat).filter(bySize);
            if (acceptedFiles.length < files.length) {
                modalService.showError(`Wrong file format or size > ${maxFileSizeInMb ?? 100}MB`);
                return;
            }
            if (acceptedFiles.length) {
                if (imageUrl && imageUrl !== initialImageLink) {
                    window.URL.revokeObjectURL(imageUrl);
                }

                const localImageUrl = window.URL.createObjectURL(acceptedFiles[0]);
                setImageUrl(localImageUrl);
                onFileChanged(acceptedFiles[0]);
            }
        }
    };

    return (
        <>
            {initialImageLink &&
                <div className={styles.image}>
                    <Thumbnail link={imageUrl} width={width ?? 300} height={height ?? 300} alt={hint} />
                </div>
            }
            <CustomInput
                className={styles.fileInput}
                type="file"
                name="file"
                id="imageUpload"
                accept={acceptedImageFormats}
                onChange={changeFileHandler}
                disabled={disabled}
            />
        </>
    );
}