import React from 'react';
import { observer } from 'mobx-react';
import { Loading } from '../Loading';
import styles from './GlobalLoading.module.scss';
import { globalLoaderStore } from '@GlobalStores';


@observer
export class GlobalLoading extends React.PureComponent {
	public render() {
        if (!globalLoaderStore.isLoading) return null;

		return (
			<div className={styles.container}>
                <div className={styles.content}>
                    <Loading loading isSuspense className={styles.loading} />
                    {globalLoaderStore.loadingMessage && <div className={styles.message}>{globalLoaderStore.loadingMessage}</div>}
                </div>
            </div>
		);
	}
}
