import { memo } from 'react';
import { HiOutlineLink } from 'react-icons/hi';
import { IconButtonWrapper } from "@Components/IconButtonWrapper/IconButtonWrapper";

type LinkIconButtonProps = {
    onClick?: () => void;
    disabled?: boolean;
    size?: number;
    title?: string;
    className?: string;
};

function LinkIconButton({ onClick, disabled, size, className, title }: LinkIconButtonProps) {
	return (
        <IconButtonWrapper 
            disabled={disabled}
            onClick={onClick}
            IconType={HiOutlineLink}
            size={size}
            className={className}
            title={title}
        />
	);
}

const Memoized = memo(LinkIconButton);
export { Memoized as LinkIconButton };