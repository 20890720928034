import { observable, action } from 'mobx';
import { PromiseCompletion } from '@Helpers';

export type GlobalProgressData = {
    total: number;
    current: number;
    title: string;
};

class LoaderStore {
    public globalLoader: PromiseCompletion = new PromiseCompletion();
    @observable public globalProgress: GlobalProgressData | null = null;

    @action.bound
    public updateGlobalProgress(progressData: GlobalProgressData) {
        this.globalProgress = {
            total: progressData.total,
            current: progressData.current,
            title: progressData.title
        };
        if (progressData.total === progressData.current) {
            this.globalProgress = null;
        }
    }

}

export const loaderStore = new LoaderStore();