import { action, computed, makeObservable, observable } from "mobx";
import { AxiosPromise } from "axios";
import { apiClient } from "@Models";
import { FileUpload } from "@AppConstants";
import { PromiseCompletion } from "@Helpers";

export type FileInfo = {
    guid: string;
    name: string;
    size: number;
    progress: number;
    isUploaded: boolean;
}

class FilesUploadStore {
    @observable public filesList: FileInfo[] = [];
    @observable private _loader = new PromiseCompletion();

    constructor() {
        makeObservable(this);
    }

    public async uploadFileByChunks(file: File, onChunksUploaded: (lastChunk: Blob, guid: string) => AxiosPromise<void>) {
        const guid = this._generateFileGuid();
        this._addNewFile(file, guid);

        const chunkSize = FileUpload.TempFileChunkSizeInBytes;
        const chunksCount = file.size % chunkSize === 0
            ? file.size / chunkSize
            : Math.floor(file.size / chunkSize) + 1;

        let chunkStart = 0;
        let chunkEnd = chunkSize;

        for (let i = 1; i <= chunksCount; i++) {
            const chunkToUpload = file.slice(chunkStart, chunkEnd);

            await this._loader.add(() => apiClient.fileUploadPost({ data: chunkToUpload, fileName: file.name }, guid, i, chunksCount));
            if (i === chunksCount) {
                await this._loader.add(() => onChunksUploaded(chunkToUpload, guid));
            }

            this._calculateFileProgressByChunks(guid, i, chunksCount);

            chunkStart = chunkEnd;
            chunkEnd += chunkSize;
        }
    }

    private _generateFileGuid(): string {
        return (crypto as any).randomUUID();
    }

    @action.bound
    public clearData() {
        this.filesList = [];
    }

    @action.bound
    private _addNewFile(file: File, guid: string) {
        this.filesList.push({ guid, name: file.name, size: file.size, progress: 0, isUploaded: false });
    }

    @action.bound
    private _calculateFileProgressByChunks(guid: string, chunkNumber: number, chunksCount: number) {
        const progress = Math.floor(chunkNumber / chunksCount * 100);
        const isUploaded = progress === 100;

        this.filesList = this.filesList.map(fileInfo => {
            if (fileInfo.guid === guid) {
                return {
                    ...fileInfo,
                    progress,
                    isUploaded
                }
            }

            return fileInfo;
        })
    }

    @computed
    public get isLoading() {
        return this._loader.isPending;
    }

    @computed
    public get totalProgress() {
        let progressSum = 0;

        for (const file of this.filesList) {
            progressSum += file.progress;
        }

        return Math.floor(progressSum / this.filesList.length) || 0;
    }
}

export const filesUploadStore = new FilesUploadStore();
