import { apiClient } from "@Models";
import { LookupModelPicker, LookupModelPickerBaseProps } from '@Components/Pickers/LookupModelPicker/LookupModelPicker';
import {useCallback} from "react";

export function ClausePicker({...props}: LookupModelPickerBaseProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.clauseLookupGet.bind(apiClient),[]);
    return (
        <LookupModelPicker
            loadData={loadData}
            {...props}
        />
    );
}