import { apiClient, ProjectModel, ProjectStatus, ProjectUpdateModel } from "@Models";
import { Form } from '@Libs/Form';
import { modalService } from '@Components/Modal/ModalService';
import { Currency } from '@AppConstants';
import { ProjectDetailsModel } from "@Pages/Projects/Components/ProjectDetailsForm/ProjectFormModel";
import {ModalButtonType} from "@Components";

export async function handleDeleteClick({ id, displayName }: ProjectModel) {
    const confirmed = await modalService.showConfirmation(`Do you really want to delete project ${displayName}?`);
    if (confirmed === ModalButtonType.Confirm) await apiClient.projectByIdDelete({ id });
};

export async function handleStatusClick({ id, displayName }: ProjectModel, status: ProjectStatus) {
    if (status === ProjectStatus.Approved) {
        const confirmed = await modalService.showConfirmation(`Do you really want to approve project ${displayName}?`);
        if (confirmed === ModalButtonType.Confirm) {
            await apiClient.projectStatusPut({ id, status });
        }
    }
};

export function checkFormValid<T>(form: Form<T>, force = true): boolean {
    const { validate } = form;
    const validationErrors = validate(force);
    let isFormValid = true;
    for (const key in validationErrors) {
        if (Object.prototype.hasOwnProperty.call(validationErrors, key)) {
            const fieldErrors = validationErrors[key as keyof T];
            if (fieldErrors && fieldErrors.length) {
                isFormValid = false;
                break;
            }
        }
    }

    return isFormValid
}

export async function handleUpdateClick(form: Form<ProjectDetailsModel>, { id }: ProjectModel): Promise<ProjectModel | null> {
    const { formFields } = form;
    const isFormValid = checkFormValid(form);

    if (!isFormValid) return null;

    const updateProject: ProjectUpdateModel = {
        id,
        displayName: formFields.displayName,
        leadOrgUnitId: formFields.leadOrgUnitId!,
        norrdProjectId: formFields.norrdProjectId,
        workPackageId: formFields.workPackageId,
        responsibleTechnologyCenterId: formFields.responsibleTechnologyCenterId,
        projectManagerId: formFields.projectManagerId as number, // check existance with validator (can be empty on codependent picker change)
        budgetEstimation: (formFields.budgetEstimation || 0) * 1000, // TODO: on change?
        currencyId: Currency.USD,
        plannedStartDate: formFields.plannedStartDate,
        plannedEndDate: formFields.plannedEndDate,
        isSecret: formFields.isSecret,
        gateModel: formFields.gateModel,
        gateInNord: formFields.gateInNord,
        completionTargetDate: formFields.completionTargetDate,
        forecastOrActualDate: formFields.forecastOrActualDate
    };

    const { data } = await apiClient.projectPut(updateProject);
    return data;
}
