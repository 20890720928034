import React, { useCallback } from "react";
import { apiClient } from "@Models";
import {BaseFormModel} from "@Helpers";
import {
    LookupModelEnumFormPicker,
    LookupModelEnumFormPickerProps
} from "@Components/Pickers/LookupModelEnumPicker/LookupModelEnumFormPicker";

import { LookupModelEnumPicker, LookupModelEnumPickerBaseProps } from "../LookupModelEnumPicker/LookupModelEnumPicker";

export const ReasonForFailurePicker = React.memo(function Picker(props: LookupModelEnumPickerBaseProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.enumReasonForFailureGet.bind(apiClient), []);
    return (
        <LookupModelEnumPicker
            {...props}
            loadData={loadData}
        />
    );
})

export function ReasonForFailureFormPicker<T extends BaseFormModel<T>>({...rest} : LookupModelEnumFormPickerProps<T>) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.enumReasonForFailureGet.bind(apiClient), []);
    return (
        <LookupModelEnumFormPicker
            {...rest}
            loadData={loadData}
        />
    );
}