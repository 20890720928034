import { apiClient } from "@Models";
import { LookupModelPicker, LookupModelPickerBaseProps } from '@Components/Pickers/LookupModelPicker/LookupModelPicker';
import {BaseFormModel} from "@Helpers";
import {LookupModelFormPicker,LookupModelFormPickerProps} from "@Components/Pickers/LookupModelPicker/LookupModelFormPicker";
import {useCallback} from "react";

export function MemberPicker({ ...props }: LookupModelPickerBaseProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.memberLookupGet.bind(apiClient), []);
    return (
        <LookupModelPicker
            {...props}
            loadData={loadData}
        />
    );
}


export function MemberFormPicker<T extends BaseFormModel<T>>({...rest} : LookupModelFormPickerProps<T>) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.memberLookupGet.bind(apiClient), []);
    return (
        <LookupModelFormPicker
            {...rest}
            loadData={loadData}
        />
    );
}