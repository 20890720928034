import { observable, action, makeObservable } from 'mobx';
import dxButton from 'devextreme/ui/button';
import dxDataGrid from 'devextreme/ui/data_grid';

import * as DxDataGridTypes from './DxDataGridTypes';
import { DataGridColumn } from './DxDataGridColumn';
import { DxGridHelper } from '@Helpers';
import { Extensions } from '@Services';


export type DxDataGridToolbarProps<T> = {
    saveStateHandler: (gridState: DxDataGridTypes.GridState) => Promise<void>;
    onCustomToolBarRender?: () => JSX.Element;
    onConfigReset: () => void;
    onFiltersReset: (gridState: DxDataGridTypes.GridState) => void;
    customColumns: DataGridColumn<T>[];
};

export type Toolbar = {
    component?: dxDataGrid;
    element?: Element;
    toolbarOptions?: ToolbarOptions;
};

type ToolbarOptions = {
    items?: Array<ToolbarItem>;
};

type ToolbarItem = {
    location?: 'after' | 'before' | 'center';
    options?: ToolbarItemOptions;
    widget?: 'dxAutocomplete' | 'dxButton' | 'dxCheckBox' | 'dxDateBox' | 'dxMenu' | 'dxSelectBox' | 'dxTabs' | 'dxTextBox' | 'dxButtonGroup' | 'dxDropDownButton';
    template?: string;
    saveStateHandler?: (gridState: DxDataGridTypes.GridState) => void;
    name?: string;
    visible?: boolean;
};

type ToolbarItemOptions = {
    icon?: string;
    hint?: string;
    disabled?: boolean;
    visible?: boolean;
    elementAttr?: unknown;
    onClick?: Function;
    onInitialized?: Function;
};

export class DxDataGridToolbar<T>{
    @observable.ref private _clearFiltersButton?: dxButton;
    @observable.ref private _resetConfigurationButton?: dxButton;
    @observable.ref private _resetGroupingButton?: dxButton;
    @observable.ref private _collapseAllGroupsButton?: dxButton;
    @observable.ref private _expandAllGroupsButton?: dxButton;
    @observable.ref private _dataGrid?: dxDataGrid;
    private _props: DxDataGridToolbarProps<T>;

    constructor(props: DxDataGridToolbarProps<T>) {
        makeObservable(this);
        this._props = props;
        this.updateButtonsState = this.updateButtonsState.bind(this);
    }

    public updateProps(props: DxDataGridToolbarProps<T>) {
        this._props = props;
        this.updateButtonsState(this._props.customColumns, this._dataGrid?.state());
    }

    public createToolbar(e: Toolbar, hideSaveRevertButtons?: boolean) {
        this._dataGrid = e.component;

        if (hideSaveRevertButtons) {
            e.toolbarOptions?.items?.forEach(item => {
                if (item.name === "saveButton" || item.name === "revertButton") {
                    item.visible = false;
                }
            });
        }
        
        e.toolbarOptions?.items?.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'clearformat',
                    hint: 'Clear all filters',
                    disabled: true,
                    onClick: () => this._clearFiltersState(this._dataGrid),
                    onInitialized: (args: DxDataGridTypes.OnInitializedParams<dxButton>) => {
                        this._clearFiltersButton = args.component;
                    }
                },
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    visible: false,
                    elementAttr: { class: 'dx-clear-btn' },
                    icon: 'fa fa-cog',
                    hint: 'Reset configuration',
                    onClick: () => this._resetStateToDefault(this._dataGrid),
                    onInitialized: (args: DxDataGridTypes.OnInitializedParams<dxButton>) => {
                        this._resetConfigurationButton = args.component;
                    },
                }
            },
            {
                location: 'before',
                widget: 'dxButton',
                options: {
                    visible: false,
                    icon: 'fas fas fa-times',
                    hint: 'Reset grouping',
                    onClick: () => this._dataGrid?.clearGrouping(),
                    onInitialized: (args: DxDataGridTypes.OnInitializedParams<dxButton>) => {
                        this._resetGroupingButton = args.component;
                    },
                }
            },
            {
                location: 'before',
                widget: 'dxButton',
                options: {
                    visible: false,
                    icon: 'fas fa-angle-double-down',
                    hint: 'Expand all groups',
                    onClick: () => this._dataGrid?.expandAll(),
                    onInitialized: (args: DxDataGridTypes.OnInitializedParams<dxButton>) => {
                        this._expandAllGroupsButton = args.component;
                    },
                }
            },
            {
                location: 'before',
                widget: 'dxButton',
                options: {
                    visible: false,
                    icon: 'fas fa-angle-double-up',
                    hint: 'Collapse all groups',
                    onClick: () => this._dataGrid?.collapseAll(),
                    onInitialized: (args: DxDataGridTypes.OnInitializedParams<dxButton>) => {
                        this._collapseAllGroupsButton = args.component;
                    },
                }
            }
        );

        if (this._props.onCustomToolBarRender) {
            // TODO: check contract
            e.toolbarOptions?.items?.unshift({
                location: 'before',
                template: 'customToolBar',
                saveStateHandler: this._props.saveStateHandler
            });
        }
    }

    public updateGroupingState = (gridState: DxDataGridTypes.GridState) => {
        const isAnyGrouped = gridState.columns?.some(x => Number(x.groupIndex) >= 0);
        this._resetGroupingButton?.option('visible', isAnyGrouped);
        this._expandAllGroupsButton?.option('visible', isAnyGrouped);
        this._collapseAllGroupsButton?.option('visible', isAnyGrouped);
    }

    private _clearFiltersState(dataGrid?: dxDataGrid) {
        dataGrid?.clearFilter();
        dataGrid?.clearSorting();

        const state = dataGrid?.state() as DxDataGridTypes.GridState;
        DxGridHelper.applyFiltersToState(state, this._getDefaultFilters(this._props.customColumns));
        state.searchText = '';
        dataGrid?.state(state);

        if (this._props.onFiltersReset) {
            this._props.onFiltersReset(state);
        }
    }

    @action
    private _resetStateToDefault(dataGrid?: dxDataGrid) {
        dataGrid?.clearFilter();
        dataGrid?.clearSorting();
        dataGrid?.state(null);

        const state = dataGrid?.state() as DxDataGridTypes.GridState;
        DxGridHelper.applyFiltersToState(state, this._getDefaultFilters(this._props.customColumns));

        if (this._props.onConfigReset) {
            this._props.onConfigReset();
        }

        dataGrid?.state(state);
    }

    private _getDefaultFilters(columns: DataGridColumn<T>[]): DxDataGridTypes.IGridFilter[] {
        const result: DxDataGridTypes.IGridFilter[] = [];

        for (const column of columns) {
            if (column.dataFieldType && column.defaultHeaderFilterOption) {
                const filter: DxDataGridTypes.IGridFilter = {
                    field: column.dataFieldName?.toString() || '',
                    fieldType: column.dataFieldType,
                    filterType: column.defaultHeaderFilterOption.filterType, values: column.defaultHeaderFilterOption.values
                };
                result.push(filter);
            }

            if (column.columns && column.columns.length > 0) {
                result.push(...this._getDefaultFilters(column.columns));
            }
        }

        return result;
    }

    @action
    public updateButtonsState(gridColumns: DataGridColumn<T>[], gridState: DxDataGridTypes.GridState) {
        if (!gridState?.columns) return;
        // eslint-disable-next-line no-prototype-builtins
        const hasSorting = gridState?.columns.some(x => x.hasOwnProperty('sortIndex'));

        const cloneGridState = Extensions.deepCopy(gridState) as DxDataGridTypes.GridState;
        if (gridState.columns) {
            for (const column of cloneGridState.columns) {
                column.filterValues = undefined;
            }
        }
        DxGridHelper.applyFiltersToState(cloneGridState, this._getDefaultFilters(this._props.customColumns));

        const hasFiltering = gridState?.columns.some(x => {
            // eslint-disable-next-line no-prototype-builtins
            if (x.hasOwnProperty('filterValue') && x['filterValue']) {
                return true;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (x.hasOwnProperty('filterValues') && x['filterValues']) {
                const defaultFilter = cloneGridState.columns?.find(c => c.dataField === x.dataField);
                if (defaultFilter) {
                    const colFiltersStr = JSON.stringify(x.filterValues);
                    const defFilterStr = JSON.stringify(defaultFilter.filterValues);
                    return colFiltersStr !== defFilterStr;
                }
            }

            return false;
        });

        const hasSearching = gridState?.searchText ? true : false;
        // eslint-disable-next-line no-prototype-builtins
        const hasGrouping = gridState.columns?.some(x => x.hasOwnProperty('groupIndex'));

        const hiddenByDisplayMode = new Set(
            gridColumns.filter(x => x.displayMode && !x.displayMode.has(DxDataGridTypes.DataGridColumnDisplayMode.UI)).map(x => x.dataFieldName));
        const hidden = gridState.columns?.filter(x => x.visible === false);
        const hasHidden = hidden.some(x => !hiddenByDisplayMode.has(x.dataField as keyof T));

        const getDefaultColumnsOrderArray = (columns: DataGridColumn<T>[]): { visibleIndex: number; dataField: keyof T | '' }[] => {
            const array: { visibleIndex: number; dataField: keyof T | '' }[] = [];
            for (let i = 0; i < columns.length; i++) {
                array.push({ visibleIndex: i, dataField: columns[i].dataFieldName || '' });
                if (columns[i].columns) {
                    array.push(...getDefaultColumnsOrderArray((columns[i].columns && columns[i].columns) || []));
                }
            }
            return array;
        };

        const currentColumnArray: { visibleIndex: number; dataField: keyof T }[] = [];
        if (gridState.columns) {
            for (let i = 0; i < gridState.columns.length; i++) {
                currentColumnArray.push({ visibleIndex: gridState.columns[i].visibleIndex, dataField: gridState.columns[i].dataField as keyof T });
            }
        }

        const defaultColumnOrder = JSON.stringify(getDefaultColumnsOrderArray(gridColumns));
        const currentColumnOrder = JSON.stringify(currentColumnArray);

        const hasChangedOrdering = defaultColumnOrder !== currentColumnOrder;

        const isClearButtonDisabled = !hasSorting && !hasFiltering && !hasSearching;
        const isResetButtonVisible = hasGrouping || hasSorting || hasFiltering || hasSearching || hasChangedOrdering || hasHidden;

        this._resetConfigurationButton?.option('visible', isResetButtonVisible);
        this._clearFiltersButton?.option('disabled', isClearButtonDisabled);
    }
}
