import React, { useState } from 'react';
// import { observable } from 'mobx';
// import { observer } from 'mobx-react';
// import styles from './LinkWithCopy.module.scss';
import stylesNew from './LinkWithCopyNew.module.scss';
import { LinkIconButton } from "@Components/Buttons/LinkIconButton/LinkIconButton";
// import { Tooltip } from 'devextreme-react';
// import nextId from 'react-id-generator';

// export type kWithCopyProps = {
//     text: string;
//     // renderChild?: (children: React.ReactNode) => React.ReactNode;
// };

// @observer
// export class WithCopy extends React.PureComponent<kWithCopyProps> {
//     @observable private _copied = false;
//     @observable private _isTooltipVisible = false;

//     private _id = nextId();

//     render() {
//         const { children, text } = this.props;
//         return (
//             <div className={styles.container}>
//                 {/* {text && (
//                     <div className={`${styles.iconWrapper} copyIcon`}>
//                         <div
//                             onClick={() => {
//                                 const mark = document.createElement('div');

//                                 mark.style.whiteSpace = 'pre';
//                                 mark.style.position = 'fixed';
//                                 mark.style.top = '0';
//                                 mark.style.clip = 'rect(0, 0, 0, 0)';

//                                 document.body.appendChild(mark);
//                                 mark.innerHTML = text;
//                                 const selection = window.getSelection();
//                                 if (selection) {
//                                     selection.removeAllRanges();
//                                     const range = document.createRange();
//                                     range.selectNode(mark);
//                                     selection.addRange(range);
//                                     const listener = (e: ClipboardEvent) => {
//                                         e.clipboardData?.setData('text/html', text);
//                                         e.clipboardData?.setData('text/plain', mark.innerText);
//                                         e.preventDefault();
//                                         this._copied = true;
//                                     };
//                                     document.addEventListener('copy', listener);
//                                     document.execCommand('copy');
//                                     document.removeEventListener('copy', listener);
//                                     selection.removeAllRanges();
//                                 }
//                                 document.body.removeChild(mark);
//                             }}
//                             onMouseEnter={() => (this._isTooltipVisible = true)}
//                             onMouseLeave={() => (this._isTooltipVisible = false)}
//                         >
//                             <FaRegCopy style={{ color: '#555' }} id={'copy' + this._id} />
//                         </div>
//                     </div>
//                 )} */}


                
//                 {/*{/* {
//                     text &&
//                     <div>
//                         <FaRegCopy style={{ color: '#555' }} id={'copy' + this._id} />
//                     </div>
//                 } */}

//                 {/* {renderChild ? (
//                     <div className={styles.text}>{renderChild(children)}</div>
//                 ) : (
//                     <div className={styles.text}>{children} </div>
//                 )} */}
//                 <div className={styles.wrapper}>
//                     {children}
//                     {text && <FaRegCopy style={{ color: '#555', cursor: 'pointer' }} id={'copy' + this._id} title={this._copied ? 'Copied' : 'Copy to clipboard'} />}
//                 </div>

//                 {text && (
//                     <Tooltip position="right" visible={this._isTooltipVisible} target={'#copy' + this._id}>
//                         {this._copied ? 'Copied' : 'Copy to clipboard'}
//                     </Tooltip>
//                 )}
//             </div>
//         );
//     }
// }

type Props = {
    text?: string;
}

const WithCopy: React.FC<Props> = ({ text, children }) => {
    const [copied, setCopied] = useState(false);
    const title = copied ? 'Copied' : 'Copy to clipboard';
    const cls = [stylesNew.icon];
    if (copied) cls.push(stylesNew.copied);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(text || '');
        setCopied(true);
    }

    return (
        <div className={stylesNew.container}>
            {children}
            {text && <LinkIconButton className={cls.join(' ')} title={title} onClick={handleCopyClick} />}
        </div>
    );
}

export { WithCopy };