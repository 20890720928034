const HISTORY_VIEW_CLASS_NAME = 'historyView';

class AppViewService {
    public enableHistoryView() {
        this.addContentClass(HISTORY_VIEW_CLASS_NAME);
    }

    public disableHistoryView() {
        this.removeContentClass(HISTORY_VIEW_CLASS_NAME);
    }

    public addContentClass(className: string) {
        const content = this._getContentElement();
        if (!content) return;

        content.classList.add(className);
    }

    public removeContentClass(className: string) {
        const content = this._getContentElement();
        if (!content) return;

        content.classList.remove(className);
    }

    private _getContentElement() {
        return document.getElementById('appContent');
    }
}

export const appViewService = new AppViewService();
