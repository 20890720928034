class UrlService {
    public openInNewWindow(url: string, isAbsolute?: boolean) {
        const link = isAbsolute ? this._parseUrl(url) : url;

        window.open(link, '_blank', 'noreferrer');
    }

    private _parseUrl(url: string) {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        }

        return 'https://' + url;
    }
}

export const urlService = new UrlService();
