import { ObjectType } from '@AppConstants';

const applicationObjectType = 'APP';

export const createUniqueId = (objectType: string, id: number) => {
    return objectType === applicationObjectType ? applicationObjectType : `${objectType}-${id}`;
};

export const getObjectType = (objectUniqueId: string) => {
    const index = objectUniqueId.indexOf('-');
    return index > 0 ? objectUniqueId.substring(0, index) : '';
};

export const splitUid = (objectUniqueId: string) => {
    let objType: string;
    let id: number;
    if (objectUniqueId === applicationObjectType) {
        objType = applicationObjectType;
        id = 0;
    } else {
        objType = getObjectType(objectUniqueId);
        id = parseInt(objectUniqueId.substring(4), 10);
    }

    const result: [string, number] = [objType, id];
    return result;
};

export function getIdFromObjectHierarchy(objectHierarchy: string, objectType: ObjectType) {
    const uniqueIds = objectHierarchy.split(';');

    for (const uniqueId of uniqueIds) {
        const index = uniqueId.search(objectType as string);
        if (index !== -1) {
            return parseInt(uniqueId.substring(index + (objectType as string).length + 1), 10);
        }
    }
}
