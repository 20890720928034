import { memo, useRef } from 'react';
import { DataGrid } from 'devextreme-react';
import { AxiosPromise } from 'axios';

import { AvailableTestReportModel, TestReportWithConfigsModel } from '@Models';

import { modalService } from '@Components/Modal/ModalService';
import { ModalButtonType } from '@Components';
import { useDxDatagridStore } from '@Components/DataGrid/Hooks/Hooks';
import { TestDocumentsDialog } from '@Pages/Components/TestReports/Dialogs/TestDocumentsDialog/TestDocumentsDialog';
import { TestReportReferenceDialog } from '@Pages/Components/TestReports/Dialogs/TestReportReferenceDialog/TestReportReferenceDialog';
import { TestReportWithConfigsGrid } from '@Components/Grids/TestReportWithConfigsGrid/TestReportWithConfigsGrid';
import { SourceMode } from '@Pages/Components/ManageTestDocuments/TestDocumentsGrid';

type DoCTestReportsTableProps = {
    canPrincipalManage: boolean;
    technologyCenterId?: number;
    typeTestMatrixId?: number;
    sourceMode: SourceMode;
    declarationOfConformityId: number;
    loadData: () => AxiosPromise<TestReportWithConfigsModel[]>;
    loadReferenceTestReports?: (isOtherTechnologyCenter: boolean, otherTechnologyCenterId?: number) => AxiosPromise<AvailableTestReportModel[]>;
    onAddTestReport?: (testReportId: number) => AxiosPromise<void>;
    onDeleteTestReport?: (testReportId: number) => AxiosPromise<void>;
}

export const DoCTestReportsTable = memo(({
    canPrincipalManage, technologyCenterId, typeTestMatrixId, sourceMode, declarationOfConformityId,
    loadData, loadReferenceTestReports, onAddTestReport, onDeleteTestReport
}: DoCTestReportsTableProps) => {
    const gridRef = useRef<DataGrid<TestReportWithConfigsModel, any>>(null);
    const [options, reloadGridOptions] = useDxDatagridStore<TestReportWithConfigsModel>(loadData, gridRef);

    const handleDocumentsClick = async (model: TestReportWithConfigsModel) => {
        await modalService.show(TestDocumentsDialog, {
            testReportId: model.id,
            testReportNumber: model.number,
            typeTestMatrixId,
            sourceMode: sourceMode,
            declarationOfConformityId: declarationOfConformityId,
            isEditable: false
        });
    }

    const handleDeleteClick = async (model: TestReportWithConfigsModel) => {
        const confirmed = await modalService.showConfirmation('Do you really want to remove this Test Report from the DoC?');
        if (confirmed !== ModalButtonType.Confirm) return;

        await onDeleteTestReport?.(model.id);
        reloadGridOptions();
    }

    const handleAddTestReportClick = async () => {
        if (!technologyCenterId || !loadReferenceTestReports) return;

        await modalService.show(TestReportReferenceDialog, {
            ownTcId: technologyCenterId,
            title: 'Add Test Report',
            loadData: loadReferenceTestReports,
            onAddClick: async (testReport) => {
                await onAddTestReport?.(testReport.id);
            },
            onDeleteClick: async (testReport) => {
                await onDeleteTestReport?.(testReport.id);
            }
        });

        reloadGridOptions();
    }

    return (
        <TestReportWithConfigsGrid 
            gridRef={gridRef}
            options={options}
            sourceMode={sourceMode}
            canPrincipalManage={canPrincipalManage}
            onDocumentsClick={handleDocumentsClick}
            onDeleteClick={handleDeleteClick}
            onAddTestReportClick={handleAddTestReportClick}
        />
    );
});
