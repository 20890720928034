import { memo, useEffect, useState } from 'react';
import { apiClient, ISearchParams, PrincipalModel } from '@Models';
import { ReactSelectStyles} from '@Components';

import Select, { ActionMeta, GroupBase, InputActionMeta } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useCallback } from 'react';
import { useMountEffect } from '@Hooks';

const DEFAULT_PAGE_SIZE = 10;

type PersonPickerChangeCallback = (users: PrincipalModel[] | null) => void;
type PickerProps = {
    clearAfterSelect?: boolean;
    defaultPrincipalId?: number | null;
    placeholder?: string;
    isDisabled?: boolean;
    className?: string;
    pageSize?: number;
    invalid?: boolean;
    isCreatable?: boolean;
    isClearable?: boolean;
    // needPortal?: boolean;
    resetDefaultPrincipalId?: number | null;
    onUsersSelect?: PersonPickerChangeCallback;
    onGetCustomRequestParams?: () => Partial<ISearchParams>;
}

function usePaginatedLoading() {
    const [options, setOptions] = useState<PrincipalModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);

    const loadOptions = useCallback(async (params: ISearchParams) => {
        setLoading(true);
        apiClient.principalSearchGet(params).then(({ data: options }) => {
            setLoading(false);
            if (params.page === 0) {
                setOptions(() => options);
            }
            else {
                setOptions((prevOptions) => [...prevOptions, ...options]);
            }
        });
    }, [setOptions, setLoading]);

    const loadPage = useCallback(async (params: Partial<ISearchParams>, page: number) => {
        params.page = page;
        params.size = DEFAULT_PAGE_SIZE;
        loadOptions(params);
    }, [loadOptions]);

    const loadFirstPage = (params: Partial<ISearchParams>) => loadPage(params, 0);

    const loadNextPage = useCallback(async (params: Partial<ISearchParams>) => {
        loadPage(params, page + 1);
        setPage(prevpage => prevpage + 1);
    }, [page, setPage, loadPage]);

    const resetOptions = useCallback(() => {
        setPage(0);
        setOptions([]);
    }, [setPage, setOptions]);

    return { options, loading, page, loadFirstPage, loadNextPage, resetOptions, setOptions, setPage }
}

export const renderCustomLabel = (option: PrincipalModel) => {
    const createOption = option as unknown as { value: string; label: string; __isNew__: boolean };

    if (createOption.__isNew__) {
        return <div>{createOption.label}</div>;
    }

    return (
        <div key={option.id} style={{ display: 'flex', width: '400px' }}>
            <div style={{ width: '200px' }}>{option.displayName}</div>
            <div style={{ width: '100px' }}>{option.subDepartment}</div>
            <div style={{ width: '100px' }}>{option.country}</div>
        </div>
    );
};

export const PersonSelect = memo((props: PickerProps) => {
    const { defaultPrincipalId, resetDefaultPrincipalId, isCreatable, onGetCustomRequestParams } = props;
    const { options, loading, loadFirstPage, loadNextPage, resetOptions, setOptions, setPage } = usePaginatedLoading();

    const [selected, setSelected] = useState<PrincipalModel | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [mounted, setMounted] = useState(false);

    const getRequestParams = useCallback((inputValue: string = '') => {
        const params: Partial<ISearchParams> = onGetCustomRequestParams ? onGetCustomRequestParams() : {};
        if (inputValue) params.term = inputValue;
        return params;
    }, [onGetCustomRequestParams]);

    useMountEffect(() => {
        loadFirstPage(getRequestParams());
        setMounted(true);
    
        if (!selected && defaultPrincipalId) {
            apiClient.principalGet({ id: +defaultPrincipalId }).then(({ data }) => {
                setSelected(data);
            });
        }
    });
    
    useEffect(() => {
        if (!mounted) return;
        resetOptions();
        loadFirstPage(getRequestParams(inputValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGetCustomRequestParams]);

    useEffect(() => {
        if (!mounted) return;
        if (selected && selected.id === resetDefaultPrincipalId) {
            return;
        }

        if (!resetDefaultPrincipalId) {
            setSelected(null);
        } else {
            apiClient.principalGet({ id: +resetDefaultPrincipalId }).then(({ data }) => {
                setSelected(data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetDefaultPrincipalId]);

    function handleInputChange(value: string, actionMeta: InputActionMeta) {
        const closedMenu = actionMeta.action === 'menu-close' && !value && inputValue;
        const changedInput = actionMeta.action === 'input-change';
        if (closedMenu || changedInput) {
            resetOptions();
            loadFirstPage(getRequestParams(value));
        }

        setInputValue(value);
    }

    function handleSelectionChange(value: PrincipalModel | null, actionMeta: ActionMeta<PrincipalModel>) {
        setSelected(value);
        const selected = value ? [value] : [];
        props.onUsersSelect?.(selected);
    }

    const { className, isDisabled, isClearable, placeholder, invalid } = props;
    const cls = ['react-select'];
    className && cls.push(className);

    async function handleCreateClick(inputValue: string) {
        apiClient.principalPost({ email: inputValue }).then(({ data }) => {
            setOptions((options) => [...options, data ]);
            setSelected(data);
            setPage(0);
            props.onUsersSelect?.([data]);
        });
    }   

    if (isCreatable) {
        return (
            <CreatableSelect<PrincipalModel>
                onCreateOption={handleCreateClick}
                className={cls.join(' ')}
                classNamePrefix="react-select"
                options={options}
                value={selected}
                isSearchable
                isLoading={loading}
                isDisabled={isDisabled}
                isClearable={isClearable}
                
                onChange={handleSelectionChange}
                onInputChange={handleInputChange}
                onMenuScrollToBottom={() => loadNextPage(getRequestParams(inputValue))}
                // menuPortalTarget={needPortal ? document.body : null}
                menuPortalTarget={document.body}
                formatOptionLabel={renderCustomLabel}
                styles={{ ...ReactSelectStyles<PrincipalModel, false, GroupBase<PrincipalModel>>(!!invalid), ...({}) }}
                placeholder={placeholder || 'Select Person'}
                filterOption={() => true} // does not recognize new options with search open // options are filtered on BE
            />
        );
    } else {
        return (
            <Select<PrincipalModel>
                className={cls.join(' ')}
                classNamePrefix="react-select"
                options={options}
                value={selected}
                isSearchable
                isLoading={loading}
                isDisabled={isDisabled}
                isClearable={isClearable}
                
                onChange={handleSelectionChange}
                onInputChange={handleInputChange}
                onMenuScrollToBottom={() => loadNextPage(getRequestParams(inputValue))}
                // menuPortalTarget={needPortal ? document.body : null}
                menuPortalTarget={document.body}
                formatOptionLabel={renderCustomLabel}
                styles={{ ...ReactSelectStyles<PrincipalModel, false, GroupBase<PrincipalModel>>(!!invalid), ...({}) }}
                placeholder={placeholder || 'Select Person'}
                filterOption={() => true} // does not recognize new options with search open // options are filtered on BE
            />
        );       
    }   
});
