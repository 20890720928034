import { Key } from 'react';
import {
    DataGridColumnContentAlignment,
    DataGridColumnFieldType,
    HeaderFilterPostProcessOptions,
    CellData,
    CellValue,
    CellInfo,
    DataGridColumnDisplayMode,
    HeaderFilterPredefinedOption,
    SearchFilterOperation,
    GroupCellData,
    DataGridColumnSortOrder,
    HeaderCellData,
    ValidationRule,
    DataGridColumnEditorOptions
} from './DxDataGridTypes';

export enum DataGridColumnType {
    Data,
    Dynamic,
    Band
}

export class DataGridColumnDescriptor<T> {
    key?: Key | null | undefined;
    caption: string;
    tooltip?: string;
    dataFieldName?: keyof T;
    dataFieldType?: DataGridColumnFieldType;
    allowGrouping?: boolean;
    allowSearch?: boolean;
    allowedSearchFilterOperations?: SearchFilterOperation[];
    allowDropdownFiltering?: boolean;
    allowSearchFiltering?: boolean;
    allowHiding?: boolean;
    allowSorting?: boolean;
    allowResizing?: boolean;
    allowEditing?: boolean;
    contentAlignment?: DataGridColumnContentAlignment;
    sortOrder?: DataGridColumnSortOrder;
    trueText?: string;
    falseText?: string;
    calculateCellValue?: (rowData: T) => CellValue;
    calculateSortValue?: (rowData: T) => CellValue;
    customizeCellText?: (cellInfo: CellData) => string;
    cellRenderTemplate?: (props: CellInfo<T>) => React.ReactNode;
    headerCellRenderTemplate?: (props: HeaderCellData) => React.ReactNode;
    editCellRenderTemplate?: (props: CellInfo<T>) => React.ReactNode;
    groupCellRenderTemplate?: (props: GroupCellData<T>) => React.ReactNode;
    customizeHeaderFilterOptions?: (options: HeaderFilterPostProcessOptions) => void;
    calculateFilterExpression?: (filterValue: unknown, selectedFilterOperation: string, target: string) => string[][];
    onBuildCustomColumnName?: () => string;
    isHidden?: boolean;
    columns?: DataGridColumn<T>[];
    minWidth?: number;
    width?: number;
    printWidth?: number;
    displayMode?: DataGridColumnDisplayMode[];
    enableCultureSpecificSorting?: boolean;
    defaultHeaderFilterOption?: HeaderFilterPredefinedOption;
    defaultFilterValue?: string | null;
    groupIndex?: string | null;
    name?: string;
    fixed?: boolean;
    fixedPosition?: 'left' | 'right';
    validationRules?: ValidationRule[];
    cssClass?: string;
    visibleIndex?: number;
    lookup?: {
        dataSource: unknown[];
        valueExpr: string;
        displayExpr: string;
    };
    editorOptions?: DataGridColumnEditorOptions;
}

export class DataGridColumn<T> {
    key?: Key | null | undefined;
    caption: string;
    tooltip?: string;
    dataFieldName?: keyof T;
    dataFieldType?: DataGridColumnFieldType;
    allowGrouping: boolean;
    allowSearch: boolean;
    allowDropdownFiltering: boolean;
    allowSearchFiltering?: boolean;
    allowedSearchFilterOperations?: SearchFilterOperation[];
    allowHiding: boolean;
    allowSorting: boolean;
    allowResizing: boolean;
    allowEditing: boolean;
    contentAlignment?: DataGridColumnContentAlignment;
    sortOrder?: DataGridColumnSortOrder;
    trueText?: string;
    falseText?: string;
    calculateCellValue?: (rowData: T) => CellValue;
    calculateSortValue?: (rowData: T) => CellValue;
    customizeCellText?: (cellInfo: CellData) => string;
    cellRenderTemplate?: (props: CellInfo<T>) => React.ReactNode;
    headerCellRenderTemplate?: (props: HeaderCellData) => React.ReactNode;
    editCellRenderTemplate?: (props: CellInfo<T>) => React.ReactNode;
    groupCellRenderTemplate?: (props: GroupCellData<T>) => React.ReactNode;
    headerFilterOptions?: (options: HeaderFilterPostProcessOptions) => void;
    calculateFilterExpression?: (filterValue: unknown, selectedFilterOperation: string, target: string) => string[][];
    isHidden?: boolean;
    columns?: DataGridColumn<T>[];
    defaultWidth?: number;
    minWidth?: number;
    width?: number;
    printWidth?: number;
    displayMode?: Set<DataGridColumnDisplayMode>;
    enableCultureSpecificSorting?: boolean;
    columnType: DataGridColumnType;
    defaultHeaderFilterOption?: HeaderFilterPredefinedOption;
    name?: string;
    defaultFilterValue?: string | null;
    groupIndex?: string | null;
    fixed?: boolean;
    fixedPosition?: 'left' | 'right';
    buildCustomColumnName?: () => string;
    validationRules?: ValidationRule[];
    cssClass?: string;
    visibleIndex?: number;
    lookup?: {
        dataSource: unknown[];
        valueExpr: string;
        displayExpr: string;
    };
    editorOptions?: DataGridColumnEditorOptions;

    constructor(props: DataGridColumnDescriptor<T>) {
        this.key = props.key;
        this.caption = props.caption;
        this.tooltip = props.tooltip;
        this.dataFieldName = props.dataFieldName;
        this.dataFieldType = typeof props.dataFieldType === 'undefined' ? DataGridColumnFieldType.string : props.dataFieldType;
        this.allowGrouping = typeof props.allowGrouping  === 'undefined' ? true : props.allowGrouping;
        this.allowSearch = typeof props.allowSearch === 'undefined' ? true : props.allowSearch;
        this.allowedSearchFilterOperations = props.allowedSearchFilterOperations;
        this.allowDropdownFiltering = typeof props.allowDropdownFiltering === 'undefined' ? true : props.allowDropdownFiltering;
        this.allowSearchFiltering = typeof props.allowSearchFiltering === 'undefined' ? true : props.allowSearchFiltering;
        this.allowHiding = typeof props.allowHiding === 'undefined' ? true : props.allowHiding;
        this.allowSorting = typeof props.allowSorting === 'undefined' ? true : props.allowSorting;
        this.allowResizing = typeof props.allowResizing === 'undefined' ? true : props.allowResizing;
        this.allowEditing = typeof props.allowEditing === 'undefined' ? false : props.allowEditing;
        this.contentAlignment = typeof props.contentAlignment === 'undefined' ? DataGridColumnContentAlignment.left : props.contentAlignment;
        this.sortOrder = props.sortOrder;
        this.isHidden = typeof props.isHidden === 'undefined' ? false : props.isHidden;
        this.calculateCellValue = props.calculateCellValue;
        this.calculateSortValue = props.calculateSortValue;
        this.customizeCellText = props.customizeCellText;
        this.cellRenderTemplate = props.cellRenderTemplate;
        this.headerCellRenderTemplate = props.headerCellRenderTemplate;
        this.editCellRenderTemplate = props.editCellRenderTemplate;
        this.groupCellRenderTemplate = props.groupCellRenderTemplate;
        this.headerFilterOptions = props.customizeHeaderFilterOptions;
        this.calculateFilterExpression = props.calculateFilterExpression;
        this.columns = props.columns;
        this.minWidth = props.minWidth;
        this.width = props.width;
        this.defaultWidth = props.minWidth || props.width || undefined;
        this.printWidth = props.printWidth;
        this.displayMode = new Set(props.displayMode ? props.displayMode : [DataGridColumnDisplayMode.UI, DataGridColumnDisplayMode.Print, DataGridColumnDisplayMode.Export]);
        this.enableCultureSpecificSorting = props.enableCultureSpecificSorting;
        this.columnType = DataGridColumnType.Data;
        this.defaultHeaderFilterOption = props.defaultHeaderFilterOption;
        this.defaultFilterValue = props.defaultFilterValue;
        this.groupIndex = props.groupIndex;
        this.name = props.name;
        this.fixed = props.fixed;
        this.fixedPosition = props.fixedPosition;
        this.buildCustomColumnName = props.onBuildCustomColumnName;
        this.validationRules = props.validationRules;
        this.cssClass = props.cssClass;
        this.visibleIndex = props.visibleIndex;
        this.lookup = props.lookup;
        this.editorOptions = props.editorOptions;
        this.trueText = props.trueText;
        this.falseText = props.falseText;
    }
}

export class DataGridDynamicColumn<T> extends DataGridColumn<T> {
    constructor(props: Omit<DataGridColumnDescriptor<T>, 'dataFieldName'>) {
        super(props as DataGridColumnDescriptor<T>);
        this.columnType = DataGridColumnType.Dynamic;
    }
}

export class DataGridBandColumn<T> extends DataGridColumn<T> {
    constructor(props: Omit<DataGridColumnDescriptor<T>, 'dataFieldName' | 'dataFieldType'>) {
        super(props as DataGridColumnDescriptor<T>);
        this.columnType = DataGridColumnType.Band;
        this.contentAlignment = typeof props.contentAlignment === 'undefined' ? DataGridColumnContentAlignment.center : props.contentAlignment;
    }
}
