import * as React from 'react';
import {
    IModalDialogContent,
    ModalButtonType,
    ModalDialogOptions,
    ModalWindow,
} from '@Components';
import { ManageDocumentsGrid } from './ManageDocumentsGrid';
import { IDocumentStore } from './IDocumentStore';
import {DocumentState} from "@Models";

export type ManageDocumentsDialogProps = {
    documentOwnerId: number;
    documentTypeName: string;
    store: IDocumentStore;
    defaultState?: DocumentState;
    onAdd?: () => void;
    onUpdate?: () => void;
    onDelete?: () => void;
    disabled?: boolean;
};

export class ManageDocumentsDialog extends React.Component<ManageDocumentsDialogProps> implements IModalDialogContent<void> {
    render() {
        return (
            <ManageDocumentsGrid
                documentOwnerId={this.props.documentOwnerId}
                defaultDocumentTypeName={this.props.documentTypeName}
                store={this.props.store}
                defaultState={this.props.defaultState}
                onAdd={this.props.onAdd}
                onUpdate={this.props.onUpdate}
                onDelete={this.props.onDelete}
                disabled={this.props.disabled}
            />
        );
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: "Document Management",
            buttons: [
                {
                    title: 'Close',
                    type: ModalButtonType.Close,
                    onClick: async () => {
                        window.close();
                    },
                }
            ],
            width: '900px',
            bodyClassName: 'create-rule-dialog',
        };
    }
}
