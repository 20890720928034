import { DataGridColumn } from "@Components";
import DxDataGrid, { Column } from "devextreme-react/data-grid";
// import { Column } from "devextreme-react/data-grid";
import React from "react";

type DataGridWProps<T> = {
	dataSource: T[];
	columns: DataGridColumn<T>[];
}

export class DataGridW<T> extends React.Component<DataGridWProps<T>> {
	render() {
		const { dataSource } = this.props;
		return (
			
			<DxDataGrid
				dataSource={dataSource}
				allowColumnReordering={true}
				showBorders={true}
			>
				{this.renderColumns()}
			</DxDataGrid>		
		);
	}

	renderColumns() {
		const columns = this.props.columns.map(col =>
			<Column
				key={String(col.dataFieldName)}
				dataField={col.dataFieldName}
				caption={col.caption}
				dataType={col.dataFieldType}
				cellRender={col.cellRenderTemplate}
				// format={col.for}
				// alignment={col.al}
			/>
		)

		return columns;
	}
}
