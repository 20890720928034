import { FieldLabels, ValidationSchema } from '@Libs/Form';

export type FormModel = {
    value: string;
};

export function initForm(value: string): FormModel {
    return {
        value: value
    }
};

export const formValidationSchema: ValidationSchema<FormModel> = {};

export const formLabels: FieldLabels<FormModel> = {
    value: 'Value'
};