import React from 'react';
import ReactDOM from 'react-dom';

// TODO: update picker and remove. Legacy for datetime picker and dxgrid toolbar
//import './../node_modules/font-awesome/css/font-awesome.css';
import './../node_modules/@fortawesome/fontawesome-free/css/all.css';
import './../node_modules/@fortawesome/fontawesome-svg-core/styles.css';

import './Scss/App.scss';
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
