import { FieldLabels, ValidationSchema } from '@Libs/Form';

export type FormModel = {
    comment: string;
};

export function initForm(defaultComment: string = ''): FormModel {
    return {
        comment: defaultComment
    }
}

export const formValidationSchema: ValidationSchema<FormModel> = {
    comment: { requiered: true }
}

export const formLabels: FieldLabels<FormModel> = {
    comment: 'Comment'
}