import { apiClient } from "@Models";
import { LookupModelEnumPicker, LookupModelEnumPickerBaseProps } from '@Components/Pickers/LookupModelEnumPicker/LookupModelEnumPicker';
import {BaseFormModel} from "@Helpers";
import {
    LookupModelEnumFormPicker,
    LookupModelEnumFormPickerProps
} from "@Components/Pickers/LookupModelEnumPicker/LookupModelEnumFormPicker";
import {useCallback} from "react";

export function ClauseLimitCategoryPicker(props: LookupModelEnumPickerBaseProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.enumClauseLimitCategoryGet.bind(apiClient), []);
    return (
        <LookupModelEnumPicker
            {...props}
            loadData={loadData}
        />
    );
}

export function ClauseLimitCategoryFormPicker<T extends BaseFormModel<T>>({...rest} : LookupModelEnumFormPickerProps<T>) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.enumClauseLimitCategoryGet.bind(apiClient), []);
    return (
        <LookupModelEnumFormPicker
            {...rest}
            loadData={loadData}
        />
    );
}