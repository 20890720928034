type SourceValueProps = {
    value?: string;
};

export function SourceValue(props: SourceValueProps) {
    return (
        <span style={{ fontSize: 13 }}>
            Source Value: {props.value ?? ' not defined' }
        </span>
    );
}