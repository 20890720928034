import { memo } from 'react';
import { Col, Row } from 'reactstrap';

import { DateTimeService } from '@Services';

import { Label } from '@Components';
import { DoCTestReportsDialogProps } from './DoCTestReportsDialog';
import { DoCTestReportsTable } from './Components/DoCTestReportsTable';


export const DoCTestReportsDialogBody = memo(({
    doCNumber, doCVersion, validFrom, canPrincipalManage, technologyCenterId, typeTestMatrixId, sourceMode, declarationOfConformityId,
    loadData, loadReferenceTestReports, onAddTestReport, onDeleteTestReport
}: DoCTestReportsDialogProps) => {

    return (
        <>
            <div className="form-wrapper mb-4">
                <Row>
                    <Col sm="2">
                        <Label
                            text="DoC Number:"
                        />
                    </Col>
                    <Col>
                        {doCNumber}
                    </Col>
                </Row>
                <Row>
                    <Col sm="2">
                        <Label
                            text="Version:"
                        />
                    </Col>
                    <Col>
                        {doCVersion}
                    </Col>
                </Row>
                <Row>
                    <Col sm="2">
                        <Label
                            text="Valid From:"
                        />
                    </Col>
                    <Col>
                        {!!validFrom && DateTimeService.toUiDate(validFrom)}
                    </Col>
                </Row>
            </div>

            <DoCTestReportsTable
                canPrincipalManage={canPrincipalManage}
                technologyCenterId={technologyCenterId}
                typeTestMatrixId={typeTestMatrixId}
                sourceMode={sourceMode}
                declarationOfConformityId={declarationOfConformityId}
                loadData={loadData}
                loadReferenceTestReports={loadReferenceTestReports}
                onAddTestReport={onAddTestReport}
                onDeleteTestReport={onDeleteTestReport}
            />
        </>
    );
});
