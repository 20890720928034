import {BaseFormModel} from "@Helpers";
import {
    LookupModelEnumPicker,
    LookupModelEnumPickerProps
} from "@Components/Pickers/LookupModelEnumPicker/LookupModelEnumPicker";
import {FormControl, FormFeedback} from "@Components";
import {Observer} from "mobx-react";

export type LookupModelEnumFormPickerProps<T extends BaseFormModel<T>> = Omit<ILookupModelEnumFormPickerProps<T>, "loadData">;
type ILookupModelEnumFormPickerProps<T extends BaseFormModel<T>> = Omit<LookupModelEnumPickerProps, "defaultValue"> & FormControl<T>;

export function LookupModelEnumFormPicker<T extends BaseFormModel<T>>({ name, formModel, ...props }: ILookupModelEnumFormPickerProps<T>) {
    return (
        <Observer>
            {() => {
                const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;
                const value = '' + formModel[name] ?? undefined;
                return(
                    <>
                        <LookupModelEnumPicker
                            {...props}
                            defaultValue={value}
                            invalid={invalid}
                        />
                        {invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
                    </>)
            }}
        </Observer>
    );
}