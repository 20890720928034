import React, { memo, ReactElement } from 'react';
import { IconType } from 'react-icons/lib';
import classNames from 'classnames';
import { ToolTipItem } from '@Components';
import styles from './IconButtonWrapper.module.scss';

export type IconButtonTooltipType = {
    id: number;
    autohide?: boolean;
    content: string | ReactElement;
}

type IconButtonWrapperProps = {
    onClick?: () => void;
    disabled?: boolean;
    IconType: IconType;
    size?: number;
    title?: string;
    className?: string;
    tooltip?: IconButtonTooltipType;
};
const DEFAULT_SIZE = 15;

function IconButtonWrapper({ onClick, disabled, IconType, size, title, className, tooltip }: IconButtonWrapperProps) {
    const iconWrapRef = React.useRef<HTMLDivElement | null>(null);
    const [ready, setReady] = React.useState(false);

    const handleOnClick = (event?: React.MouseEvent<SVGElement>) => {
        event?.stopPropagation();
        if(!disabled && onClick){
            onClick();
        }
    }

    React.useEffect(() => {
        if (iconWrapRef.current) {
            setReady(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iconWrapRef.current])

	return (
    <>
    <div ref={iconWrapRef} className={classNames(styles.iconWrap, className)}>
        <IconType
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          onClick={handleOnClick}
          size={size ? size : DEFAULT_SIZE}
          title={title}
        />
    </div>
        {
            tooltip && iconWrapRef.current && ready && (
              <ToolTipItem
                autohide={tooltip.autohide}
                text={tooltip.content}
                innerClassName="tooltip-with-buttons"
                targetId={iconWrapRef.current}
              />
            )
        }
    </>

	);
}

const Memoized = memo(IconButtonWrapper);
export { Memoized as IconButtonWrapper };
