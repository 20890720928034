import { DateTimeService } from '@Services';
import { isFloatNumber } from '@Validators';
import { RequieredFields } from '../type-declarations';

export function isRequiered(value: unknown) {
    const fieldName = ''; // TODO:
    const invalid = value === undefined || value === null || value === '' || (typeof value === 'string' && !value.trim());
    return { invalid: invalid, errorMessage: invalid ? `Field ${fieldName} cannot be empty` : '' };
}

export function isDate<T>(value: unknown, key: keyof T, _: T, requieredFields: RequieredFields<T>) {
    if (!requieredFields[key] && !value) {
        return { invalid: false,  errorMessage: ''}
    }

    if (requieredFields[key] && !value) {
        return { invalid: true,  errorMessage: 'Requiered Field' }
    }

    const invalid = !DateTimeService.isValidDate(typeof value === 'string' ? DateTimeService.parseUiDate(value as string) : value as Date);
    return { invalid: invalid, errorMessage: invalid ? `Invalid Date` : '' };
}

export function isDateAfter<T>(d1key: keyof T) {
    return (value: unknown, key: keyof T, formFields: T, requieredFields: RequieredFields<T>) => {
        const d1 = formFields[d1key] as unknown as Date | undefined;
        const isAfter = d1 ? DateTimeService.isAfterDate(value as Date, d1) : false;
        return { invalid: !isAfter, errorMessage: 'Incorrect date range'}
    }
}

export function isDateBefore<T>(d1key: keyof T) {
    return (value: unknown, key: keyof T, formFields: T, requieredFields: RequieredFields<T>) => {
        const d1 = formFields[d1key] as unknown as Date | undefined;
        const isBefore = d1 ? DateTimeService.isBeforeDate(value as Date, d1) : false;
        return { invalid: !isBefore, errorMessage: 'Incorrect date range'}
    }
}

export function hasMaxLength<T>(len: number) {
    return (value: unknown, key: keyof T, formFields: T, requieredFields: RequieredFields<T>) => {
        const isValid = ((value as string)?.length??0) <= len;
        return { invalid: !isValid, errorMessage: `String length should be less then ${len}`};
    }
}

export function isNumber(value: unknown) {
    const isValid = isFloatNumber(value);
    return { invalid: !isValid, errorMessage: `Invalid number`};
};

export function isNumberOrEmpty(value: unknown) {
    const isValid = value ? isFloatNumber(Number(value)) : true;
    return { invalid: !isValid, errorMessage: `Invalid number`};
};

export function isNumberTranslatable(value: unknown) {
    const isValid = !isNaN(Number(value));
    return { invalid: !isValid, errorMessage: `Invalid number`};
};

export function isAlphanumeric<T>() {
    return (value: unknown, key: keyof T, formFields: T, requieredFields: RequieredFields<T>) => {
        const isValid = /^[0-9A-Za-z]+$/.test(value as string);
        return { invalid: !isValid, errorMessage: "Value should be alphanumeric"};
    }
}