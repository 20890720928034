import {ImBook} from 'react-icons/im';
import {memo, useEffect, useState} from 'react';
import { apiClient } from '@Models';

function HelpLink(props: { buttonClassName?: string }) {
    const [helpLink, setHelpLink] = useState('');

    useEffect(() => {
        getLink();
    }, []);

    async function getLink() {
        const { data } = await apiClient.configurationHelpLinkGet();
        if (data) {
            setHelpLink(data)
        }
    }

    function handleClick() {
        if (helpLink) window.open(helpLink);
    }

    return (
        <div title="Help" className={props.buttonClassName} onClick={handleClick}>
            <ImBook size={14}/>
        </div>
    )
}

const Memoized = memo(HelpLink);

export {Memoized as HelpLink};