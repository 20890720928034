import { apiClient } from "@Models";
import { LookupModelPicker, LookupModelPickerBaseProps } from '@Components/Pickers/LookupModelPicker/LookupModelPicker';
import {BaseFormModel} from "@Helpers";
import {useCallback} from "react";
import {LookupModelFormPicker,LookupModelFormPickerProps} from "@Components/Pickers/LookupModelPicker/LookupModelFormPicker";

export function CurrencyPicker(props: LookupModelPickerBaseProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.currencyLookupGet.bind(apiClient), []);
    return (
        <LookupModelPicker
            {...props}
            loadData={loadData}
        />
    );
}

export function CurrencyFormPicker<T extends BaseFormModel<T>>({...rest} : LookupModelFormPickerProps<T>) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.currencyLookupGet.bind(apiClient), []);
    return (
        <LookupModelFormPicker
            {...rest}
            loadData={loadData}
        />
    );
}