import * as React from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { DateTime } from '@AppConstants';
import { BaseFormModel } from '@Helpers';
import { DateTimeService } from '@Services';

import { Label } from 'reactstrap';
import { Input, InputProps } from './Basic/Input/Input';
import { Textarea, TextareaProps } from './Basic/Textarea/Textarea';
import { DatePicker, DatePickerProps } from './DateTimeControls/DatePicker/DatePicker';
import { TimePicker, TimePickerProps } from './DateTimeControls/TimePicker/TimePicker';
import { Checkbox, CheckboxProps } from './Basic/Checkbox/Checkbox';
import { Switcher } from './Basic/Switcher/Switcher';
import { DropdownInput, DropdownInputProps, SimpleSelect, SimpleSelectProps } from '@Components';
import { FormFeedback } from './Basic/FormFeedback/FormFeedback';
import { NumberInput, NumberInputProps } from './Basic/NumberInput/NumberInput';

type OmitName<T> = Omit<T, 'name'>;
export type FormControl<T extends BaseFormModel<T>> = {
    formModel: T;
    name: keyof T;
};

@observer
export class FormLabel<T extends BaseFormModel<T>> extends React.PureComponent<FormControl<T>> {
	public render() {
		const { name, formModel } = this.props;
		const displayName = formModel.nameFor(name);
		const isRequired = !!formModel.validatorsFor(name).find(x => x.name === 'isRequired' || x.name === 'isRequiredWhen');
		return (
			<>
				{displayName || name}{isRequired ? <span style={{color: '#e32'}}>*</span> : ''}
			</>
		);
	}
}


export class FormErrors<T extends BaseFormModel<T>> extends React.Component<FormControl<T>> {
	public render() {
		const { name, formModel } = this.props;
		const invalid = formModel.validated && !formModel.isValid(name);

		return (
			<>
				{invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
			</>
		);
	}
}

export type IFormCheckboxProps<T extends BaseFormModel<T>> = OmitName<CheckboxProps> & FormControl<T> & {
	label?: string;
};

@observer
export class FormCheckbox<T extends BaseFormModel<T>> extends React.PureComponent<IFormCheckboxProps<T>, {}> {
    public render() {
		const { label, name, formModel, onChange } = this.props;
		const value = formModel[name] as unknown as string | boolean;
		const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;

        return (
            <>
                <Label className="checkbox">
					<Checkbox
						checked={value === 'true' || value === true}
						name={name as string}
                        disabled={this.props.disabled}
                        invalid={invalid}
                        onChange={ev => {
							const checked = ev.target.checked as unknown as T[keyof T];
							formModel[name] = checked;
							if (onChange) onChange(ev);
                        }}
					/>
                    <span style={{ verticalAlign: 'text-bottom', marginLeft: 6 }}>{label}</span>
                </Label>
				{invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
            </>
        );
    }
}

@observer
export class FormSwitcher<T extends BaseFormModel<T>> extends React.PureComponent<IFormCheckboxProps<T>> {
    public render() {
        const { name,  formModel } = this.props;
		const value = formModel[name] as unknown as string | boolean;
		const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;
        return (
            <>
                <Switcher
					checked={value === 'true' || value === true}
					name={name as string}
					invalid={invalid}
					disabled={this.props.disabled}
					onChange={ev => {
						const checked = ev.target.checked as unknown as T[keyof T];
						formModel[name] = checked;
					}}
				/>
				{invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
            </>
        );
    }
}


export type IFormSimpleSelectProps<T extends BaseFormModel<T>> = OmitName<SimpleSelectProps> & FormControl<T>;

@observer
export class FormSimpleSelect<T extends BaseFormModel<T>> extends React.PureComponent<IFormSimpleSelectProps<T>> {
    public render() {
        const { formModel, name, onChange, ...rest } = this.props;
		const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;

        return (
            <>
				<SimpleSelect
					name={name as string}
					value={formModel[name] as unknown as string | undefined}
					invalid={invalid}
					onChange={ev => {
						formModel[name] = ev.target.value as unknown as T[keyof T];
						if (onChange) onChange(ev);
					}}
					{...rest}
				/>
                {invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
            </>
        );
    }
}

export type IFormInputNewProps<T extends BaseFormModel<T>> = OmitName<InputProps> & FormControl<T>;
@observer
export class FormInput<T extends BaseFormModel<T>> extends React.PureComponent<IFormInputNewProps<T>> {
	public render() {
		const { formModel, name, onChange, required,  ...rest} = this.props;
		const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;
		// const invalid = !formModel.isValid(name);

		return (
			<>
				<Input
					name={name as string}
					value={formModel[name]  as unknown as string | undefined || ''}
					invalid={invalid}
					onChange={e => {
						formModel.setValue(name, e.target.value);
						if (onChange) onChange(e);
					}}
					required={required}
					{...rest}
				/>
				{invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
			</>
		);
	}
}

export type IFormNumberInputProps<T extends BaseFormModel<T>> = OmitName<Omit<NumberInputProps, 'onChange'>> & FormControl<T>;
@observer
export class FormNumberInput<T extends BaseFormModel<T>> extends React.PureComponent<IFormNumberInputProps<T>> {
	public render() {
		const { formModel, name, required,  ...rest} = this.props;
		const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;

		return (
			<>
				<NumberInput
					name={name as string}
					value={formModel[name] as unknown as string | undefined}
					invalid={invalid}
					onChange={value => formModel.setValue(name, value)}
					required={required}
					{...rest}
				/>
				{invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
			</>
		);
	}
}

export type IFormDropdownInputProps<T extends BaseFormModel<T>> = OmitName<DropdownInputProps> & FormControl<T>;
@observer
export class FormDropdownInput<T extends BaseFormModel<T>> extends React.PureComponent<IFormDropdownInputProps<T>> {
	public render() {
		const { formModel, name, onChange, onOptionSelect, ...rest } = this.props;
		const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;

		return (
			<>
				<DropdownInput
					name={name as string}
					value={formModel[name] as unknown as string | undefined || ''}
					invalid={invalid}
					onChange={e => {
						formModel.setValue(name, e.target.value);
						if (onChange) onChange(e);
					}}
					onOptionSelect={option => {
						formModel.setValue(name, option);
						if (onOptionSelect) onOptionSelect(option);
					}}
					{...rest}
				/>
				{invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
			</>
		);
	}
}

export type IFormTextareaProps<T extends BaseFormModel<T>> = OmitName<TextareaProps> & FormControl<T>;
@observer
export class FormTextarea<T extends BaseFormModel<T>> extends React.PureComponent<IFormTextareaProps<T>> {
	public render() {
		const { formModel, name, onChange,  ...rest} = this.props;
		const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;

		return (
			<>
				<Textarea
					name={name as string}
					value={formModel[name] as unknown as string | undefined}
					invalid={invalid}
					onChange={e => {
						formModel.setValue(name, e.target.value);
						if (onChange) onChange(e);
                        formModel.validate();
					}}
					{...rest}
				/>
				{invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
			</>
		);
	}
}


export type IFormDatePickerProps<T extends BaseFormModel<T>> = Omit<DatePickerProps, 'value'> & FormControl<T>;
@observer
export class FormDatePicker<T extends BaseFormModel<T>> extends React.PureComponent<IFormDatePickerProps<T>> {
	constructor(props: IFormDatePickerProps<T>) {
		super(props);
		this._handleCgange = this._handleCgange.bind(this);
		this._handleClear = this._handleClear.bind(this);
	}

	public render() {
		const { formModel, name, onChange, onClear, ...rest} = this.props;
		const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;
		const err = formModel.errorFor(name);

		return (
			<>
				<DatePicker
					value={formModel[name] ? DateTimeService.parse(formModel[name] as unknown as string, DateTime.viewDateFormat, false) : undefined}
					invalid={invalid}
					onChange={this._handleCgange}
					onClear={this._handleClear}
					{...rest}
				/>
				{invalid &&
				<FormFeedback>
					{err.map((error: string) => <div key={(name as string) + error}>{error}</div>)}
				</FormFeedback>}
			</>
		);
	}

    private _handleCgange(date: Date, value: string) {
        const { pickerMode, formModel, name, onChange } = this.props;

		runInAction(() => {
			const formValue = (value && DateTimeService.isValidDate(date)) ? DateTimeService.toCustomUiFormat(date, this._getPickerFormat(pickerMode)) : value;
			formModel.setValue(name, formValue);
			formModel.validate();
		});

        if (onChange && (formModel.isValid(name) || DateTimeService.isValidDate(date))) {
            onChange(date, value);
        }
    }

	private _handleClear() {
        const { formModel, name } = this.props;

		runInAction(() => {
			formModel.setValue(name, '');
			formModel.validate();
		});
    }

	private _getPickerFormat(mode?: string): string {
		if (mode === 'datetime') {
			return DateTime.viewFullFormat;
		}

		if (mode === 'time') {
			return DateTime.timeFormat;
		}

		return DateTime.viewDateFormat;
	}
}

export type IFormTimePickerProps<T extends BaseFormModel<T>> = Omit<TimePickerProps, 'value'> & FormControl<T>;
@observer
export class FormTimePicker<T extends BaseFormModel<T>> extends React.PureComponent<IFormTimePickerProps<T>> {
	constructor(props: IFormTimePickerProps<T>) {
		super(props);
		this._handleCgange = this._handleCgange.bind(this);
		this._handleClick = this._handleClick.bind(this);
	}

	public render() {
		const { formModel, name, onChange,  ...rest} = this.props;
		const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;
		const err = formModel.errorFor(name);

		return (
			<>
				<TimePicker
					value={formModel[name] as unknown as string }
					invalid={invalid}
					onChange={this._handleCgange}
					onControlClick={this._handleClick}
					{...rest}
				/>
				{invalid &&
				<FormFeedback>
					{err.map((error: string) => <div key={(name as string) + error}>{error}</div>)}
				</FormFeedback>}
			</>
		);
	}

    private _handleCgange(dateTime: Date, value: string) {
		const { formModel, name, onChange } = this.props;
		const formValue = value ? DateTimeService.toUiTime(dateTime) : value;

		runInAction(() => {
			formModel.setValue(name, formValue);
			formModel.validate();
		});

		if (onChange && formModel.isValid(name)) {
			onChange(dateTime, value);
		}
    }

	private _handleClick(time: string, minutes: number) {
		const { formModel, name, onChange } = this.props;

        let newTimeValue = DateTimeService.parseUiTime(DateTimeService.today(), time);
        const newTime =  DateTimeService.isValidDate(newTimeValue) ? DateTimeService.addTime(newTimeValue, 0 , minutes) : '';
		const uiTime = newTime ? DateTimeService.toUiTime(newTime) : '';

		runInAction(() => formModel.setValue(name, uiTime));

        if (onChange && formModel.isValid(name)) {
            onChange(newTimeValue, uiTime);
        }
	}
}
