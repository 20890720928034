import { useEffect, useState } from 'react';
import { AxiosPromise } from 'axios';
import { LookupModel } from '@Models';
import { Select } from '@Components';
import React from 'react';

export type LookupModelPickerNextBaseProps = {
    disabled?: boolean;
    defaultValue?: number;
    isClearable?: boolean;
    className?: string;
    clearValueOnReload?: boolean;
    onChange?: (selectedOption: LookupModel | null) => void;
};

export type LookupModelPickerNextProps = {
    loadData?: () => AxiosPromise<LookupModel[]>;
} & LookupModelPickerNextBaseProps;

export const LookupModelPickerNext = React.memo((props: LookupModelPickerNextProps) => {
    const { disabled, defaultValue, isClearable, className, clearValueOnReload, onChange, loadData } = props;
    const [selectedOption, setSelectedOption] = useState<LookupModel | null | undefined>(undefined);
    const [options, setOptions] = useState<LookupModel[]>([]);

    const _handleChange = (value: LookupModel | null) => {
        setSelectedOption(value);
        onChange?.(value);

    };

    useEffect(() => {
        if (!loadData) return;
        loadData().then(({ data }) => { setOptions(data); }); 
    }, [loadData]);
    
    useEffect(() => {
        if (selectedOption !== undefined) return;
        const option = options.find(x => x.value === defaultValue);
        if (option) _handleChange(option);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, defaultValue, selectedOption, clearValueOnReload]);

    useEffect(() => {
        if (!selectedOption) return;
        if (clearValueOnReload) {
            _handleChange(null);
        } else {
            const checkedInNewOptions = options.find(x => x.value === selectedOption.value) || null;
           _handleChange(checkedInNewOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, selectedOption, clearValueOnReload]);

    return (
        <Select<LookupModel>
            isDisabled={disabled}
            value={selectedOption}
            options={options}
            onChange={_handleChange}
            isClearable={isClearable}
            classNamePrefix={className}
        />
    );
});