import * as React from 'react';
import {
    ButtonColor,
    IModalDialogContent,
    ModalButtonOptions,
    ModalButtonType,
    ModalDialogOptions,
    ModalWindow
} from '@Components';
import styles from './ConfirmationDialog.module.scss';

type ConfirmationDialogProps = {
    title?: string;
    message?: string | string[] | JSX.Element;
    color?: ButtonColor;
    isShowNoButton?: boolean;
    confirmButton?: ModalButtonType;
};

export class ConfirmationDialog extends React.PureComponent<ConfirmationDialogProps, {}> implements IModalDialogContent<void> {

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        const {title, color, isShowNoButton, confirmButton} = this.props;
        let buttons = [
            {
                type: ModalButtonType.Cancel,
                onClick: () => {
                    window.close();
                }
            } as ModalButtonOptions<void>

        ];

        if(isShowNoButton){
            buttons = [...buttons, {
                type: ModalButtonType.No,
                onClick: () => {
                    window.close(ModalButtonType.No);
                }
            }];
        }
        buttons.push(
          {
              color: color ?? 'secondary' as ButtonColor | undefined,
              type: confirmButton || ModalButtonType.Confirm,
              onClick: () => {
                  window.close(confirmButton || ModalButtonType.Confirm);
              }
          }
          );

        return {
            title: title ?? 'Confirmation',
            buttons,
            width: '640px'
        };
    }

    render() {
        const {message} = this.props;

        if (typeof message === 'string' || Array.isArray(message)) {
            const messages = typeof message === 'string' ? [message] : message;

            return (
                <>
                    {messages && messages.length > 0 &&
                    <ul className="list-unstyled white-space-pre">
                        {messages.map(m => <li className={styles.message} key={m}>{m}</li>)}
                    </ul>
                    }
                </>
            );
        }

        return message;
    }
}
