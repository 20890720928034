/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { observer } from "mobx-react";
import { action, computed, makeObservable, observable } from "mobx";
import { AxiosPromise } from 'axios';
import { apiClient } from "@Models";
import { FileUpload } from "@AppConstants";
import { IModalDialogContent, ModalDialogOptions, ModalWindow } from "@Components";
import { ProgressBarDialogBody } from "./Components/ProgressBarDialogBody";
import './progress-bar-dialog.scss';

type ProgressBarByChunksDialogProps<T> = {
    file: File;
    title?: string;
    request: (lastChunk: Blob, guid: string) => AxiosPromise<T>;
    onSuccess?: (response: T) => Promise<void> | void;
    onError?: () => void;
};

@observer
export class ProgressBarByChunksDialog<T> extends React.PureComponent<ProgressBarByChunksDialogProps<T>> implements IModalDialogContent<T> {
    @observable private _store = new ProgressBarByChunksDialogStore<T>();
    private _setDialogPayload: (payload: T) => void;
    private _closeDialog: () => void;

    constructor(props: ProgressBarByChunksDialogProps<T>) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        const { file, request, onSuccess, onError } = this.props;

        const response = await this._store.uploadFile(file, request, onSuccess, onError);
        if (response?.data) {
            this._setDialogPayload(response.data);
        }

        this._closeDialog();
    }

    @computed
    private get _message() {
        if (this._store.hasError) {
            return 'Failed to upload';
        }

        if (!this._store.isLoading && this._store.progress === 100) {
            return 'Process completed';
        }

        return undefined;
    }

    @computed
    private get _color() {
        if (this._store.hasError) {
            return 'danger';
        }

        if (!this._store.isLoading && this._store.progress === 100) {
            return 'success';
        }

        return undefined;
    }

    render() {
        return (
            <ProgressBarDialogBody
                value={this._store.progress}
                color={this._color}
                message={this._message}
                isLoading={this._store.isLoading}
            />
        );
    }
  
    public getModalOptions(window: ModalWindow<T>): ModalDialogOptions<T> {
        const {title} = this.props;
        this._setDialogPayload = (payload: T) => window.setPayload(payload);
        this._closeDialog = () => window.close();

        return {
            title: title ?? 'Uploading...',
            width: '640px',
            modalClassName: 'tms-progress-bar-dialog'
        };
    }
}

class ProgressBarByChunksDialogStore<T> {
    @observable public progress: number = 0;
    @observable public isLoading: boolean = false;
    @observable public hasError: boolean = false;

    constructor() {
        makeObservable(this);
    }

    public async uploadFile(file: File, request: (lastChunk: Blob, guid: string) => AxiosPromise<T>, onSuccess?: (response: T) => void, onError?: () => void) {
        this._setIsLoading(true);

        const guid = this._generateFileGuid();

        const chunkSize = FileUpload.TempFileChunkSizeInBytes;
        const chunksCount = file.size % chunkSize === 0
            ? file.size / chunkSize
            : Math.floor(file.size / chunkSize) + 1;

        let chunkStart = 0;
        let chunkEnd = chunkSize;

        for (let i = 1; i <= chunksCount; i++) {
            const chunkToUpload = file.slice(chunkStart, chunkEnd);
            
            try {
                await apiClient.fileUploadPost({ data: chunkToUpload, fileName: file.name }, guid, i, chunksCount);
                
                this._calculateFileProgress(i, chunksCount);

                if (i === chunksCount) {
                    const response = await request(chunkToUpload, guid);
                    this._setIsLoading(false);
                    onSuccess?.(response.data);

                    return response;
                }
            } catch(error: unknown) {
                this._setIsLoading(false);
                this._setHasError(true);
                onError?.();
            }
                
            chunkStart = chunkEnd;
            chunkEnd += chunkSize;
        }
    }

    private _generateFileGuid(): string {
        return (crypto as any).randomUUID();
    }

    @action.bound
    private _calculateFileProgress(chunkNumber: number, chunksCount: number) {
        this.progress = Math.floor(chunkNumber / chunksCount * 100);
    }

    @action.bound
    private _setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @action.bound
    private _setHasError(hasError: boolean) {
        this.hasError = hasError;
    }
}
