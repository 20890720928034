import * as React from 'react';
import {
    IModalDialogContent,
    ModalButtonOptions,
    ModalButtonType,
    ModalDialogOptions,
    ModalWindow,
} from '@Components';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { TestDocumentType } from '@Models';
import { TestDocumentFormStore } from './TestDocumentForm/TestDocumentFormStore';
import { TestDocumentForm } from './TestDocumentForm/TestDocumentForm';
import { RELOAD_TEST_RUN } from '@AppConstants';

export type TestDocumentDialogProps = {
    documentId?: number;
    documentOwnerId: number;
    documentOwnerDisplayName: string;
    labOfferRequestId?: number;
    typeTestPlanId?: number;
    typeTestMatrixId?: number;
    testReportConfigurationId?: number;
    title: string;
    allowedTestDocumentType: TestDocumentType[];
    onAdd?: () => void;
    onUpdate?: () => void;
};

@observer
export class TestDocumentDialog extends React.Component<TestDocumentDialogProps> implements IModalDialogContent<void> {
    @observable private _selectedTestDocumentType: TestDocumentType = TestDocumentType.FullReport;
    @observable private _showReportTypeSelector: boolean = false;
    store: TestDocumentFormStore;

    constructor(props: TestDocumentDialogProps) {
        super(props);
        makeObservable(this);

        this._showReportTypeSelector = props.allowedTestDocumentType.length > 1;

        if (props.allowedTestDocumentType.length === 1) {
            
            this._selectedTestDocumentType = props.allowedTestDocumentType[0];
        }

        this.store = new TestDocumentFormStore(this.props.documentOwnerId, this._selectedTestDocumentType, this.props.documentId, this.props.labOfferRequestId, this.props.typeTestPlanId, this.props.typeTestMatrixId, this.props.testReportConfigurationId);
    }

    private onDocumentTypeChangedHandler = (value: TestDocumentType) => {
        this.store.documentType = value;
    };
   
    @action.bound
    private getDialogTitle = () => {
        return this.props.allowedTestDocumentType.length === 1
            ? `Upload Document (Test Document [${this._selectedTestDocumentType}])`
            : `Upload Document`;
    };

    render() {
        return (
            <>
                <div style={{ fontSize: 16, fontWeight: 600, marginBottom: 20 }}>
                    {this.props.documentOwnerDisplayName}
                </div>
                <TestDocumentForm
                    form={this.store.form}
                    onDocumentTypeChanged={this.onDocumentTypeChangedHandler}
                    showDocumentTypeSelector={this._showReportTypeSelector}
                    allowedTestDocumentTypes={this.props.allowedTestDocumentType}
                />
            </>
        );
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        const buttons: ModalButtonOptions<void>[] = [];

        buttons.push(
            {
                title: 'Close',
                type: ModalButtonType.Close,
                onClick: async () => {
                    window.close();
                },
            },
            {
                title: !this.props.documentId ? 'Create New' : 'Save',
                type: ModalButtonType.Save,
                onClick: async () => {
                    const result = !this.props.documentId ? await this.store.handleCreateClick() : await this.store.handleUpdateClick();
                    if (result) {
                        document.dispatchEvent(new Event(RELOAD_TEST_RUN));
                        !this.props.documentId ? this.props.onAdd?.() : this.props.onUpdate?.();
                        window.close();
                    }
                },
            }
        )

        return {
            title: this.getDialogTitle(),
            buttons: buttons,
            width: '600px',
            bodyClassName: 'create-rule-dialog',
        };
    }
}