import pako from 'pako';

export const getEncodedToken = (token: string) => {
    const tokenParts = token.split('.');

    const dec0 = window.atob(tokenParts[0]);
    const def0 = pako.deflateRaw(dec0, { level: 9,to: 'string' });
    const enc0 = window.btoa(def0);
    
    const dec1 = window.atob(tokenParts[1]);
    const def1 = pako.deflateRaw(dec1, { level: 9, to: 'string' });
    const enc1 = window.btoa(def1);

    const encodedToken = enc0 + '.' + enc1 + '.' + tokenParts[2];
    return encodedToken;
};