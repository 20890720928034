import React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid from 'devextreme-react/data-grid';

import { CustomStoreOptions, DxDataGrid, Loader, OnRowDoubleClickArgs, IDxDataGridProps } from '@Components';

import { ApiService } from '@Services';
import { AxiosPromise } from 'axios';

export type DxGridReload = () => Promise<void>;

export type RestrictedDxDatagridProps<T> = Omit<IDxDataGridProps<T>, 'autoExpandAllGrouping'
    | 'dataGridRef'
    | 'defaultFilters'
    | 'highlightChanges'
    | 'repaintChangesOnly'
>;

export type DxDataGridWrapperProps<TModel> = RestrictedDxDatagridProps<TModel> & {
    dataUrl?: string;
    loadData?: () => AxiosPromise<TModel[]>;
    params?: unknown;
    dataItemKey?: string;
    sortCompareFunc?: (a: TModel, b: TModel) => number;
    userConfigGroup?: string;
};

@observer
export class DxDataGridWrapper<TModel> extends React.Component<DxDataGridWrapperProps<TModel>> {
    private _store: CustomStore;
    @observable.ref private _dataSource: DataSource;
    @observable.ref private _gridRef: React.RefObject<DataGrid<TModel, any>> = React.createRef();

    get gridRef() {
        return this._gridRef?.current;
    }

    constructor(props: DxDataGridWrapperProps<TModel>) {
        super(props);
        makeObservable(this);

        const storeOptions: CustomStoreOptions = {
            load: () => this._loadData()
        };

        if (this.props.dataItemKey) {
            storeOptions.key = this.props.dataItemKey;
            storeOptions.byKey = (key) => this._dataSource.items().find(x => x[this.props.dataItemKey as keyof TModel] === key);
        }

        this._store = new CustomStore(storeOptions as unknown as any);

        this._dataSource = new DataSource({
            store: this._store,
            reshapeOnPush: true,
            pageSize: 100
        });
    }

    handleRowDblClick = (args: OnRowDoubleClickArgs<TModel>) => {
        const { groupIndex } = args;
        if (groupIndex !== undefined) return;
        this.props.onRowDoubleClick?.(args);
    }

    render() {
        return (
            <React.Suspense fallback={<Loader isSuspense />}>
                <DxDataGrid<TModel>
                    {...this.props}
                    dataGridRef={this._gridRef}
                    dataSource={(this._dataSource) ?? undefined}
                    repaintChangesOnly
                    highlightChanges
                    onRowDoubleClick={this.handleRowDblClick}
                    userConfigGroup={this.props.userConfigGroup}
                />
            </React.Suspense>
        );
    }

    public addAll(items: TModel[]) {
        this._store.push(items.map(i => ({ type: 'insert', data: i })));
    }

    public async updateFields(sourceItems: TModel[], fields?: string[]) {
        const itemKeys: string[] = [];

        for (const sourceItem of sourceItems) {
            const key = (sourceItem as any)[this.props.dataItemKey || 'id'];
            itemKeys.push(key);

            const item = await this._store.byKey(key);
            if (item) {
                if (fields) {
                    fields.forEach(f => {
                        item[f] = sourceItem[f as keyof TModel];
                    });
                }
                else {
                    Object.keys(sourceItem as any).forEach(k => {
                        item[k] = sourceItem[k as keyof TModel];
                    });
                }
            }
        }

        const rowIndexes = itemKeys.map(x => {
            return this.gridRef?.instance.getRowIndexByKey(x) || 0;
        });

        if (rowIndexes) {
            this.gridRef?.instance.repaintRows(rowIndexes);
        }
    }

    public updateItem(key: string) {
        const rowIndex = this.gridRef?.instance.getRowIndexByKey(key) || 0;
        if (rowIndex) {
            this.gridRef?.instance.repaintRows([rowIndex]);
        }
    }
    
    public updateAll(items: TModel[]) {
        this._store.push(items.map(i => ({ type: 'update', key: (i as any)[this.props.dataItemKey || 'id'], data: i })));
        const rowIndexes = items.map(x => {
            const key = (x as any)[this.props.dataItemKey || 'id'];
            return this.gridRef?.instance.getRowIndexByKey(key) || 0;
        });

        if (rowIndexes) {
            this.gridRef?.instance.repaintRows(rowIndexes);
        }
    }

    public deleteAll(items: string[]) {
        this._store.push(items.map(key => ({ type: 'remove', key })));
    }

    public getItems(): TModel[] {
        return this._dataSource.items();
    }

    public  reload = async () => {
        await this._dataSource.reload();
    }

    private _loadData = async () => {
        const {loadData, dataUrl, params, sortCompareFunc} = this.props;
        if (loadData) {
            try {
                const response = await loadData();
                let data = ApiService.typeCheck(response.data);
                if(sortCompareFunc){
                    data = data.sort(sortCompareFunc);
                }
                return data;
            } catch (e) {
                console.error(e)
                throw new Error('Data Loading Error');
            }

        } else if (dataUrl) {
            return ApiService.getTypedData<TModel[]>(dataUrl, params/*, { completion: loaderStore.globalLoader }*/).then(response => {
                let data = ApiService.typeCheck(response.data);
                if(sortCompareFunc){
                    data = data.sort(sortCompareFunc);
                }
                return data;
            }).catch(() => { ;throw new Error('Data Loading Error'); });
        }
    }
}