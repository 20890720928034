import { memo } from 'react';
import { Button } from 'devextreme-react/button';

type AddButtonProps = {
	text: string;
	hint?: string;
	disabled?: boolean;
	hidden?: boolean;
	className?: string;
	onClick: () => void;
};

function AddButton({ onClick, disabled, hint, text, hidden, className }: AddButtonProps) {
	return (
		<Button
			icon="plus"
			text={text}
			hint={hint}
			disabled={disabled}
			visible={!hidden}
			className={className}
			onClick={onClick}
		/>
	);
}

const Memoized = memo(AddButton);
export { Memoized as AddButton };
