import {BaseFormModel} from "@Helpers";
import {
    LookupModelPicker,
    LookupModelPickerProps
} from "@Components/Pickers/LookupModelPicker/LookupModelPicker";
import {FormControl, FormFeedback} from "@Components";
import {Observer} from "mobx-react";

export type LookupModelFormPickerProps<T extends BaseFormModel<T>> = Omit<ILookupModelFormPickerProps<T>, "loadData">;
type ILookupModelFormPickerProps<T extends BaseFormModel<T>> = Omit<LookupModelPickerProps, "defaultValue"> & FormControl<T>;
export function LookupModelFormPicker<T extends BaseFormModel<T>>({name, formModel, ...rest} : ILookupModelFormPickerProps<T>) {
    return (
        <Observer>
            {() => {
                const invalid = formModel.validated ? !formModel.isValid(name) : formModel.validated;
                const parsedValue = Number(formModel[name]);
                const value = formModel[name] !== null ? parsedValue ? parsedValue : undefined : null;
                return(
                    <>
                        <LookupModelPicker
                            {...rest}
                            defaultValue={value}
                            invalid={invalid}
                        />
                        {invalid && <FormFeedback>{formModel.errorFor(name)}</FormFeedback>}
                    </>)
            }}
        </Observer>
    );
}