import React, { ReactElement } from 'react';
import {
    AiFillDelete,
    AiFillEdit,
    AiOutlineApartment,
    AiOutlinePlusCircle,
    AiOutlineEye,
    AiOutlineEyeInvisible,
    AiOutlineFile,
    AiOutlineLink,
    AiOutlineInfoCircle,
    AiOutlineEnter,
    AiOutlineDownload,
    AiOutlineFilePdf,
    AiTwotoneFile,
    AiOutlineMinusCircle,
    AiOutlineAppstoreAdd
} from 'react-icons/ai';
import {
    FiRotateCw,
    FiArchive
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { MdContentCopy } from 'react-icons/md';
import { ImLab, ImCalendar, ImCog } from 'react-icons/im';
import { Button } from 'reactstrap';
import { ToolTipItem } from '@Components/ToastNotification/ToolTipItem';
import styles from './ActionCell.module.scss';
import { IoDocumentsOutline } from 'react-icons/io5';
import { GrRotateLeft } from 'react-icons/gr';
import { FaCheck } from 'react-icons/fa';

type ActionButtonName = 'edit'
    | 'delete'
    | 'copy'
    | 'add'
    | 'remove'
    | 'parent'
    | 'eye'
    | 'eyeInvisible'
    | 'referenced'
    | 'info'
    | 'inside'
    | 'document'
    | 'documents'
    | 'uploadedDocument'
    | 'download'
    | 'downloadPdf'
    | 'lab'
    | 'calendar'
    | 'cog'
    | 'rotate'
    | 'archive'
    | 'addConfig'
    | 'check'
    | 'undo';

export enum ActionCellType {
    icon = 'icon',
    button = 'button'
}

type TooltipType = {
    id: number;
    autohide?: boolean;
    content: string | ReactElement;
}

export type ActionButton = {
    type?: string;
    name?: ActionButtonName;
    size?: number | string;
    visible?: boolean;
    disabled?: boolean;
    title?: string;
    hint?: string;
    color?: string;
    tooltip?: TooltipType;
    onClick?: () => void;
    className?: string;
};

type ActonsCellProps = {
    buttons: ActionButton[];
};

const DEFAULT_SIZE = 15;

export function ActionCell(props: ActonsCellProps) {
    const { buttons } = props;
    const columns = buttons.length;
    const buttonWrapRef = React.useRef<HTMLDivElement | null>(null);
    const [ready, setReady] = React.useState(false);

    function renderIcon(button: ActionButton) {
        const { name, title, size, onClick, disabled } = button;
        let Icon: IconType | null = null;

        if (name === 'edit') Icon = AiFillEdit;
        if (name === 'delete') Icon = AiFillDelete;
        if (name === 'copy') Icon = MdContentCopy;
        if (name === 'add') Icon = AiOutlinePlusCircle;
        if (name === 'remove') Icon = AiOutlineMinusCircle;
        if (name === 'parent') Icon = AiOutlineApartment;
        if (name === 'eye') Icon = AiOutlineEye;
        if (name === 'eyeInvisible') Icon = AiOutlineEyeInvisible;
        if (name === 'referenced') Icon = AiOutlineLink;
        if (name === 'info') Icon = AiOutlineInfoCircle;
        if (name === 'inside') Icon = AiOutlineEnter;
        if (name === 'document') Icon = AiOutlineFile;
        if (name === 'documents') Icon = IoDocumentsOutline;
        if (name === 'uploadedDocument') Icon = AiTwotoneFile;
        if (name === 'download') Icon = AiOutlineDownload;
        if (name === 'downloadPdf') Icon = AiOutlineFilePdf;
        if (name === 'lab') Icon = ImLab;
        if (name === 'calendar') Icon = ImCalendar;
        if (name === 'cog') Icon = ImCog;
        if (name === 'rotate') Icon = FiRotateCw;
        if (name === 'archive') Icon = FiArchive;
        if (name === 'addConfig') Icon = AiOutlineAppstoreAdd;
        if (name === 'check') Icon = FaCheck;
        if (name === 'undo') Icon = GrRotateLeft;

        if (!Icon) return null;
        if (disabled) {
            return <Icon size={size || DEFAULT_SIZE} className={styles.disabled} title={title} />
        }
        else {
            return <Icon onClick={onClick} size={size || DEFAULT_SIZE} style={{ cursor: 'pointer', color: name === 'info' ? 'red' : '' }} title={title} />
        }
    }

    function getTitle(type: string) {
        switch (type) {
            case 'primary':
                return 'Apply';
            case 'success':
                return 'Success';
            case 'danger':
                return 'Deny';
            default:
                return 'Apply';
        }
    }


    function renderButton(button: ActionButton) {
        const { type = ActionCellType.icon, color = 'primary', size = 'sm', onClick, ...props } = button;
        if (type === ActionCellType.button) {
            return (
                <Button {...props} color={color} size={size as string} onClick={onClick} title={button?.hint || button?.title}>
                    {button?.title || getTitle(color)}
                </Button>
            )
        }
        return renderIcon(button);
    }
    const isButtonExist = buttons.some(btn => btn.type === ActionCellType.button);

    React.useEffect(() => {
        if (buttonWrapRef.current) {
            setReady(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buttonWrapRef.current])

    return (
        <div className={styles.wrap} style={{ gridTemplateColumns: !isButtonExist ? `repeat(${columns}, 25px)` : undefined }}>
            {buttons.map((button, i) => {
                const { tooltip, visible } = button;
                return (
                    <React.Fragment key={i}>
                        <span ref={buttonWrapRef} style={{ gridColumn: `${i + 1} / ${i + 2}` }}>
                            {(visible === undefined || visible === true) ? renderButton(button) : null}
                        </span>
                        {
                            tooltip && buttonWrapRef.current && ready && <ToolTipItem autohide={tooltip.autohide} text={tooltip.content} innerClassName="tooltip-with-buttons" targetId={buttonWrapRef.current} />
                        }
                    </React.Fragment>
                )
            }
            )}
        </div>
    );
}
