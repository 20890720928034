import React from 'react';
import styles from './Logo.module.scss';
import {devInfoStore} from '@GlobalStores';

export class Logo extends React.PureComponent {
    render() {
        const title = 'TMS';
        const environment = this._getEnvironment();

        return (
            <div className={styles.container}>
                <div className={styles.logo}>
                    <img src={"/images/logo.png"} alt=""/>
                    <span className={styles.env}>
                        {environment}
                    </span>
                </div>
                <div className={styles.block}>
                    {title}
                </div>
            </div>
        );
    }

    private _getEnvironment() {
        let environment = '';
        if (devInfoStore.isStaging) environment = ' Stage';
        if (devInfoStore.isDemo) environment = ' Demo';
        if (devInfoStore.isDevelopment) environment = ' Development';
        if (devInfoStore.isCICD) environment = ' CICD';
        return environment;
    }
}
