import React from 'react';
import { NavTab } from "@Components";
import { NavTabs } from '../NavTabs';
import styles from './nav-tabs-layout.module.scss';

type NavLayoutProps = {
    tabs: NavTab[];
    activeTab?: NavTab;
    actions?: () => JSX.Element;
};

export const NavTabsLayout: React.FC<NavLayoutProps> = ({ children, ...navTabsProps }) => {
    return (
        <>
            <NavTabs {...navTabsProps}/>
            <div className={styles.container}>
                {children}
            </div>
        </>
    )
};