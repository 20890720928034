import * as React from 'react';
import { IModalDialogContent, ModalDialogOptions, ModalWindow } from '@Components';
import { ImpersonationDialogBody } from './ImpersonationDialogBody';

export type ImpersonationDialogProps = {
    canImpersonateAsAdmin: boolean;
};

export class ImpersonationDialog extends React.Component<ImpersonationDialogProps, {}> implements IModalDialogContent<void> {
    render() {
        return <ImpersonationDialogBody canImpersonateAsAdmin={this.props.canImpersonateAsAdmin} />;
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: 'Impersonation',
            width: '500px'
        };
    }
}
