import * as React from 'react';
import { observer } from 'mobx-react';

import {
    FaEye,
    FaEyeSlash,
    FaUserSecret
} from 'react-icons/fa';
import styles from './Impersonation.module.scss';

import { authStore, devInfoStore } from '@GlobalStores';
import { modalService } from '@Components/Modal/ModalService';
import { ImpersonationDialog } from '@Components/Dialogs/ImpersonationDialog/ImpersonationDialog';

@observer
export class Impersonation extends React.PureComponent<{ buttonClassName?: string }> {

    render() {
        if (devInfoStore.isProduction) return null;
        if (!authStore.isUserHasAdminRole) return null;
        const {buttonClassName} = this.props
        const cls = [styles.impersonate];
        buttonClassName && cls.push(buttonClassName)
        return (
            <div className={cls.join(' ')}>
                <div onClick={this._handleImpersonateClick}>
                    {authStore.impersonatedPrincipal ? <FaUserSecret size={14}/> : <FaEye/>}
                </div>
                {authStore.impersonatedPrincipal &&
                <div onClick={this._handleUnImpersonateClick}>
                    <FaEyeSlash size={14}/>
                </div>
                }
            </div>
        );
    }

    private async _handleImpersonateClick() {
        await modalService.show(ImpersonationDialog);
    }

    private _handleUnImpersonateClick() {
        localStorage.removeItem('impersonatedPrincipal');
        // this._impersonatedPrincipal = null;
        window.location.href = '/';
    }
}