import { memo } from "react";
import { getEncodedToken } from "@Helpers";
import { authStore } from "../../../Stores";

export type ThumbnailProps = {
    link?: string;
    path?: string;
    width?: number;
    height?: number;
    alt?: string;
    title?: string;
    onClick?: () => void;
};

export const Thumbnail = memo(({ link, path, width, height, alt, title, onClick }: ThumbnailProps) => {
    const encodedToken = getEncodedToken(authStore.currentToken);

    let src = link;
    if (link) {
        const isBlob = link.indexOf('blob:') > -1;
        if (!isBlob) {
            const hasParams = link.indexOf('?') > -1;
            src = hasParams ? `${link}&` : `${link}?`;
            src = src + `token=${encodeURIComponent(encodedToken)}`;
            src += `&path=${path}`;
        }
    }

    return (
        <>
            {src &&
                <img style={{ cursor: "pointer" }} src={src} width={width ?? 100} height={height ?? 100} onClick={onClick} alt={alt} title={title} />
            }
            {!src &&
                <img style={{ cursor: "pointer" }} src={'/nopic.png'} width={width ?? 100} height={height ?? 100} onClick={onClick} alt={`no ${alt}`} title={title} />
            }
        </>
    );
});
