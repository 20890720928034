import { PureComponent } from 'react';

import {
    IModalDialogContent,
    ModalDialogOptions,
    ModalWindow,
} from '@Components';
import { IDocumentStore } from '../../IDocumentStore';
import { AddDocumentDialogBody } from './AddDocumentDialogBody';
import { DocumentState } from "@Models";


export type AddDocumentDialogProps = {
    documentOwnerId: number;
    documentTypeName: string;
    store: IDocumentStore;
    defaultState?: DocumentState;
    onAdd?: () => void;
};


export class AddDocumentDialog extends PureComponent<AddDocumentDialogProps> implements IModalDialogContent<void> {
    render() {
        return (
            <AddDocumentDialogBody
                documentOwnerId={this.props.documentOwnerId}
                documentTypeName={this.props.documentTypeName}
                store={this.props.store}
                defaultState={this.props.defaultState}
                onAdd={this.props.onAdd}
            />
        );
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: "Manage Documents",
            width: '600px',
            bodyClassName: 'create-rule-dialog',
        };
    }
}
