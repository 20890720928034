import {
    ClauseRelationValidationResult, DocumentState, LabOfferRequestState, ProjectStatus, TestRunStatus, TestType, TestDocumentType, ReasonForFailure,
    ReasonForAddingVariant, TestReportCategory, TestObjectDocumentType, FinancialEntryType, TypeTestPlanApprovalState, TypeTestMatrixVariantState, SProductFamilyParameterType
} from "@Models";

export type EnumDictionary<T extends string | symbol | number, U> = {
	// eslint-disable-next-line no-unused-vars
	[K in T]: U;

};
export class EnumHelper {
    public TestTypeLabels: EnumDictionary<TestType, string> = {
        [TestType.NotApplicable]: 'Not Applicable',
        [TestType.PerformTest]: 'Perform Test',
        [TestType.ReferencedTest]: 'Referenced Test / Referenced Test Report',
        [TestType.ToBeDefined]: 'To Be Defined',
        [TestType.TTMPerformedTest]: 'Performed Test',
        [TestType.TTMMissing]: 'Missing'
    };

    public TestRunStatusLabels: EnumDictionary<TestRunStatus, string> = {
        [TestRunStatus.Failed]: 'Failed',
        [TestRunStatus.NotPerformed]: 'Not Performed',
        [TestRunStatus.Success]: 'Success',
    };

    public ProjectStatusLabels: EnumDictionary<ProjectStatus, string> = {
        [ProjectStatus.Archived]: 'Archived',
        [ProjectStatus.Deleted]: 'Deleted',
        [ProjectStatus.InApproval]: 'In Approval',
        [ProjectStatus.Approved]: 'Approved',
        [ProjectStatus.InSetup]: 'In Setup',
        [ProjectStatus.OnHold]: 'On Hold',
        [ProjectStatus.Terminated]: 'Terminated'
    };

    public LabOfferRequestStateLabels: EnumDictionary<LabOfferRequestState, string> = {
        [LabOfferRequestState.Draft]: 'Draft',
        [LabOfferRequestState.OfferAccepted]: 'Accepted',
        [LabOfferRequestState.OfferRejected]: 'Rejected',
        [LabOfferRequestState.OfferRequested]: 'Requested',
        [LabOfferRequestState.Terminated]: 'Terminated',
        [LabOfferRequestState.TestCompleted]: 'Test Completed'
    };

    public ClauseRelationValidationResultLabels: EnumDictionary<ClauseRelationValidationResult, string> = {
        [ClauseRelationValidationResult.NoError]: '',
        [ClauseRelationValidationResult.ParentHasNoChild]: 'The Parent Clause has no child',
        [ClauseRelationValidationResult.NoReferencesClause]: 'No References Clause',
        [ClauseRelationValidationResult.NoLeafClause]: 'The References Clause does not end up with Direct Clause',
        [ClauseRelationValidationResult.CircularReference]: 'Circular Reference',
    };

    public DocumentStateLabels: EnumDictionary<DocumentState, string> = {
        [DocumentState.Draft]: 'Draft',
        [DocumentState.InApproval]: 'In Approval',
        [DocumentState.Approved]: 'Approved',
    };

    public TestDocumentTypeLabels: EnumDictionary<TestDocumentType, string> = {
        [TestDocumentType.ShortReport]: 'Short Report',
        [TestDocumentType.FullReport]: 'Full Report',
        [TestDocumentType.FailedReport]: 'Failed Report',
        [TestDocumentType.ConfirmationLetter]: 'Confirmation Letter'
    };

    public ReasonForFailureLabels: EnumDictionary<ReasonForFailure, string> = {
        [ReasonForFailure.ProductIssue]: 'Product Issue',
        [ReasonForFailure.TestObjectIssue]: 'Test Object Issue',
        [ReasonForFailure.LabIssue]: 'Lab. Issue',
        [ReasonForFailure.Other]: 'Other'
    };

    public ReasonForAddingVariantLabels: EnumDictionary<ReasonForAddingVariant, string> = {
        [ReasonForAddingVariant.NotSpecified]: 'Not Specified',
        [ReasonForAddingVariant.Maintenance]: 'Maintenance',
        [ReasonForAddingVariant.StandardEditionUpdate]: 'Product Edition Update',
        [ReasonForAddingVariant.Other]: 'Other'
    };

    public TestReportCategoryLabels: EnumDictionary<TestReportCategory, string> = {
        [TestReportCategory.Success]: 'Test Report',
        [TestReportCategory.Fault]: 'Failed Test Report',
    };

    public TestObjectDocumentTypeLabels: EnumDictionary<TestObjectDocumentType, string> = {
        [TestObjectDocumentType.PartsList]: 'Parts List',
        [TestObjectDocumentType.Diagram]: 'Diagram',
        [TestObjectDocumentType.File]: 'File',
    };

    public FinancialEntryTypeLabels: EnumDictionary<FinancialEntryType, string> = {
        [FinancialEntryType.Budget]: 'Budget',
        [FinancialEntryType.Invoice]: 'Invoice',
        [FinancialEntryType.OfferQuotation]: 'Offer/Quotation',
        [FinancialEntryType.OutstandingInvoice]: 'Outstanding Invoice',
        [FinancialEntryType.PurchaseOrder]: 'Purchase Order',
        [FinancialEntryType.PurchaseOrderAdditional]: 'Purchase Order Additional',
        [FinancialEntryType.PurchaseOrderAmendment]: 'Purchase Order Amendment',
    };

    public TypeTestPlanApprovalStateLabels: EnumDictionary<TypeTestPlanApprovalState, string> = {
        [TypeTestPlanApprovalState.InSetup]: 'In Setup',        
        [TypeTestPlanApprovalState.Draft]: 'Draft',
        [TypeTestPlanApprovalState.Prepared]: 'Draft (TTP Ready)',
        [TypeTestPlanApprovalState.InApproval]: 'In Approval',
        [TypeTestPlanApprovalState.Approved]: 'Approved',
        [TypeTestPlanApprovalState.Cancelled]: 'Cancelled'
    };

    public TypeTestMatrixVariantStateLabels: EnumDictionary<TypeTestMatrixVariantState, string> = {
        [TypeTestMatrixVariantState.Draft]: 'Draft',
        [TypeTestMatrixVariantState.InApproval]: 'In Approval',
        [TypeTestMatrixVariantState.Approved]: 'Approved'
    };

    public SProductFamilyParameterTypeLabels: EnumDictionary<SProductFamilyParameterType, string> = {
        [SProductFamilyParameterType.DesignParameter]: 'Design Parameter',
        [SProductFamilyParameterType.TechnicalRating]: 'Technical Rating'
    };
}

export const enumHelper = new EnumHelper();