import { useEffect, useState } from 'react';
import { AxiosPromise } from 'axios';
import { LookupStringValueModel } from '@Models';
import {Select} from '@Components';
import React from 'react';

export type LookupModelEnumPickerBaseProps = {
    loadDataParams?: object;
    disabled?: boolean;
    defaultValue?: string;
    isClearable?: boolean;
    onChange?: (selectedOption: LookupStringValueModel | null) => void;
    invalid?: boolean;
};

export type LookupModelEnumPickerProps = {
    loadData: (params?: object) => AxiosPromise<LookupStringValueModel[]>;
} & LookupModelEnumPickerBaseProps;

function Picker({ loadData, loadDataParams, disabled, defaultValue, isClearable, onChange, ...rest }: LookupModelEnumPickerProps) {
    const [selectedValue, setSelectedValue] = useState<LookupStringValueModel | null>(null);
    const [options, setOptions] = useState<LookupStringValueModel[]>([]);

    useEffect(() => {
        const option = options.find(x => x.value === defaultValue);
        if (option)
            setSelectedValue(option);
    }, [options, defaultValue]);
    
    useEffect(() => {
        (async function loadOptions() {
            const { data } = await loadData(loadDataParams);
            setOptions(data);
        })();
    }, [loadData, loadDataParams]);

    const _handleChange = (value: LookupStringValueModel | null) => {
        setSelectedValue(value)
        onChange?.(value);
    }

    return (
        <Select<LookupStringValueModel>
            isDisabled={disabled}
            value={selectedValue}
            options={options}
            onChange={_handleChange}
            isClearable={isClearable}
            {...rest}
        />
    );
}
const LookupModelEnumPicker = React.memo(Picker);
export { LookupModelEnumPicker };