import * as React from 'react';
import { AxiosError } from 'axios';

import {
    ModalWindow, 
    ModalDialogOptions, 
    IModalDialogContent,
    ModalButtonType
} from '@Components';

type ErrorDialogProps = {
    error?: string[] | string | AxiosError | JSX.Element;
    errorHeader? : string;
    hideErrorState ? : boolean;
};

export class ErrorDialog extends React.PureComponent<ErrorDialogProps, {}> implements IModalDialogContent<void> {

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: this.props.error ? this.props.errorHeader ? this.props.errorHeader :'Request can\'t be completed' : 'Error',
            buttons: [{
                type: ModalButtonType.Ok,
                onClick: () => {
                    window.close();
                }
            }],
            width: '800px'
        };
    }

    render() {
        return (
            <React.Fragment>
                {this._renderMessage()}
                {this._renderRequestMessage()}
            </React.Fragment>
        );
    }

    _renderMessage() {
        const { error } = this.props;
        
        if (typeof error === 'string'){
            return <p>{this._renderMessageString(error)}</p>;
        }

        if (Array.isArray(error)) {
            return (
                <React.Fragment>
                    {error.map((mess, index) => <p key={index}>{this._renderMessageString(mess)}</p>)}
                </React.Fragment>
            );
        }

        if (!React.isValidElement(error)) return null;

        return error;
    }
    
    _renderMessageString(error: string) {
        const errorData = error?.split('\n');
        return errorData.map((mess, index) => <p style={{ marginTop: 0, marginBottom: 0 }} key={index}>{mess}</p>);
    }

    _renderRequestMessage() {
        const { error, hideErrorState } = this.props;
        
        if (!error) return null;
        if (typeof error !== 'object') return null;
        if (Array.isArray(error)) return null;
        if (React.isValidElement(error)) return null;

        const axiosError = error as AxiosError;
        if (!axiosError.response){
            return null;
        }

        let errorData = axiosError.response.data as unknown;

        if (typeof errorData === 'string'){
            errorData = errorData?.split('\r\n');
        }
        else if (errorData !== null && typeof errorData === 'object' && 'error' in errorData) {
            errorData = (errorData as {error: string}).error.split('\r\n');
        }

        return (
            <>
                {errorData && 
                    <p>
                        {Array.isArray(errorData) ? errorData.map((mess, index) => <p key={index}>{this._renderMessageString(mess)}</p>) : 
                            this._renderMessageString(errorData as string)
                        }
                    </p>
                }
                {!hideErrorState &&
                    <>
                        <div><small>Code: {axiosError.response.status}</small></div>
                        <div><small>URL: {axiosError.config && axiosError.config.url}</small></div>
                    </>
                }
            </>
        );
    }
}