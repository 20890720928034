/* eslint-disable no-unused-vars */
export enum Environment {
    Development = 'Development',
    Staging = 'Staging',
    Production = 'Production',
    Demo = 'Demo',
    CICD = 'CICD',
}
/* eslint-enable no-unused-vars */

export class Logs {
    public static readonly MaxSizeInMbToDownload: number = 1;
}

export class ParameterType {
    public static readonly DateTime: string = 'DateTime';
    public static readonly Date: string = 'Date';
    public static readonly Boolean: string = 'Boolean';
    public static readonly MultiLine: string = 'Multiline';
    public static readonly SingleLine: string = 'String';
    public static readonly Integer: string = 'Integer';
    public static readonly Enum: string = 'Enum';
}

export class Status {
    public static readonly success: string = 'Success';
    public static readonly warning: string = 'Warning';
    public static readonly fail: string = 'Fail';
}

export class Member {
    public static readonly PjM: number = 1;
    public static readonly LTE: number = 2;
    public static readonly TE: number = 3;
    public static readonly SPLM: number = 13;
    public static readonly SCTM: number = 46;
}

export class Icons {
    public static readonly DefaultEditTitle: string = 'Edit';
}

export class Currency {
    public static readonly USD: number = 1117;
}

export class DocumentConfig {
    public static readonly StandardDocumentType: string = 'Other';

    public static readonly TestRunShortReportDocumentType: string = 'ShortReport';
    public static readonly TestRunFullReportDocumentType: string = 'FullReport';

    public static readonly FinancialEntryPurchaseOrderDocumentType: string = 'PurchaseOrder';

    public static readonly FinancialEntryPurchaseOrderAmendmentDocumentType: string = 'PurchaseOrderAmendment';
    public static readonly FinancialEntryPurchaseOrderAdditionalDocumentType: string = 'PurchaseOrderAdditional';
    public static readonly FinancialEntryInvoiceDocumentType: string = 'Invoice';
    public static readonly FinancialEntryOfferInitialDocumentType: string = 'OfferInitial';
    public static readonly FinancialEntryOutstandingInvoiceDocumentType: string = 'OutstandingInvoice';
    public static readonly FinancialEntryBudgetDocumentType: string = 'Budget';
    public static readonly ComponentDocumentType: string = 'Other';
}

export class ObjectType {
    public static readonly Project: string = 'PRO';
    public static readonly TypeTestPlan: string = 'TTP';
    public static readonly LabOfferRequest: string = 'LOR';
    public static readonly SProductFamilyVariantDocument: string = 'SVD';
}

export class FileUpload {
    public static readonly TempFileChunkSizeInBytes: number = 1048576 * 3;
}
