export function isNumber(value: unknown): boolean {
	return typeof value === 'number' && !Number.isNaN(value as number);
}

export function isFiniteNumber(value: unknown): boolean {
	if (!isNumber(value)) return false;
	return isFinite(value as number);
}

export function isIntegerNumber(value: unknown) {
	if (!isFiniteNumber(value)) return false;
	// return Number.isInteger(value);
	return Number.isSafeInteger(value);
}

export function isFloatNumber(value: unknown) {
	return isFiniteNumber(value);
}

export function isNumberWithFractionalPart(value: unknown) {
	if (!isFiniteNumber(value)) return false;
	return Number(value) === value && value % 1 !== 0;
}

export function isNumberFractionalPartIsLessThanOrEqual(value: unknown, digits: number, includeInteger?: boolean) {
	if (!isFiniteNumber(value)) return false;
	const isInteger = !isNumberWithFractionalPart(value);
	if (isInteger) return !!includeInteger;
	
	const stringValue = '' + value;
	return (stringValue.length - 1) - stringValue.indexOf('.') <= digits;
}

// TODO: add tests
export function hasValue(value: unknown): boolean {
	return !!value;
}

export function hasMaxLength<T>(length: number) {
    return (value: T) => {
        if (typeof value  !== 'string') return false;
        return value.length <= length;
    } ;
}
