import { NotificationHandler } from '@Components';
import { modalService } from '@Components/Modal/ModalService';

class ErrorHandleService {
    private _lastError: { message: string, time: number };

    async showError(url: string, error: any) {
        const currentTime = (new Date()).getTime();
        console.log('--error AJAX: ', error);
        if (!this._lastError || this._lastError.message !== error.message || (currentTime - this._lastError.time > 100)) {
            // TODO: BAD VARIANT: refreshed page doesnt save forbidden request
            // if (error.response.status === 403) {
            //     window.history.pushState({}, '', '/403')
            //     window.location.href = '/403'
            // }
            
            // if (error.response && error.response.status !== 403) {
            if (error.response) {
                if (error && error.response && error.response.status === 400) {

                    await modalService.showError(error, 'Validation Error', true);
                }else {
                    NotificationHandler.showError(error.message.toString());
                    if(error && error.response && error.response.status === 500){
                        await modalService.showError(error);
                    }
                }
                this._lastError = { message: error.message, time: currentTime };
            }
        }
    }
}

export const errorHandleService = new ErrorHandleService();