import { memo } from 'react';
import { AiFillEdit } from "react-icons/ai";
import { IconButtonWrapper } from "@Components/IconButtonWrapper/IconButtonWrapper";
import { Icons } from "@AppConstants";

type EditIconButtonProps = {
    onClick?: () => void;
    disabled?: boolean;
    size?: number;
    title?: string;
    className?: string;
};

export const EditIconButton = memo(({ onClick, disabled, size, className, title }: EditIconButtonProps) => {
	return (
        <IconButtonWrapper 
            disabled={disabled}
            onClick={onClick}
            IconType={AiFillEdit}
            size={size}
            className={className}
            title={title ? title : Icons.DefaultEditTitle}
        />
	);
});
