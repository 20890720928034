import { computed, makeObservable, observable, runInAction } from 'mobx';
import { NotificationModel } from '@Models';
import { apiClient } from '../ApiClient';

const DEFAULT_INTERVAL = 60000;

class NotificationsStore {
  @observable notifications: NotificationModel[] = [];
  intervalId: ReturnType<typeof setInterval>;

  constructor() {
    makeObservable(this);
  }


  private _fetchNotifications = async () => {
    const { data } = await apiClient.dashboardNotificationsGet();

    runInAction(() => {
      this.notifications = data;
    });
  }

  public getUserNotifications = () => {
    this._fetchNotifications();
    this.intervalId = setInterval(this._fetchNotifications, DEFAULT_INTERVAL);
  }


  public dismissNotification = async (id?: number) => {
    id ?
      await apiClient.dashboardDismissNotificationByIdPut({id}) :
      await apiClient.dashboardDismissNotificationsPut();
    this._fetchNotifications();
  }

  @computed
  public get notificationsCount(){
    return this.notifications.length;
  }

  public stopUserNotifications = () => {
    if(this.intervalId){
      clearInterval(this.intervalId);
    }
  }

}

export const notificationsStore = new NotificationsStore();
