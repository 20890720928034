import * as React from 'react';
import { Spinner } from './Spinner/Spinner';
import { GlobalProgressData } from "@GlobalStores";
import { Progress } from "reactstrap";

type LoaderProps = {
    isVisible?: boolean;
    progress?: GlobalProgressData | null;
    isSuspense?: boolean;
}

//TODO: Deprecate
export class Loader extends React.PureComponent<LoaderProps, {}> {

    render() {
        const cls = ['loader'];
        const {isVisible, isSuspense} = this.props;
        if (isSuspense) {
            cls.push('loader-suspense');
        }
        return (
            <div hidden={!isVisible} className={cls.join(' ')}>
                <Spinner/>
                {this._renderProgress}
            </div>
        );
    }

    private get _renderProgress() {
        const {progress} = this.props;
        if (!progress) return null;
        return (
            <div className="loader-progress">
                <h3>{progress?.title}</h3>
                <Progress
                    animated
                    max={progress?.total}
                    now={progress?.current}
                />
                <div className="loader-progress-label">
                    <span>{progress?.current} / {progress?.total}</span>
                </div>
            </div>
        )
    }

}
