import { useCallback, useEffect, useMemo, useState } from 'react';

import {
    FieldLabels,
    Sanitizer,
    TouchedField,
    ValidationErrors,
    ValidationSchema,
    Validator,
    Form,
    Fields
} from '../type-declarations';

import { getCrossFieldValidators, getFormValidators, getInitialErrors, getRequieredFields } from '../utils';

export function useForm<FormFields extends Fields<FormFields> = {}>(initFormState: () => FormFields, validationSchema: ValidationSchema<FormFields>, labels: FieldLabels<FormFields> ): Form<FormFields> {
    const [formFields, setFormFields] = useState<FormFields>(initFormState);
    const [capturedFields, setCapturedFields] = useState<FormFields>(initFormState);
    const [activeCapturing, setActiveCapturing] = useState(false);

    const [validationErrors, setValidationErrors] = useState<ValidationErrors<FormFields>>(() => getInitialErrors(formFields));

    const [touchedFields, setTouchedFields] = useState<TouchedField<FormFields>>({});
    const [capturedToucedFields, setCapturedTouchedFields] = useState<TouchedField<FormFields>>({});
    const requieredFields = getRequieredFields(validationSchema, formFields);
    // const [requieredFields] = useState(() => getRequieredFields(validationSchema));
    const [validated, setValidated] = useState(false);
    const [capturedValidated, setCapturedValidated] = useState(false);

    const validators = getFormValidators(validationSchema, requieredFields);
    // const [validators] = useState(() => getFormValidators(validationSchema, requieredFields));
    const [crossFieldValidators] = useState(() => getCrossFieldValidators(validationSchema));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { validate() }, [formFields]);

    const reinitFormFields = useCallback((fields: FormFields, forceCapturing = false) => {
        if (forceCapturing || !activeCapturing) {
            setFormFields(fields);
            setCapturedFields(fields);
        }
    }, [activeCapturing, setFormFields, setCapturedFields])

    const captureState = useCallback(() => {
        setActiveCapturing(true);
        setCapturedFields(formFields);
        setCapturedValidated(validated);
        setCapturedTouchedFields(touchedFields);
    }, [formFields, validated, touchedFields, setCapturedFields, setCapturedValidated, setCapturedTouchedFields, setActiveCapturing]);

    const restoreState = useCallback(() => {
        setActiveCapturing(false);
        setFormFields(capturedFields);
        setValidated(capturedValidated);
        setTouchedFields(capturedToucedFields);
    }, [capturedFields, capturedValidated, capturedToucedFields, setFormFields, setValidated, setTouchedFields, setActiveCapturing]);

    const handleChange = useCallback(<V extends unknown, S extends unknown>(key: keyof FormFields, value: V, sanitize?: Sanitizer<V, S>) => {
        const sanitizedValue = sanitize ? sanitize(value) : value;
        setFormFields(prevFields => ({ ...prevFields, [key]: sanitizedValue }));
        setTouchedFields(prevFields => ({...prevFields, [key]: true }));
    }, [setFormFields, setTouchedFields]);

    const validate = useCallback((force: boolean = false) => {
        const validationErrors = getInitialErrors(formFields);

        for (const key in formFields) {
            if (!validated && !force) {
                continue;
            }
            if (!touchedFields[key] && !force) continue;

            if (Object.prototype.hasOwnProperty.call(formFields, key)) {
                const value = formFields[key];
                const fieldValidators: Validator<FormFields>[] = validators[key] || [];
                let error = '';

                for (let i = 0; i < fieldValidators.length; i++) {
                    const validate = fieldValidators[i];
                    const { errorMessage, invalid } = validate(value, key, formFields, requieredFields);
                    if (invalid) {
                        error = errorMessage;
                        break;
                    }
                }

                const errors: string[] = !!error ? [error] : [];
                validationErrors[key] = errors;
            }
        }


        let allowCrossFieldValidation = true;

        for (const key in validationErrors) {
            if (Object.prototype.hasOwnProperty.call(validationErrors, key)) {
                const errors = validationErrors[key];
                if (errors.length) {
                    allowCrossFieldValidation = false;
                    break;
                }
            }
        }

        if (allowCrossFieldValidation) {
            for (const key in formFields) {
                if (!validated && !force) {
                    continue
                }
                if (!touchedFields[key] && !force) continue;

                if (Object.prototype.hasOwnProperty.call(formFields, key)) {
                    const value = formFields[key];
                    const fieldValidators: Validator<FormFields>[] = crossFieldValidators[key] || [];
                    let error = '';

                    for (let i = 0; i < fieldValidators.length; i++) {
                        const validate = fieldValidators[i];
                        const { errorMessage, invalid } = validate(value, key, formFields, requieredFields);
                        if (invalid) {
                            error = errorMessage;
                            break;
                        }
                    }

                    const errors: string[] = !!error ? [error] : [];
                    validationErrors[key] = errors;
                }
            }
        }

        setValidated(true);
        setValidationErrors(validationErrors);
        return validationErrors;
    }, [formFields, validators, validated, touchedFields, requieredFields, setValidationErrors, crossFieldValidators]);

    const getValidationErrorsFor = useCallback((key: keyof FormFields): string | null => {
        const err = validationErrors[key];
        return err.join(' ') || null;
    }, [validationErrors]);

    const invalidFields = useMemo(() => {
        const invalidFields: Partial<Record<keyof FormFields, boolean>> = {};

        for (const key in formFields) {
            if (Object.prototype.hasOwnProperty.call(formFields, key)) {
                invalidFields[key] = !!validationErrors[key].length;
                // const field = formFields[key];

            }
        }

        return invalidFields as Record<keyof FormFields, boolean>;
    }, [validationErrors, formFields]);

    const isFormValid = useMemo(() => {
        let isValid = true;

        for (const key in validationErrors) {
            if (Object.prototype.hasOwnProperty.call(validationErrors, key)) {
                const fieldErrors = validationErrors[key];
                if (fieldErrors && fieldErrors.length) {
                    isValid = false;
                    break;
                }

            }
        }
        return isValid;
    }, [validationErrors]);

    const hasChanges = useMemo(() => {
        let isDifferent = false;
        for (const key in capturedFields) {
            if(capturedFields[key] !== formFields[key]){
                isDifferent = true;
                break;
            }
        }
        return isDifferent;

    }, [capturedFields, formFields])

    return {
        formFields,
        validationErrors,
        invalidFields,
        labels,
        validators,
        crossFieldValidators,
        requieredFields,
        touchedFields,
        isFormValid,
        getValidationErrorsFor,
        handleChange,
        validate,
        setValidated,
        captureState,
        restoreState,
        setTouchedFields,
        setFormFields,
        reinitFormFields,
        setCapturedFields,
        hasChanges
    };
}
