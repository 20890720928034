import { ErrorBoundary } from '@Components/ErrorBoundary/ErrorBoundary';
import * as React from 'react';

type ReportErrorBoundaryProps = {
	bodyClassName?: string;
};

export class PageErrorBoundary extends React.Component<ReportErrorBoundaryProps> {
	render() {
		const { bodyClassName } = this.props;
		const className = 'content-body ' + (bodyClassName || '');

		return (
			<ErrorBoundary>
				<div className={className}>
					{this.props.children}
				</div>
			</ErrorBoundary>
		);
	}
}