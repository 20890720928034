import React from 'react';
import './form-feedback.scss';

export class FormFeedback extends React.PureComponent {
	render() {
        return (
            <div className="form-feedback">
                {this.props.children}
            </div>
        );
	}
}

type WithFormFeedbackProps = {
    validationErrors?: string[];
};

export const withFormFeedback = <T extends {}>(Component: React.ComponentType<T>) => {
	return class extends React.Component<T & WithFormFeedbackProps> {
		render () {
			return (
                <>
                    <Component {...this.props}/>
                    <FormFeedback>
                        {this.props.validationErrors?.join(' ')}
                    </FormFeedback>
                </>
			);
		}
	};
};