import { apiClient } from "@Models";
import { LookupModelPicker, LookupModelPickerBaseProps } from '@Components/Pickers/LookupModelPicker/LookupModelPicker';
import { LookupModelPickerNext, LookupModelPickerNextProps } from "../LookupModelPicker/LookupModelPickerNext";
import { useCallback } from "react";
import { BaseFormModel } from "@Helpers";
import { LookupModelFormPicker, LookupModelFormPickerProps } from "@Components/Pickers/LookupModelPicker/LookupModelFormPicker";

export function ProductPicker(props: LookupModelPickerBaseProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.productLookupGet.bind(apiClient), []);
    return <LookupModelPicker {...props} loadData={loadData} />;
}

export function ProductPickerNext(props: LookupModelPickerNextProps) {
    return (
        <LookupModelPickerNext
            {...props}
        />
    );
}

export function ProductFormPicker<T extends BaseFormModel<T>>({ ...rest }: LookupModelFormPickerProps<T>) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.productLookupGet.bind(apiClient), []);
    return (
        <LookupModelFormPicker
            {...rest}
            loadData={loadData}
        />
    );
}