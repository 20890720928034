import React from 'react';
import { observable, action, makeObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react';

import DataSource from 'devextreme/data/data_source';
// import { exportDataGrid } from 'devextreme/excel_exporter';
import { dxElement } from 'devextreme/core/element';
import dxDataGrid from 'devextreme/ui/data_grid';

import { Template } from 'devextreme-react/core/template';

import { DateTime, PrincipalConfig } from '@AppConstants';
import { DateTimeService } from '@Services';
import { configurationStore } from '@GlobalStores';
// import ExcelJS from 'exceljs';

import DataGrid, {
    GroupPanel,
    Paging,
    SearchPanel,
    Export,
    FilterRow,
    HeaderFilter,
    ColumnChooser,
    Scrolling,
    Sorting,
    Column,
    Selection,
    Format,
    Editing,
    Pager,
    TotalItem,
    Summary,
    RowDragging,
    MasterDetail,
    StateStoring
} from 'devextreme-react/data-grid';

import {
    DataGridColumn,
    DataGridBandColumn,
    DataGridColumnType
} from './DxDataGridColumn';
import {
    DataGridColumnFieldType,
    OnRowPreparedHandler,
    OnCustomizeExcelCellHandler,
    DataGridColumnDisplayMode,
    DataGridSelectionMode,
    OnSelectionChangedHandler,
    OnCellPreparedHandler,
    OnFocuserRowChangedHandler,
    IGridFilter,
    ColumnData,
    GridState,
    OnCellPreparedArgs,
    ExportingHandlerArgs,
    OnRowDoubleClickHandler,
    EditingOptions,
    DataGridSelectionAllMode,
    DxGridDefaults,
    DataGridScrollMode, OnFocusedRowChangingHandler, DataGridRowRenderingMode, DataGridEditingMode, OnFocusedCellChangingHandler, OnFocusedCellChangedHandler, ExportedHandlerArgs, CustomizeExcelCellArgs
} from './DxDataGridTypes';
import { DxDataGridToolbar,
    DxDataGridToolbarProps, Toolbar
} from './DxDataGridToolbar';
import { DxGridHelper } from '@Helpers';

type RowData = {};

type CellData<T> = {
    column: ColumnData;
    columnIndex: number;
    rowType: 'data' | 'header' | 'group';
    isAltRow: boolean;
    cellElement: React.ReactNode;
    data: T;
    displayValue: string | Date;
    isEditing: boolean;
    row: RowData;
    rowIndex: number;
    text: string;
    value: string | Date;
};

type DxGridSummaryOptions = {
    name?: string;
};

export type DxGridSummaryTotalItem = {
    name?: string;
    column?:string;
};

type DxGridSummaryConfig = {
    calculateCustomSummary: (options: DxGridSummaryOptions) => void;
    totalItems: DxGridSummaryTotalItem[];
};

export type IDxDataGridProps<T> = {
    allowServerSideFiltering?: boolean;
    allowedPageSizes?: number[];
    autoExpandAllGrouping?: boolean;
    columns: DataGridColumn<T>[];
    dataGridRef?: React.RefObject<DataGrid<T, any>>;
    dataSource?: DataSource | T[];
    defaultFilters?: IGridFilter[];
    disableColumnChooser?: boolean;
    disableExporting?: boolean;
    disableFilterRow?: boolean;
    disableGrouping?: boolean;
    disableHeaderFilter?: boolean;
    disableSorting?: boolean;
    disableWordWrap?: boolean;
    disableRowAlternation?: boolean;
    disableSearchPanel?: boolean;
    disableToolbar?: boolean;
    hideSaveRevertButtons?: boolean;
    hideGridBorders?: boolean;
    editingOptions?: EditingOptions<T>;
    enableHoverState?: boolean;
    enablePaging?: boolean;
    exportBaseFileName?: string;
    fillRestHeight?: boolean;
    highlightFocusedRow?: boolean;
    highlightChanges?: boolean;
    overrideHeight?: number | string;
    pageSize?: number;
    repaintChangesOnly?: boolean;
    selectionMode?: DataGridSelectionMode;
    selectionAllMode?: DataGridSelectionAllMode;
    scrollMode?: DataGridScrollMode;
    scrollBottomOnInit?: boolean;
    userConfigGroup?: string;
    summaryConfig?: DxGridSummaryConfig;
    focusedRowKey?: number;
    focusedColumnIndex?: number;
    keyExpr?: string;
    autoNavigateToFocusedRow?: boolean;
    excelFileName?: string;
    notExportingColumnNames?: string[];

    customToolBarRender?: () => JSX.Element;
    onCellPrepared?: OnCellPreparedHandler<T>;
    onCustomizeExcelCell?: OnCustomizeExcelCellHandler<T>;
    onFocusedRowChanged?: OnFocuserRowChangedHandler<T>;
    onFocusedCellChanged?: OnFocusedCellChangedHandler<T>;
    onFocusedCellChanging?: OnFocusedCellChangingHandler<T>;
    onRowDoubleClick?: OnRowDoubleClickHandler<T>;
    onRowPrepared?: OnRowPreparedHandler<T>;
    onSelectionChanged?: OnSelectionChangedHandler<T>;
    onFocusedRowChanging?: OnFocusedRowChangingHandler<T>;
    onReorder?: (e: any) => void;
    onSaving?: ((e: any) => void);
    isMasterDetailsEnabled?: boolean;
    renderMasterDetails?: (e: any) => React.ReactNode;
    columnAutoWidth?: boolean;
    rowRenderingMode?: DataGridRowRenderingMode;
    viewportRowModelPageSize?: number;
    disabled?: boolean;
    onEditorPreparing?: (e: any) => void;
    onEditingStart?: ((e: any) => void);
    onContentReady?: ((e: any) => void);
};

@observer
export class DxDataGrid<T> extends React.Component<IDxDataGridProps<T>> {
    private _gridRef: React.RefObject<DataGrid<T, any>> = React.createRef();

    @observable.ref private _gridWrapper: React.RefObject<HTMLDivElement> = React.createRef();
    @observable private _gridHeight: number;
    @observable private _customColumns: DataGridColumn<T>[] = [];
    @observable.ref private _toolbar: DxDataGridToolbar<T>;

    constructor(props: IDxDataGridProps<T>) {
        super(props);
        makeObservable(this);


        this._gridWrapper = React.createRef();
        this._customColumns = this.props.columns;
        this._setColumnNames(this._customColumns);

        const toolbarProps: DxDataGridToolbarProps<T> = {
            saveStateHandler: this._saveState,
            onCustomToolBarRender: this.props.customToolBarRender,
            customColumns: this._customColumns,
            onConfigReset: this._onConfigReset,
            onFiltersReset: this._onFiltersReset
        };
        this._toolbar = new DxDataGridToolbar<T>(toolbarProps);
        this._createToolbar = this._createToolbar.bind(this);
        this._toolbar.updateButtonsState = this._toolbar.updateButtonsState.bind(this);
        this._onCellPrepared = this._onCellPrepared.bind(this);
        this._handleExporting = this._handleExporting.bind(this);
        this._handleExported = this._handleExported.bind(this);
        this._handleCustomizeExcelCell = this._handleCustomizeExcelCell.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this._calcGridHeight);
        if (this._gridWrapper.current) {
            this._calcGridHeight();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._calcGridHeight);
    }

    componentDidUpdate(prevProps: IDxDataGridProps<T>) {
        const prevColumnsValue = JSON.stringify(prevProps.columns);
        const columnsValue = JSON.stringify(this.props.columns);

        if ((prevColumnsValue.length !== columnsValue.length) || (prevColumnsValue !== columnsValue)) {
            runInAction(() => { this._customColumns = this.props.columns; });
            this._setColumnNames(this._customColumns);

            const toolbarProps: DxDataGridToolbarProps<T> = {
                saveStateHandler: this._saveState,
                onCustomToolBarRender: this.props.customToolBarRender,
                customColumns: this._customColumns,
                onConfigReset: this._onConfigReset,
                onFiltersReset: this._onFiltersReset
            };
            this._toolbar.updateProps(toolbarProps);
        }

        if (prevProps.userConfigGroup !== this.props.userConfigGroup) {
            void this._loadState();
        }
    }

    static defaultProps = {
        scrollMode: DataGridScrollMode.virtual
    }

    render() {
        const {
            dataSource, onRowPrepared, allowServerSideFiltering, selectionMode, selectionAllMode, onSelectionChanged, onRowDoubleClick,
            disableExporting, disableGrouping, disableSorting, disableColumnChooser, customToolBarRender, disableWordWrap, excelFileName,
            disableFilterRow, disableHeaderFilter, editingOptions, enablePaging = false, pageSize, allowedPageSizes, scrollMode,
            fillRestHeight, repaintChangesOnly, highlightChanges, highlightFocusedRow, onFocusedRowChanged, onFocusedCellChanged, onFocusedCellChanging, dataGridRef, enableHoverState,
            overrideHeight, autoExpandAllGrouping, disableRowAlternation, disableSearchPanel, disableToolbar, hideGridBorders,
            isMasterDetailsEnabled, renderMasterDetails, onSaving, columnAutoWidth, focusedRowKey, focusedColumnIndex, keyExpr, autoNavigateToFocusedRow,
            onFocusedRowChanging, rowRenderingMode, disabled, onEditorPreparing, onEditingStart
        } = this.props;
        const pageSizeV = pageSize ? pageSize : DxGridDefaults.DefaultPageSize;
        const allowedPageSizesV = allowedPageSizes ? allowedPageSizes : DxGridDefaults.AllowedPageSize;
        const className = `dx-custom-data-grid`;
        const wrapperClassName = disabled ? 'dx-data-grid-wrap-disabled' : ''
        const height = overrideHeight ? overrideHeight : (fillRestHeight ? this._gridHeight : '100%');
        const columnChooserHeight = window.innerHeight * 0.7;

        return (
            <div ref={this._gridWrapper} style={{ height: height }} className={wrapperClassName}>
                <DataGrid
                    style={{ height: '100%' }}
                    className={className}
                    ref={dataGridRef || this._gridRef}
                    dataSource={dataSource}
                    width={'100%'}
                    keyExpr={keyExpr}
                    showBorders={!hideGridBorders}
                    showColumnLines={true}
                    showRowLines={true}
                    allowColumnReordering={true}
                    rowAlternationEnabled={!disableRowAlternation}
                    // ToDo: onToolbarPreparing is deprecated. Should be replaced with toolbar property.
                    onToolbarPreparing={this._createToolbar}
                    remoteOperations={allowServerSideFiltering}
                    wordWrapEnabled={!disableWordWrap}
                    onRowPrepared={onRowPrepared}
                    onCellPrepared={this._onCellPrepared}
                    onSelectionChanged={onSelectionChanged}
                    onFocusedRowChanged={onFocusedRowChanged}
                    onFocusedRowChanging={onFocusedRowChanging}
                    onFocusedCellChanged={onFocusedCellChanged}
                    onFocusedCellChanging={onFocusedCellChanging}
                    columnResizingMode={'widget'}
                    repaintChangesOnly={repaintChangesOnly}
                    highlightChanges={highlightChanges}
                    focusedRowEnabled={highlightFocusedRow}
                    hoverStateEnabled={enableHoverState}
                    grouping={{ contextMenuEnabled: true, autoExpandAll: !!autoExpandAllGrouping }}
                    onExporting={this._handleExporting}
                    onExported={this._handleExported}
                    onRowDblClick={onRowDoubleClick}
                    onContentReady={this._onComponentReady}
                    onSaving={onSaving}
                    columnAutoWidth={columnAutoWidth}
                    focusedRowKey={focusedRowKey}
                    focusedColumnIndex={focusedColumnIndex}
                    autoNavigateToFocusedRow={autoNavigateToFocusedRow}
                    onEditorPreparing={onEditorPreparing}
                    onEditingStart={onEditingStart}
                >
                    <StateStoring
                        enabled={true}
                        type="custom"
                        customLoad={this._loadState}
                        customSave={this._saveState}
                    />
                    {
                        this.props.onReorder &&
                        <RowDragging
                            allowReordering={true}
                            onReorder={this.props.onReorder}
                            showDragIcons={false}
                        />
                    }
                    <ColumnChooser
                        enabled={!disableColumnChooser && !disableToolbar}
                        mode="select"
                        title="Column Visibility"
                        height={columnChooserHeight}
                    />
                    <GroupPanel visible={!disableGrouping && !disableToolbar} />
                    <HeaderFilter
                        visible={!disableHeaderFilter}
                        searchMode="equals"
                    />
                    <FilterRow visible={!disableFilterRow} />
                    <SearchPanel visible={!disableSearchPanel && !disableToolbar} />
                    <Export enabled={!disableExporting && !disableToolbar} fileName={excelFileName} customizeExcelCell={this._handleCustomizeExcelCell} />
                    <Sorting mode={disableSorting ? 'none' : 'single'} />
                    <Selection
                        mode={selectionMode}
                        showCheckBoxesMode="always"
                        selectAllMode={selectionAllMode}
                    />
                    <Paging
                      enabled={enablePaging}
                      defaultPageSize={pageSizeV}
                    />
                    <Pager
                      visible={enablePaging}
                      showPageSizeSelector={true}
                      showNavigationButtons={true}
                      showInfo={true}
                      allowedPageSizes={allowedPageSizesV}
                    />

                    {
                        (!scrollMode || scrollMode === DataGridScrollMode.standard) &&
                        <Scrolling
                            mode={'standard'}
                            rowRenderingMode={rowRenderingMode || DataGridRowRenderingMode.virtual}
                            preloadEnabled
                            useNative={true}
                        />
                    }
                    {
                        (scrollMode && scrollMode === DataGridScrollMode.virtual) &&
                        <Scrolling mode={'virtual'} />
                    }
                    {
                        editingOptions &&
                        <Editing
                            mode={editingOptions?.mode || DataGridEditingMode.cell}
                            allowUpdating={editingOptions.allowUpdating}
                            allowAdding={editingOptions.allowAdding}
                            allowDeleting={editingOptions?.allowDeleting}
                            useIcons={editingOptions?.useIcons}
                            onChangesChange={editingOptions.onEditingDataChange}
                        />
                    }
                    {
                        customToolBarRender && !disableToolbar &&
                        <Template name="customToolBar" render={customToolBarRender} />
                    }
                    {
                        this._renderColumns(this._customColumns)
                    }
                    {isMasterDetailsEnabled && renderMasterDetails &&
                        <MasterDetail enabled={isMasterDetailsEnabled} autoExpandAll render={e => renderMasterDetails(e)} />
                    }
                    {
                        this.props.summaryConfig &&
                        <Summary calculateCustomSummary={this.props.summaryConfig.calculateCustomSummary}>
                            {this.props.summaryConfig.totalItems.map((item, key) =>
                                <TotalItem
                                    key={key}
                                    name={item.name}
                                    column={item.column}
                                    summaryType="custom"
                                />
                                )}
                        </Summary>
                    }
                </DataGrid>
            </div>
        );
    }

    private _onCellPrepared(cellPreparedEvent: OnCellPreparedArgs<T>) {
        if (cellPreparedEvent.rowType === 'header' && cellPreparedEvent.column?.command === 'select' && cellPreparedEvent.cellElement) {
            const getGridInstance = () => (this.props.dataGridRef || this._gridRef).current?.instance;
            DxGridHelper.replaceSelectAllClickHandler(cellPreparedEvent.cellElement, getGridInstance);
        }
        this.props.onCellPrepared?.(cellPreparedEvent);
    }

    private _onComponentReady = (e: { component?: dxDataGrid; element?: dxElement; model?: T }) => {
        if(this.props.scrollBottomOnInit){
            setTimeout(function() {
                const scrollable = e.component?.getScrollable();
                scrollable?.scrollTo(scrollable?.scrollHeight());
            }, 0);
        }

        this.props.onContentReady?.(e);
    };

    @action
    private _onConfigReset = () => {
        this._resetColumnsWidthToDefault(this._customColumns);
        this._customColumns = [...this._customColumns];
    }

    @action
    private _onFiltersReset = async (gridState: GridState) => {
        this._setColumnsWidth(this._customColumns, gridState.columns);
        this._customColumns = [...this._customColumns];
        await this._saveState(gridState);
    }

    @action
    private _resetColumnsWidthToDefault(columns: DataGridColumn<T>[]) {
        for (const column of columns) {
            column.width = column.defaultWidth;
            if (column.columns && column.columns.length > 0) {
                this._resetColumnsWidthToDefault(column.columns);
            }
        }
    }

    private _setColumnsWidth(columns: DataGridColumn<T>[], gridColumnsState: ColumnData[]) {
        for (const column of columns) {
            const colState = gridColumnsState.find(x => x.name === column.name);
            if (colState) {
                column.width = colState.width;
            }
            if (column.columns && column.columns.length > 0) {
                this._setColumnsWidth(column.columns, gridColumnsState);
            }
        }
    }

    private _createToolbar(e: Toolbar) {
        if (!this.props.disableToolbar) {
            this._toolbar.createToolbar(e, this.props.hideSaveRevertButtons);
        }
    }

    @action
    private _setColumnNames(columns: DataGridColumn<T>[]) {
        for (const column of columns) {
            column.name = this._getColumnName(column);
            if (column.columns && column.columns.length > 0) {
                this._setColumnNames(column.columns);
            }
        }
    }

    private _getColumnKey(column: DataGridColumn<T>): string {

        const { dataFieldName, caption, columnType } = column;

        const key = dataFieldName
            ? `${dataFieldName.toString()}_${caption.replace(' ', '_')}`
            : `${caption.replace(' ', '_')}_${columnType}`;
        return key;
    }

    private _getColumnName(column: DataGridColumn<T>): string {
        if (column.buildCustomColumnName) return column.buildCustomColumnName();
        const key = this._getColumnKey(column);
        return key + (column.columns ? `_${column.columns.map(c => c.dataFieldName).join('_')}` : '');
    }

    private _renderColumns(columnDescriptions: DataGridColumn<T>[]): JSX.Element[] {
        const elements: JSX.Element[] = [];
        columnDescriptions.forEach(x => {
            if (x.columnType === DataGridColumnType.Band) {
                elements.push(this._renderBandColumn(x));
            } else {
                elements.push(this._renderDataColumn(x));
            }
        });

        return elements;
    }

    private _renderBandColumn(x: DataGridBandColumn<T>): JSX.Element {
        const keyValue = this._getColumnName(x);
        if (!keyValue) {
            console.log(keyValue + ', ' + (x.dataFieldName as string));
        }
        const shouldBeExported = x.displayMode?.has(DataGridColumnDisplayMode.Export);
        return (
            <Column
                key={keyValue}
                caption={x.caption}
                alignment={x.contentAlignment}
                allowSearch={false}
                allowExporting={shouldBeExported}
            >
                {x.columns && this._renderColumns(x.columns)}
            </Column>
        );
    }

    private _renderDataColumn(x: DataGridColumn<T>): JSX.Element {
        const isVisibleByDisplayMode = x.displayMode && x.displayMode.has(DataGridColumnDisplayMode.UI);
        const shouldBeExported = x.displayMode?.has(DataGridColumnDisplayMode.Export);
        let filterExpr: ((filterValue: CellData<T>, selectedFilterOperation: string, target: string) => unknown) | undefined;

        if (this.props.allowServerSideFiltering) {
            if (x.dataFieldType === DataGridColumnFieldType.date) {
                filterExpr = (filterValue, selectedFilterOperation) => this._dateFilterExpression(filterValue, selectedFilterOperation, x);
            } else if (x.calculateFilterExpression) {
                filterExpr = x.calculateFilterExpression;
            } else {
                filterExpr = (filterValue, selectedFilterOperation) => this._defaultFilterExpression(filterValue, selectedFilterOperation, x);
            }
        }
        const allowSearch = x.dataFieldName ? (x.dataFieldType === DataGridColumnFieldType.string ? x.allowSearch : false) : false;
        const isDateDataType = x.dataFieldType === DataGridColumnFieldType.date || x.dataFieldType === DataGridColumnFieldType.dateTime;

        return (
            <Column
                name={x.name}
                key={x.name}
                caption={x.caption}
                dataField={x.dataFieldName?.toString() || ''}
                dataType={x.dataFieldType}
                allowGrouping={x.allowGrouping}
                allowHiding={x.allowHiding}
                allowSearch={allowSearch}
                allowHeaderFiltering={x.allowDropdownFiltering}
                allowFiltering={x.allowSearchFiltering}
                filterOperations={x.allowedSearchFilterOperations}
                allowSorting={x.allowSorting}
                allowResizing={x.allowResizing}
                allowExporting={shouldBeExported}
                allowEditing={x.allowEditing}
                alignment={x.contentAlignment}
                calculateCellValue={x.calculateCellValue}
                customizeText={x.customizeCellText}
                cellRender={x.cellRenderTemplate}
                editCellRender={x.allowEditing ? x.editCellRenderTemplate : undefined}
                editorOptions={x.editorOptions}
                groupCellRender={x.groupCellRenderTemplate}
                calculateSortValue={x.calculateSortValue}
                headerFilter={{ dataSource: x.headerFilterOptions }}
                headerCellRender={x.headerCellRenderTemplate ? x.headerCellRenderTemplate : (data: CellData<T>) => <p title={x.tooltip || data.column.caption}>{data.column.caption}</p>}
                calculateFilterExpression={filterExpr}
                visible={!x.isHidden && isVisibleByDisplayMode}
                width={x.width}
                minWidth={x.minWidth}
                sortingMethod={x.enableCultureSpecificSorting ? this._cultureSpecificSorting : null}
                defaultFilterValue={x.defaultFilterValue}
                groupIndex={x.groupIndex}
                sortOrder={x.sortOrder}
                fixed={x.fixed}
                fixedPosition={x.fixedPosition}
                validationRules={x.validationRules}
                lookup={x.lookup}
                trueText={x.trueText}
                falseText={x.falseText}
            >
                {isDateDataType &&
                    <Format 
                        formatter={(date: Date) => {
                            if (x.dataFieldType === DataGridColumnFieldType.date) {
                                const isUTCDisabled = false;
                                return DateTimeService.format(date, DateTime.viewDateFormat, isUTCDisabled);
                            } else {
                                const isUTCDisabled = true; 
                                return DateTimeService.format(date, DateTime.viewFullFormat, isUTCDisabled);
                            }
                        }}
                    />
                }
            </Column>
        );
    }

    private static _formatDate(value: Date) {
        const year = value.getFullYear();
        const month = value.getMonth() + 1;
        const day = value.getDate();
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    }

    private static _parseFilterValueToDate(filterValue: unknown) {
        let date: Date;
        if (Object.prototype.toString.call(filterValue) === '[object Date]') {
            date = (filterValue as Date);
        } else {
            date = new Date(Date.parse(filterValue as string));
        }
        return date;
    }

    private _dateFilterExpression(filterValue: unknown, selectedFilterOperation: string, column: DataGridColumn<T>): unknown {
        let date1: Date | null = null;
        let date2: Date | null = null;
        let operation = selectedFilterOperation;
        if (Array.isArray(filterValue)) {
            if (filterValue[0]) {
                date1 = DxDataGrid._parseFilterValueToDate(filterValue[0]);
            }
            if (filterValue.length > 1 && filterValue[1]) {
                date2 = DxDataGrid._parseFilterValueToDate(filterValue[1]);
            }
        } else if ((filterValue + '').match(/^\d+$/g)) {
            date1 = DxDataGrid._parseFilterValueToDate(filterValue);
            date2 = new Date(date1.getFullYear() + 1, date1.getMonth(), date1.getDate() - 1);
            operation = 'between';
        // eslint-disable-next-line no-useless-escape
        } else if ((filterValue + '').match(/^\d+[\/]\d+$/g)) {
            date1 = DxDataGrid._parseFilterValueToDate(filterValue + '/1');
            date2 = new Date(date1.getFullYear(), date1.getMonth() + 1, date1.getDate() - 1);
            operation = 'between';
        } else {
            date1 = DxDataGrid._parseFilterValueToDate(filterValue);
        }
        if (operation === 'between') {
            let newValue1: string = '';
            let newValue2: string = '';
            const filterExpression: unknown[] = [];

            if (date1) {
                newValue1 = DxDataGrid._formatDate(date1);
                filterExpression.push([`${column.dataFieldName as string}`, '>=', newValue1]);
            }
            if (date2) {
                if (date1) filterExpression.push('and');
                newValue2 = DxDataGrid._formatDate(date2);
                filterExpression.push([`${column.dataFieldName as string}`, '<=', newValue2]);
            }
            return filterExpression;
        } else {
            if (date1) {
                const newValue = DxDataGrid._formatDate(date1);
                return [[`${column.dataFieldName as string}`, (selectedFilterOperation || 'contains'), newValue]];
            }
        }
    }

    private _defaultFilterExpression(filterValue: unknown, selectedFilterOperation: string, column: DataGridColumn<T>): unknown {
        return [[`${column.dataFieldName as string}`, (selectedFilterOperation || 'contains'), filterValue]];
    }

    private _cultureSpecificSorting(value1: string, value2: string) {
        if (!value1 && value2) return -1;
        if (!value1 && !value2) return 0;
        if (value1 && !value2) return 1;
        return value1.localeCompare(value2);
    }

    private _handleExporting(e: ExportingHandlerArgs<T>) {
        e.component?.beginUpdate();  
        this.props.notExportingColumnNames?.forEach(columnName => {
            e.component?.columnOption(columnName, 'visible', false);
        });
    }

    private _handleExported(e: ExportedHandlerArgs<T>) {
        this.props.notExportingColumnNames?.forEach(columnName => {
            e.component?.columnOption(columnName, 'visible', true);
        });
        e.component?.endUpdate();
    }

    private _handleCustomizeExcelCell(e: CustomizeExcelCellArgs<T>) {
        if (e.gridCell.rowType === 'data') {
            if (typeof e.gridCell.value === 'boolean') {
                e.value = e.gridCell.value ? 'Yes' : 'No';
            }
        }

        this.props.onCustomizeExcelCell?.(e);
    }

    @action
    private _calcGridHeight = () => {
        if (this._gridWrapper.current) {
            let margin = 180;
            const isModal = document.getElementsByClassName('modal')?.length > 0;
            if (isModal) {
                margin = 300;
            }
            const offsetTop = this._gridWrapper.current.offsetTop;
            this._gridHeight = document.documentElement.clientHeight - offsetTop - margin;
        }
    }

    @action
    private _saveState = async (gridState: GridState) => {
        this._toolbar.updateButtonsState(this._customColumns, gridState);
        this._toolbar.updateGroupingState(gridState);
        if (!this.props.userConfigGroup) return;
        gridState.selectedRowKeys = [];
        gridState.columns?.forEach(x => {
            const col = this._customColumns.find(c => this._getColumnName(c) === x.name);
            if (col && col.displayMode) {
                x.displayMode = [...Array.from(col.displayMode)];
            }
        });
        // console.log('save grid state:' + JSON.stringify(gridState));
        await configurationStore.set(this.props.userConfigGroup, PrincipalConfig.GridStateKey, JSON.stringify(gridState));
    }

    @action
    private _loadState = async () : Promise<GridState | null> => {
        if (!this.props.userConfigGroup) return null;
        const configValue = await configurationStore.get(this.props.userConfigGroup, PrincipalConfig.GridStateKey);
        let gridState: GridState;
        if (!configValue) {
            const grid = (this.props.dataGridRef || this._gridRef).current?.instance;
            if (!grid || !this.props.defaultFilters) return null;
            const state = grid.state() as GridState;
            DxGridHelper.applyFiltersToState(state, this.props.defaultFilters);
            gridState = state;
        } else {
            gridState = JSON.parse(configValue);
        }
        runInAction(() => {
            this._customColumns = this._correctColumns(this._customColumns, gridState.columns);
        });
        this._toolbar.updateButtonsState(this._customColumns, gridState);

        // important to have timeout for applying configuration after all data is loaded
        // without - grid thinks that new data is a new state and automaticaly tries to save it and after that we read (local) or load wrong (default state)
        // with timeout we schedule this state return and it helps to make correct sequense
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(gridState);
            }, 0);
        })
    }

    private _correctColumns(columns: DataGridColumn<T>[], gridColumnsState: ColumnData[]) {
        const resultColumns: DataGridColumn<T>[] = [];
        for (const col of columns) {
            const stateColumn = this._findGridStateColumn(gridColumnsState, this._getColumnName(col));
            const resColumn: DataGridColumn<T> = Object.assign({}, col);
            if (stateColumn?.width) {
                resColumn.width = stateColumn.width;
            }

            resColumn.isHidden = !stateColumn?.visible;

            resultColumns.push(resColumn);
            
            if (col.columns && col.columns.length > 0) {
                resColumn.columns = this._correctColumns(col.columns, gridColumnsState);
            }
        }
        return resultColumns;
    }

    private _findGridStateColumn(gridColumnsState: ColumnData[], searchName: string) {
        return gridColumnsState.find((s: ColumnData) => s.name === searchName);
    }

    @action
    private _updateColumnVisibility = (gridState: GridState): GridState => {
        const hiddenInPrintMode = new Set(
            this._customColumns.filter(x => x.displayMode && !x.displayMode.has(DataGridColumnDisplayMode.Print)).map(x => this._getColumnName(x)));
        const visibleOnlyInPrintMode = new Set(
            this._customColumns.filter(x => x.displayMode &&
                x.displayMode.has(DataGridColumnDisplayMode.Print) &&
                !x.displayMode.has(DataGridColumnDisplayMode.UI)).map(x => this._getColumnName(x)));
        gridState.columns?.forEach(x => {
            if (hiddenInPrintMode.has(x.name)) {
                x.visible = false;
            } else if (visibleOnlyInPrintMode.has(x.name)) {
                x.visible = true;
            }
            //if column has UI display mode then do nothing because user can set column as invisible and we should't show such column in print mode
        });
        return gridState;
    };

    @action
    public saveEditData() {
        this._gridRef.current?.instance.saveEditData();
    }
}
