import { FaExclamation } from 'react-icons/fa';

//TODO: use enum from WEbApiModel when bug TMS-452 is fixed
enum ClauseRelationValidationResult {
    NoError = "NoError",
    ParentHasNoChild = "ParentHasNoChild",
    NoReferencesClause = "NoReferencesClause",
    NoLeafClause = "NoLeafClause",
    CircularReference = "CircularReference",
}

type ClauseTypeCellProps = {
    value: string;
    validationCode: ClauseRelationValidationResult;
    columnSize: number;
};

type EnumDictionary<T extends string, U extends string> = {
    // eslint-disable-next-line no-unused-vars
    [K in T]: U;
};

export function ClauseTypeCell({ value, validationCode, columnSize }: ClauseTypeCellProps) {
    //TODO: move to EnumHelper when bug TMS-452 is fixed
    const ClauseRelationValidationResultLabels: EnumDictionary<ClauseRelationValidationResult, string> = {
        [ClauseRelationValidationResult.NoError]: '',
        [ClauseRelationValidationResult.ParentHasNoChild]: 'The Parent Clause has no child',
        [ClauseRelationValidationResult.NoReferencesClause]: 'No References Clause',
        [ClauseRelationValidationResult.NoLeafClause]: 'The References Clause does not end up with Direct Clause',
        [ClauseRelationValidationResult.CircularReference]: 'Circular Reference',
    };

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: `${columnSize - 10}px` }}>
                {value}
            </div>
            {
                validationCode !== ClauseRelationValidationResult.NoError &&
                //<FaExclamation color="red" title={enumHelper.ClauseRelationValidationResultLabels[validationCode]} />
                //<FaExclamation color="red" title={validationCode} />
                <FaExclamation color="red" title={ClauseRelationValidationResultLabels[validationCode]} />
            }
        </div>
    );
}