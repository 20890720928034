import * as React from 'react';
import { AxiosPromise } from 'axios';
import { TestGridModel, TestReportModel, AvailableTestReportModel } from '@Models';
import { IModalDialogContent, ModalDialogOptions, ModalWindow } from '@Components';
import { AvailableForReferenceTestReportsTable } from '../Components/AvailableForReferenceTestReportsTable';

export type TestReportReferenceDialogProps = {
    ownTcId: number;
    title?: string;
    loadData: (isOtherTechnologyCenter: boolean, otherTechnologyCenterId?: number) => AxiosPromise<AvailableTestReportModel[]>;
    onAddClick: (testReportModel: TestReportModel) => Promise<void>;
    onDeleteClick: (testReportModel: TestReportModel) => Promise<void>;
};

export class TestReportReferenceDialog extends React.Component<TestReportReferenceDialogProps> implements IModalDialogContent<TestGridModel> {
    render() {
        const { ownTcId, loadData, onAddClick, onDeleteClick } = this.props;
        return (
            <AvailableForReferenceTestReportsTable
                ownTcId={ownTcId}
                loadData={loadData}
                onAddClick={onAddClick}
                onDeleteClick={onDeleteClick}
            />
        );
    }

    public getModalOptions(window: ModalWindow<TestGridModel>): ModalDialogOptions<TestGridModel> {
        return {
            title: this.props.title || `Select Referenced Test Report`,
            buttons: [],
            width: '1050px',
            bodyClassName: 'create-rule-dialog'
        };
    }
}
