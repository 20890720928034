export const PROJECT_DETAILS_RELOAD = 'project_details_reload';
export const PLAN_DELETED = 'plan_deleted';
export const TEST_OBJECT_CREATED = 'test_object_created';
export const TEST_OBJECT_UPDATED = 'test_object_updated';
export const RELOAD_TEST_OBJECT_DOCS = 'reload_test_object_docs';
export const TEST_UPDATED = 'test_updated';
export const TEST_RUN_REPORTS_CHANGED = 'TEST_RUN_REPORTS_CHANGED';
export const STANDARD_CREATED = 'standard_created';
export const STANDARD_UPDATED = 'standard_updated';
export const CLAUSE_CREATED = 'clause_created';
export const CLAUSE_UPDATED = 'clause_updated';
export const CLAUSE_DELETED = 'clause_deleted';
export const PRODUCT_CLASS_CREATED = 'product_class_created';
export const PRODUCT_CLASS_UPDATED = 'product_class_updated';
export const PRODUCT_CREATED = 'product_created';
export const PRODUCT_UPDATED = 'product_updated';
export const DESIGN_PARAMETER_CREATED = 'design_parameter_created';
export const DESIGN_PARAMETER_UPDATED = 'design_parameter_updated';
export const TECHNICAL_RATING_CREATED = 'technical_rating_created';
export const TECHNICAL_RATING_UPDATED = 'technical_rating_updated';
export const HOMOLOGATION_CREATED = 'homologation_created';
export const HOMOLOGATION_UPDATED = 'homologation_updated';
export const CUSTOMER_CREATED = 'customer_created';
export const CUSTOMER_UPDATED = 'customer_updated';
export const MARKET_CREATED = 'market_created';
export const MARKET_UPDATED = 'market_updated';
export const MANUFACTURER_CREATED = 'manufacturer_created';
export const MANUFACTURER_UPDATED = 'manufacturer_updated';
export const TECHNOLOGY_CENTER_CREATED = 'technology_center_created';
export const TECHNOLOGY_CENTER_UPDATED = 'technology_center_updated';
export const LAB_CREATED = 'lab_created';
export const LAB_UPDATED = 'lab_updated';
export const MEMBER_CREATED = 'member_created';
export const MEMBER_UPDATED = 'member_updated';
export const STANDARDS_RELOAD = 'reload_standards';
export const RELOAD_TEST_RUN = 'reload_test_run';
export const RELOAD_TEST_INFO = 'reload_test_info';
export const RELOAD_TEST_INFO_WITH_ID = 'reload_test_info_with_id';
export const COMPONENT_CREATED = 'component_created';
export const COMPONENT_UPDATED = 'component_updated';
export const SPRODUCTFAMILY_CREATED = 'component_created';
export const SPRODUCTFAMILY_UPDATED = 'component_updated';
export const RELOAD_MANAGE_DOCS_GRID = 'reload_manage_docs_grid';
export const RELOAD_TEST_REPORTS_GRID = 'reload_test_reports_grid';

export class TTMEvents {
    static TTM_CREATED = 'type_test_matrix_created';
    static TTM_UPDATED = 'type_test_matrix_updated';
    static TTM_VARIANT_CREATE_CLICK = 'type_test_matrix_variant_create_click';
    static TTM_VARIANT_DELETE_CLICK = 'type_test_matrix_variant_delete_click';
    static TTM_RELOAD_PRODUCT_VARIANTS = 'type_test_matrix_reload_product_variants';
    static TTM_VARIANT_COPY_CLICK = 'type_test_matrix_variant_copy_click';
    static TTM_VARIANT_COPY_FINISH = 'type_test_matrix_variant_copy_finish';
    static TTM_VARIANT_ADD_VARIANT = 'type_test_matrix_variant_add';
    static TTM_VARIANT_EDIT_VARIANT = 'type_test_matrix_variant_edit';
    static RELOAD_TTM_VARIANT_DOC_GRID = 'reload_type_test_matrix_variant_doc_grid';
}

export class TTPEvents {
    static TTP_REFRESH_REPORTS = 'ttp_refresh_reports';
    static CLAUSE_CREATED = 'ttp_clause_created';
    static CLAUSE_UPDATED = 'ttp_clause_updated';
    static TECHNICAL_RATING_UPDATED = 'ttp_technical_rating_updated';
    static DESIGN_PARAMETER_UPDATED = 'ttp_design_parameter_updated';
    static RELOAD_PRODUCT_VARIANTS = 'ttp_reload_product_variants';
    static REVALIDATE_PRODUCT_VARIANTS = 'ttp_revalidate_product_variants';
    static RELOAD_COMPONENTS = 'ttp_reload_components';
    static STANDARD_UPDATED = 'ttp_standard_updated';
    static RESET_GRID_STATE = 'ttp_reset_grid_state';
}

export class TRDEvents {
    static REFRESH_REPORTS = 'trd_refresh_reports';
    static REFRESH_DOC = 'trd_refresh_doc';
}

export class LOREvents {
    static LOR_STATE_CHANGED = 'lor_state_changed';
    static LOR_CREATED = 'lor_created';
    static LOR_UPDATED = 'lor_updated';
    static FINANCIAL_ENTRY_CHANGED = 'lor_financial_entry_changed';
    static FINANCIAL_ENTRY_DOCS_CHANGED = 'lor_financial_entry_docs_changed';
    static REFRESH_TEST_OBJECT_AVAILABILITY = 'lor_refresh_test_object_availability';
    static TEST_RUNS_CHANGED = 'lor_test_runs_changed';
}

export class LabEvents {
    static LAB_RELOAD = 'lab_reload';
}

export class TODOCEvents {
    static SAVE_CLICK = 'TODOCEvents_save_click';
}

export class TCEvents {
    static TC_RELOAD = 'TC_reload';
}

export class TestRunReportSettingsEvents {
    static TESTRUN_REPORT_SETTINGS_CHANGED = 'testrun_report_settings_changed';
}
