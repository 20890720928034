import { apiClient } from "@Models";
import { LookupModelEnumPicker, LookupModelEnumPickerBaseProps } from '@Components/Pickers/LookupModelEnumPicker/LookupModelEnumPicker';
import {BaseFormModel} from "@Helpers";
import {useCallback} from "react";
import {LookupModelEnumFormPicker,LookupModelEnumFormPickerProps} from "@Components/Pickers/LookupModelEnumPicker/LookupModelEnumFormPicker";


export function ClauseTestResolvePicker({ ...props }: LookupModelEnumPickerBaseProps) {
    const loadData = useCallback( () => {
        const loadFunc = apiClient.enumClauseTestResolveGet.bind(apiClient);
        return loadFunc();
    }, []);
    return (
        <LookupModelEnumPicker
            {...props}
            loadData={loadData}
        />
    );
}

export function ClauseTestResolveFormPicker<T extends BaseFormModel<T>>({...rest} : LookupModelEnumFormPickerProps<T>) {
    const loadData = useCallback( () => {
        const loadFunc = apiClient.enumClauseTestResolveGet.bind(apiClient);
        return loadFunc();
    }, []);
    return (
        <LookupModelEnumFormPicker
            {...rest}
            loadData={loadData}
        />
    );
}