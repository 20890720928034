import { Col, Row } from 'reactstrap';
import { apiClient, LookupModel, TestReportCategory } from '@Models';
import { FormDatePicker, FormDropdownInput, FormInput, FormLabel } from '@Components';
import { LabFormPicker, TechnologyCenterFormPicker } from '@Components/Pickers';
import { FormModel } from './TestReportFormModel';
import { useCallback, useEffect, useState } from 'react';
import { LookupModelEnumFormPicker } from '@Components/Pickers/LookupModelEnumPicker/LookupModelEnumFormPicker';

type TestReportFormProps = {
    form: FormModel;
    isLabOfferRequest: boolean;
    isDisabledByTechnologyCenterRule?: boolean;
    productId?: number;
    isReadOnly?: boolean;
};

export function TestReportForm({ form, isLabOfferRequest, productId, isDisabledByTechnologyCenterRule, isReadOnly = false }: TestReportFormProps) {
    const loadTestReportCategories = useCallback(() => apiClient.enumTestReportCategoryGet(), []);

    const [infos, setInfos] = useState<string[]>([]);
    useEffect(() => {
        if (productId) {
            apiClient.productInfoLookupGet({ productId: productId }).then(({ data }) => setInfos(data.filter(x => x.label !== undefined).map(x => x.label!)))
        }
    }, [productId]);

    return (
        <>
            <Row>
                <Col sm="4">
                    <FormLabel formModel={form} name="info" />
                </Col>
                <Col>
                    <FormDropdownInput
                        options={infos}
                        name="info"
                        formModel={form}
                        disabled={isReadOnly}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <FormLabel formModel={form} name="category" />
                </Col>
                <Col>
                    {/* TODO: form refactoring */}
                    <LookupModelEnumFormPicker
                        formModel={form}
                        name="category"
                        loadData={loadTestReportCategories}
                        disabled={isReadOnly || isDisabledByTechnologyCenterRule}
                        onChange={(e) => form.category = e?.value as TestReportCategory}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <FormLabel formModel={form} name="number" />
                </Col>
                <Col>
                    <FormInput<FormModel>
                        name="number"
                        formModel={form}
                        disabled={isReadOnly || isDisabledByTechnologyCenterRule}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <FormLabel formModel={form} name="issuedDate" />
                </Col>
                <Col>
                    <FormDatePicker
                        name="issuedDate"
                        formModel={form}
                        disabled={isReadOnly || isDisabledByTechnologyCenterRule}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <FormLabel formModel={form} name="testLabId" />
                </Col>
                <Col>
                    <LabFormPicker
                        formModel={form}
                        name="testLabId"
                        isClearable
                        disabled={isLabOfferRequest || isReadOnly || isDisabledByTechnologyCenterRule}
                        onChange={(lab: LookupModel | null) => {
                            const value = lab ? lab.value.toString() : '';
                            form.testLabId = value
                            if (!form.issuedLabId) {
                                form.issuedLabId = value;
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <FormLabel formModel={form} name="issuedLabId" />
                </Col>
                <Col>
                    <LabFormPicker
                        formModel={form}
                        name="issuedLabId"
                        isClearable
                        disabled={isReadOnly || isDisabledByTechnologyCenterRule}
                        onChange={(lab: LookupModel | null) => {
                            form.issuedLabId = lab ? lab.value.toString() : '';
                        }}
                    />
                </Col>
            </Row>
            {!isLabOfferRequest &&
                < Row >
                    <Col sm="4">
                        <FormLabel formModel={form} name="technologyCenterId" />
                    </Col>
                    <Col>
                        <TechnologyCenterFormPicker
                            formModel={form}
                            name="technologyCenterId"
                            isClearable
                            disabled={isReadOnly || isDisabledByTechnologyCenterRule}
                            onChange={(m: LookupModel | null) => {
                                form.technologyCenterId = m ? m.value.toString() : '';
                            }}
                        />
                    </Col>
                </Row>
            }
        </>
    );
}
