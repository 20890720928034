import Axios, { AxiosPromise, CancelToken } from "axios";
import { FileUpload } from "@AppConstants";
import { apiClient } from "@Models";

type UploadByChunksOptions = {
    cancelToken?: CancelToken;
    onProgressChange?: (progress: number) => void;
    onError?: () => void;
    onCancel?: () => void;
}

class FileUploadService {
    public async uploadByChunks<T>(file: File, onChunksUpload: (lastChunk: Blob, guid: string) => AxiosPromise<T>, options?: UploadByChunksOptions) {
        const guid = this._generateFileGuid();

        const chunkSize = FileUpload.TempFileChunkSizeInBytes;
        const chunksCount = file.size % chunkSize === 0
            ? file.size / chunkSize
            : Math.floor(file.size / chunkSize) + 1;

        let chunkStart = 0;
        let chunkEnd = chunkSize;

        for (let i = 1; i <= chunksCount; i++) {
            const chunkToUpload = file.slice(chunkStart, chunkEnd);

            try {
                await apiClient.fileUploadPost({ data: chunkToUpload, fileName: file.name }, guid, i, chunksCount, { cancellationToken: options?.cancelToken });
                if (i === chunksCount) {
                    await onChunksUpload(chunkToUpload, guid);
                }

                const progress = Math.floor(i / chunksCount * 100);
                options?.onProgressChange?.(progress);
            } catch(error: unknown) {
                if (Axios.isCancel(error)) options?.onCancel?.();

                options?.onError?.();
            }


            chunkStart = chunkEnd;
            chunkEnd += chunkSize;
        }
    }

    private _generateFileGuid(): string {
        return (crypto as any).randomUUID();
    }
}

export const fileUploadService = new FileUploadService();
