import { Loading } from '@Components/Loading/Loading';
import React, {ErrorInfo} from 'react';

type Boundary = {
    error: Error | null;
    errorInfo: ErrorInfo | null;
    isReloadingRequired: boolean;
};

export class ErrorBoundary extends React.Component<{}, Boundary> {
    constructor(props: {}) {
        super(props);
        this.state = { error: null, errorInfo: null, isReloadingRequired: false };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        const isChunkLoadError = error.name === 'ChunkLoadError';
        const isReloadingRequired = isChunkLoadError;

        if (isReloadingRequired) {
            window.location.reload();
        }

        this.setState({ error, errorInfo, isReloadingRequired });  
    }

    render() {
        if (this.state.isReloadingRequired) {
            return (
                <Loading loading fullPage />
            );
        }

        if (this.state.errorInfo) {
            return (
                <div className="p-5">
                    <h2>Something went wrong.</h2>
                    <p>Please contact support.</p>
                    <details style={{ whiteSpace: 'pre-wrap', color: 'red' }}>
                        {this.state.error && this.state.error.toString()}
                        <br/>
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }

        return this.props.children;
    }
}
  