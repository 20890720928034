import * as React from 'react';
import dxDataGrid, { FocusedCellChangedEvent, FocusedCellChangingEvent } from 'devextreme/ui/data_grid';

export interface IGridConfigState {
    gridStateJson: string;
}

export type CellValue = string | number | Date | boolean | null;
export type CellData = { value?: CellValue; valueText?: string; target?: string; groupInterval?: string | number };
export type GridCellData<T> = {
    data?: T;
    column: ColumnData;
    rowType: 'data' | 'header';
    value?: CellValue;
};

export type GroupCellData<T> = {
    column: ColumnData;
    columnIndex: number;
    component: React.ReactNode;
    data: T;
    displayValue: string | Date;
    isAltRow: boolean;
    isEditing: boolean;
    key: string[];
    rowType: 'data' | 'header' | 'group';
    rowIndex: number;
    text: string;
    value: string | Date;
};

export type HeaderCellData = {
    column: ColumnData;
    columnIndex: number;
    component: React.ReactNode;
    isAltRow: boolean;
    rowType: 'data' | 'header' | 'group';
    rowIndex: number;
};

export enum DataGridColumnContentAlignment {
    left = 'left',
    center = 'center',
    right = 'right'
}
export enum DataGridColumnSortOrder {
    desc = 'desc',
    asc = 'asc',
}

export enum DataGridColumnFieldType {
    number = 'number',
    string = 'string',
    date = 'date',
    boolean = 'boolean',
    object = 'object',
    dateTime = 'datetime'
}

export enum SearchFilterOperation {
    contains = 'contains',
    notcontains = 'notcontains',
    startswith = 'startswith',
    endswith = 'endswith',
    equal = '=',
    notequal = '<>',
    less = '<',
    greater = '>',
    lessorequal = '<=',
    greaterorequal = '>=',
    between = 'between'
}

export enum DataGridSelectionMode {
    single = 'single',
    multiple = 'multiple',
    none = 'none',
}

export enum DataGridSelectionAllMode {
    allPages = 'allPages',
    page = 'page',
}

export enum DataGridScrollMode {
    standard = 'standard',
    virtual = 'virtual',
}

export enum DataGridRowRenderingMode {
    standard = 'standard',
    virtual = 'virtual',
}

export enum DataGridEditingMode {
    row = 'row',
    cell = 'cell',
    batch = 'batch'
}

export enum DataGridColumnDisplayMode {
    UI = 'UI',
    Export = 'Export',
    Print = 'Print'
}

export class FilterOptionsItem {
    key: string;
    text: string;
    value: string[][] | string;
}

export type HeaderFilterPostProcessOptions = {
    dataSource?: { postProcess?: ((data: FilterOptionsItem[]) => FilterOptionsItem[])};
};

export type ExportingHandlerArgs<T = any> = {
    component?: dxDataGrid<T, any>;
    cancel?: boolean;
};

export type ExportedHandlerArgs<T = any> = {
    component?: dxDataGrid<T, any>;
};

export type OnInitializedParams<T> = {
    component?: T;
    element?: HTMLElement;
};

export type CellInfo<TModel> = {
    data?: TModel;
    value?: CellValue;
    column?: { name: string, caption: string };
};

export type OnRowPreparedArgs<TModel> = {
    data?: TModel;
    rowType?: string;
    rowIndex?: number;
    rowElement: HTMLElement;
};

export type OnCellPreparedArgs<TModel> = {
    data?: TModel;
    column?: DataGridColumnInfo;
    cellElement?: HTMLElement;
    value?: CellValue;
    rowType?: string;
    rowIndex?: number;
    component?: any;
};

export type OnRowDoubleClickArgs<TModel> = {
    data?: TModel;
    rowElement?: Element;
    groupIndex?: number;
};

export type DataGridColumnInfo = {
    caption?: string;
    dataField?: string;
    command?: string;
};

export type ValidationRule = {
    type: string;
};

export type OnRowPreparedHandler<TModel> = (e: OnRowPreparedArgs<TModel>) => unknown;
export type OnCellPreparedHandler<TModel> = (e: OnCellPreparedArgs<TModel>) => unknown;

export type ExcelCellFont = {
    bold: boolean;
    name: string;
    size: number;
    color: string;
};

export type CustomizeExcelCellArgs<TModel> = {
    gridCell: GridCellData<TModel>;
    value: CellValue;
    font: ExcelCellFont;
    numberFormat: number | string;
    backgroundColor: string;
    horizontalAlignment: string;
    verticalAlignment: string;
    wrapTextEnabled: boolean;
};

export type OnCustomizeExcelCellHandler<TModel> = (args: CustomizeExcelCellArgs<TModel>) => void;

export type OnSelectionChangedArgs<TModel> = {
    selectedRowsData?: Array<TModel>;
};

export type OnSelectionChangedHandler<TModel> = (args: OnSelectionChangedArgs<TModel>) => unknown;
export type OnRowDoubleClickHandler<TModel> = (args: OnRowDoubleClickArgs<TModel>) => unknown;

export type OnFocusedRowChangedArgs<T> = {
    row?: DataGridRowObject<T>;
    component?: dxDataGrid<T, any>;
};

export type OnFocusedRowChangingArgs<T> = {
    component: dxDataGrid<T, any>;
    cancel?: boolean;
    element: HTMLElement;
    newRowIndex: number;
    prevRowIndex: number;
    model?: any;
    rows: DataGridRowObject<T>[];
    rowElement: HTMLElement;
    event?: Event;
};

export type DataGridRowObject<T> = {
    data?: T;
    rowType?: string;
};

export type OnFocuserRowChangedHandler<T> = (args: OnFocusedRowChangedArgs<T>) => unknown;
export type OnFocusedRowChangingHandler<T> = (args: OnFocusedRowChangingArgs<T>) => unknown;

export type OnFocusedCellChangedHandler<T> = (event: FocusedCellChangedEvent<T>) => void;
export type OnFocusedCellChangingHandler<T> = (event: FocusedCellChangingEvent<T>) => void;

export type ColumnFilterValue = string[][] & { columnIndex: number };

export enum FilterType {
    Include = 'include',
    Exclude = 'exclude'
}

export class ColumnData {
    caption?: string;
    dataField?: string;
    dataType?: DataGridColumnFieldType;
    filterType?: FilterType;
    filterValue?: string | number;
    filterValues?: ColumnFilterValue[] | string[];
    visible: boolean;
    visibleIndex: number;
    name: string;
    width?: number;
    groupIndex?: number;
}

export class ColumnDataExt extends ColumnData {
    displayMode?: DataGridColumnDisplayMode[];
}

export type GridState = {
    columns: ColumnDataExt[];
    searchText: string;
    selectedRowKeys: string[];
    focusedRowKey: string | number;
};

export enum FilterOperation {
    Equal = '=',
    NotEqual = '<>',
    Contains = 'contains'
}

export interface IGridFilter {
    filterType: FilterType;
    field: string;
    fieldType: DataGridColumnFieldType;
    values: {
        operation: FilterOperation;
        value: string;
    }[];
}

export type HeaderFilterPredefinedOption = {
    filterType: FilterType;
    values: {
        operation: FilterOperation;
        value: string;
    }[];
};

export type CustomStoreOptions = {
    load?: ((options: LoadOptions | unknown) => Promise<unknown>  | Array<unknown>);
    byKey?: ((key: unknown | string | number) => Promise<unknown> );
    // load?: ((options: LoadOptions | unknown) => Promise<unknown> | JQueryPromise<unknown> | Array<unknown>); // TODO: JQuery promise
    // byKey?: ((key: unknown | string | number) => Promise<unknown> | JQueryPromise<unknown>);
    key?: string | Array<string>;
};

export type LoadOptions = {
    filter?: unknown;
    group?: unknown;
    requireGroupCount?: boolean;
    requireTotalCount?: boolean;
    searchExpr?: string | Function | Array<string | Function>;
    searchOperation?: string;
    searchValue?: unknown;
    select?: unknown;
    skip?: number;
    sort?: unknown;
    take?: number;
};

export type RowRemovingArg<T> = {
    model?: unknown;
    data?: T;
    key?: T;
    cancel?: boolean | Promise<void>;
    // cancel?: boolean | Promise<void> | JQueryPromise<void>; // TODO: JQ promise
};

export type EditingOptions<T> ={
    mode?: DataGridEditingMode;
    useIcons?: boolean;
    allowUpdating?: boolean;
    allowAdding?: boolean;
    allowDeleting?: boolean;
    onEditingDataChange?: (e: DataChanges<T>[]) => void;
}

export type DataChanges<T> = {
    data: T;
    key: T;
    type: string;
}

export type OnSavingProps<T> = {
    cancel: boolean;
    changes: DataChanges<T>[];
}

export class DxGridDefaults {
    public static readonly DefaultPageSize = 50;
    public static readonly AllowedPageSize = [50, 100, 200];
}

export type DataGridColumnEditorOptions = {
    format?: string | DataGridCustomFormatMasks;
}

export enum DataGridCustomFormatMasks {
    PositiveNumbers = '#;-'
}

export type DataGridEditorPreparingEvent<T> = {
    cancel: boolean;
    caption?: string;
    dataField?: string;
    editorName: string;
    row?: DataGridRowObject<T>;
}
