import { memo, useEffect, useState } from 'react';
import { apiClient, ISearchParams, LookupStringValueModel } from '@Models';
import { ReactSelectStyles, Select} from '@Components';

import { GroupBase, InputActionMeta } from "react-select";
import { useCallback } from 'react';
import { useMountEffect } from '@Hooks';

const DEFAULT_PAGE_SIZE = 10;

type PersonPickerAsTextChangeCallback = (users: LookupStringValueModel[] | null) => void;
type PickerProps = {
    clearAfterSelect?: boolean;
    value?: string | undefined;
    placeholder?: string;
    isDisabled?: boolean;
    className?: string;
    pageSize?: number;
    invalid?: boolean;
    isClearable?: boolean;
    onUsersSelect?: PersonPickerAsTextChangeCallback;
    onGetCustomRequestParams?: () => Partial<ISearchParams>;
}

function usePaginatedLoading() {
    const [options, setOptions] = useState<LookupStringValueModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);

    const loadOptions = useCallback(async (params: ISearchParams) => {
        setLoading(true);
        apiClient.principalSearchGet(params).then(({ data: options }) => {
            setLoading(false);
            const newOptions: LookupStringValueModel[] = options.map(x => { return {value: x.displayName, label: x.displayName} });
            if (params.page === 0) {
                if(!!params.term){
                    const valueOptions = newOptions.find(x => x.value === params.term);
                    if (!valueOptions) {
                        newOptions.unshift({value: params.term, label: params.term});
                    }
                }
                setOptions(newOptions);
            }
            else {
                setOptions((prevOptions) => [...prevOptions, ...newOptions]);
            }
        });
    }, [setOptions, setLoading]);

    const loadPage = useCallback(async (params: Partial<ISearchParams>, page: number) => {
        params.page = page;
        params.size = DEFAULT_PAGE_SIZE;
        loadOptions(params);
    }, [loadOptions]);

    const loadFirstPage = (params: Partial<ISearchParams>) => loadPage(params, 0);

    const loadNextPage = useCallback(async (params: Partial<ISearchParams>) => {
        loadPage(params, page + 1);
        setPage(prevpage => prevpage + 1);
    }, [page, setPage, loadPage]);

    const resetOptions = useCallback(() => {
        setPage(0);
        setOptions([]);
    }, [setPage, setOptions]);

    return { options, loading, page, loadFirstPage, loadNextPage, resetOptions, setOptions, setPage }
}

export const PersonSelectAsText = memo((props: PickerProps) => {
    const { value, onGetCustomRequestParams } = props;
    const { options, loading, loadFirstPage, loadNextPage, resetOptions } = usePaginatedLoading();

    const [selected, setSelected] = useState<LookupStringValueModel | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [mounted, setMounted] = useState(false);

    const getRequestParams = useCallback((inputValue: string = '') => {
        const params: Partial<ISearchParams> = onGetCustomRequestParams ? onGetCustomRequestParams() : {};
        if (inputValue) params.term = inputValue;
        return params;
    }, [onGetCustomRequestParams]);

    useMountEffect(() => {
        loadFirstPage(getRequestParams());
        setMounted(true);
        if (value) {
            setSelected({value: value, label: value});
        }
    });
    
    useEffect(() => {
        if (!mounted) return;
        resetOptions();
        loadFirstPage(getRequestParams(inputValue));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGetCustomRequestParams]);

    useEffect(() => {
        if (!mounted) return;
        if (selected && selected.value === value) {
            return;
        }

        if (!value) {
            setSelected(null);
        } else {
            const selected = options.find(x => x.value === value);
            setSelected(selected ?? null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    function handleInputChange(value: string, actionMeta: InputActionMeta) {
        const closedMenu = actionMeta.action === 'menu-close' && !value && inputValue;
        const changedInput = actionMeta.action === 'input-change';
        if (closedMenu || changedInput) {
            resetOptions();
            loadFirstPage(getRequestParams(value));
        }

        setInputValue(value);
    }

    function handleSelectionChange(value: LookupStringValueModel | null) {
        setSelected(value);
        const selected = value ? [value] : [];
        props.onUsersSelect?.(selected);
    }

    const { className, isDisabled, placeholder, isClearable, invalid } = props;
    const cls = ['react-select'];
    className && cls.push(className); 

    return (
        <Select<LookupStringValueModel>
            className={cls.join(' ')}
            classNamePrefix="react-select"
            options={options}
            value={selected}
            isSearchable
            isLoading={loading}
            isDisabled={isDisabled}
            isClearable={isClearable}          
            onChange={handleSelectionChange}
            onInputChange={handleInputChange}
            onMenuScrollToBottom={() => loadNextPage(getRequestParams(inputValue))}
            menuPortalTarget={document.body}
            styles={{ ...ReactSelectStyles<LookupStringValueModel, false, GroupBase<LookupStringValueModel>>(!!invalid), ...({}) }}
            placeholder={placeholder || 'Select Person'}
            filterOption={() => true} // does not recognize new options with search open // options are filtered on BE
        />
    );     
});
