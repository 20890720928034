import { useState } from 'react';
import classNames from 'classnames';
import { observer } from "mobx-react";
import { IoNotifications } from 'react-icons/io5';
import styles from './Notifications.module.scss';
import { NotificationsList } from './NotificationsList/NotificationsList';
import { notificationsStore } from "@GlobalStores";

type NotificationsProps = {
  buttonClassName: string;
}

export const Notifications = observer(({buttonClassName}: NotificationsProps) => {
  const [isVisible, setVisible] = useState(false);

  const { notifications, notificationsCount, dismissNotification } = notificationsStore;

  return (
    <div title="Notifications" className={classNames(buttonClassName, styles.button)}>
      <IoNotifications size={18} onClick={() => setVisible(!isVisible)}/>
      {
        notificationsCount > 0 && <div className={styles.notificationsCount}>{notificationsCount}</div>
      }
      <NotificationsList
        visible={isVisible}
        onHide={() => setVisible(!isVisible)}
        notifications={notifications}
        onDismiss={(id) => dismissNotification(id)}
      />
    </div>
  )
})
