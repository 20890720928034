import { memo } from 'react';
import { withErrorContainer } from '../ErrorContainer/ErrorContainer';
import { withFormFeedback } from '../FormFeedback/FormFeedback';
import { Input, InputProps } from '../Input/Input';
import { findHasValueAfterDot, toNumberFormat } from "@Components/FormControls/Basic/NumberInput/helpers";

export type NumberInputProps = Omit<InputProps, 'onChange'> & {
	maxLength?: number;
	countOfDecimalPositions?: number;
	allowNegativeValue?: boolean;
	disableBlurHandler?: boolean;
	disableMaxLength?: boolean;
	disableDecimal?: boolean;
	onChange?: (value: string) => void;
};

export const ShiftsNumberInput = memo(({ placeholder = "Enter number of shifts, for example: 0.25, 0.5, 0.75, 1, 2, etc.", ...props }: NumberInputProps) => {
	return (
	  <NumberInput
		placeholder={placeholder}
		{...props}
	  />
	);
});

export const NumberInput = memo(({ allowNegativeValue, countOfDecimalPositions, maxLength, disableBlurHandler, disableDecimal, disableMaxLength, onChange, onBlur, ...props }: NumberInputProps) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const numberValue = e.currentTarget?.value ? toNumberFormat(String(e.currentTarget.value), { allowNegativeValue, maxLength, disableDecimal, disableMaxLength }) : '';
		onChange?.(numberValue);
	}

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (!disableBlurHandler && e.currentTarget?.value.length) {
			const value = e.currentTarget?.value;
			const decimalsLength = (disableDecimal || !findHasValueAfterDot(value)) ? 0 : countOfDecimalPositions || 2;

			onChange?.(Number(e.currentTarget?.value).toFixed(decimalsLength));
		}
		onBlur?.(e);
	}

	return (
		<Input
			{...props}
			onChange={handleChange}
			onBlur={handleBlur}
		/>
	);
});

export const NumberInputWithError = withErrorContainer<NumberInputProps>(NumberInput);
export const NumberInputWithFeedback = withFormFeedback<NumberInputProps>(NumberInput);
