import { apiClient } from "@Models";
import { TEST_OBJECT_CREATED } from '@AppConstants';
import { useCallback } from "react";
import { LookupModelPicker, LookupModelPickerBaseProps } from "../LookupModelPicker/LookupModelPicker";

type TestObjectPickerProps = LookupModelPickerBaseProps & {
    value?: string;
}

export const TestObjectPicker = ({ value, ...props }: TestObjectPickerProps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.testObjectLookupGet.bind(apiClient), []);
    const parsedValue = Number(value);
    const defaultValue = value !== null ? parsedValue ? parsedValue : undefined : null;

    return(
        <LookupModelPicker
            {...props}
            loadData={loadData}
            reloadOn={TEST_OBJECT_CREATED}
            defaultValue={defaultValue}
        />
    );
}
