import { PrincipalConfig } from "@AppConstants";
import { ActionButton, ActionCell } from "@Components/ActionCell/ActionCell";
import { AddButton } from "@Components/AddButton/AddButton";
import { CellInfo, DataGridColumn, DataGridColumnFieldType, DxDataGrid } from "@Components/DataGrid";
import { TestReportWithConfigsModel } from "@Models";
import { SourceMode } from "@Pages/Components/ManageTestDocuments/TestDocumentsGrid";
import { DataGrid } from "devextreme-react";
import { memo, useCallback, useMemo } from "react";

const CELL_ITEM_HEIGHT = 30;

type TestReportWithConfigsGridProps = {
    gridRef: React.RefObject<DataGrid<TestReportWithConfigsModel, any>>;
    options: TestReportWithConfigsModel[];
    canPrincipalManage: boolean;
    sourceMode: SourceMode;
    onDocumentsClick: (data: TestReportWithConfigsModel) => Promise<void>;
    onDeleteClick: (data: TestReportWithConfigsModel) => Promise<void>;
    onAddTestReportClick: () => Promise<void>;
}

export const TestReportWithConfigsGrid = memo(({ 
    gridRef, options, canPrincipalManage, sourceMode,
    onDocumentsClick, onDeleteClick, onAddTestReportClick }: TestReportWithConfigsGridProps) => {
    
    const actionsCellRender = (cellData: CellInfo<TestReportWithConfigsModel>) => {
        const { data } = cellData;
        if (!data) return null;

        const buttons: ActionButton[] = [
            { name: 'uploadedDocument', onClick: () => onDocumentsClick(data), title: 'Documents' },
            { name: 'delete', onClick: () => onDeleteClick(data), visible: canPrincipalManage, title: 'Delete' }
        ];

        return <ActionCell buttons={buttons} />;
    };
    
    const parentClausesCellRender = (cellData: CellInfo<TestReportWithConfigsModel>) => {
        return (
            <div className="dx-multiCell">
                {cellData.data?.testReportConfigs?.map((config, index) => {
                    return (
                        <div key={index} className="dx-multiCell-item" style={{ height: CELL_ITEM_HEIGHT }} title={config.parentClauseDisplayName} >
                            <div className="dx-multiCell-value">
                                {config.parentClauseDisplayName}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    const clausesCellRender = (cellData: CellInfo<TestReportWithConfigsModel>) => {
        return (
            <div className="dx-multiCell">
                {cellData.data?.testReportConfigs?.map((config, index) => {
                    return (
                        <div key={index} className="dx-multiCell-item" style={{ height: CELL_ITEM_HEIGHT }} title={config.clauseFullName} >
                            <div className="dx-multiCell-value">
                                {config.clauseFullName}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    const columns = useMemo(() => {
        const columns: DataGridColumn<TestReportWithConfigsModel>[] = [];

        columns.push(
            new DataGridColumn<TestReportWithConfigsModel>({ dataFieldName: 'number', caption: 'Number', width: sourceMode === SourceMode.TRD ? 150 : 550}),
        );

        if (sourceMode === SourceMode.TRD) {
            columns.push(
                new DataGridColumn<TestReportWithConfigsModel>({ caption: 'Performances', cellRenderTemplate: parentClausesCellRender }),
                new DataGridColumn<TestReportWithConfigsModel>({ caption: 'Test according to', cellRenderTemplate: clausesCellRender }),
            );
        }

        columns.push(
            new DataGridColumn<TestReportWithConfigsModel>({ dataFieldName: 'issuedDate', caption: 'Valid From', dataFieldType: DataGridColumnFieldType.date, width: 90 }),
            new DataGridColumn<TestReportWithConfigsModel>({ dataFieldName: 'testLabDisplayName', caption: 'Test Lab', width: 120 }),
            new DataGridColumn<TestReportWithConfigsModel>({ dataFieldName: 'issuedLabDisplayName', caption: 'Issued Lab', width: 120 }),
            new DataGridColumn<TestReportWithConfigsModel>({ dataFieldName: 'id', caption: 'Actions', cellRenderTemplate: actionsCellRender, width: 75 }),
        );

        return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderToolbar = useCallback((): JSX.Element => {

        return (
            <AddButton onClick={onAddTestReportClick} text="Add Test Report" hidden={!canPrincipalManage} />
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canPrincipalManage]);
    
    return (
        <DxDataGrid<TestReportWithConfigsModel>
            dataGridRef={gridRef}
            dataSource={options}
            userConfigGroup={sourceMode === SourceMode.TRD ? PrincipalConfig.TrdDocTestReports : PrincipalConfig.DocTestReports }
            columns={columns}
            disableGrouping
            customToolBarRender={renderToolbar}
        />
    );
});