import React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import DataSource from 'devextreme/data/data_source';
import DataGrid from 'devextreme-react/data-grid';
import { DxDataGrid, Loader, IDxDataGridProps } from '@Components';

type RestrictedDxDatagridWithDataProps<T> = Omit<IDxDataGridProps<T>, 'autoExpandAllGrouping'
    | 'dataGridRef'
    | 'dataSource'
    | 'defaultFilters'
    | 'highlightChanges'
    | 'repaintChangesOnly'
>;

export type DxDataGridWithDataWrapperProps<TModel> = RestrictedDxDatagridWithDataProps<TModel> & {
    dataItems: TModel[];
    dataItemKey?: string;
}

@observer
export class DxDataGridWithDataWrapper<TModel> extends React.Component<DxDataGridWithDataWrapperProps<TModel>> {
    @observable.ref private _dataSource: DataSource;
    @observable.ref private _gridRef: React.RefObject<DataGrid<TModel, any>> = React.createRef();

    get gridRef() {
        return this._gridRef?.current;
    }

    constructor(props: DxDataGridWithDataWrapperProps<TModel>) {
        super(props);
        makeObservable(this);
        this._dataSource = new DataSource({
            store: props.dataItems,
            reshapeOnPush: true,
            pageSize: 100
        });
    }

    render() {
        return (
            <React.Suspense fallback={<Loader isSuspense />}>
                <DxDataGrid<TModel>
                    {...this.props}
                    dataGridRef={this._gridRef}
                    dataSource={(this._dataSource) ?? undefined}
                    repaintChangesOnly
                    highlightChanges
                />
            </React.Suspense>
        );
    }

    public addAll(items: TModel[]) {
        this._dataSource.store()?.push(items.map(i => ({ type: 'insert', data: i })));
    }

    public async updateFields(sourceItems: TModel[], fields?: string[]) {
        const itemKeys: string[] = [];
        const store = this._dataSource.store();
        if(!store)
            return ;
        for (const sourceItem of sourceItems) {
            const key = (sourceItem as any)[this.props.dataItemKey || 'id'];
            itemKeys.push(key);

            const item = await store.byKey(key);
            if (item) {
                if (fields) {
                    fields.forEach(f => {
                        item[f] = sourceItem[f as keyof TModel];
                    });
                }
                else {
                    Object.keys(sourceItem as any).forEach(k => {
                        item[k] = sourceItem[k as keyof TModel];
                    });
                }
            }
        }

        const rowIndexes = itemKeys.map(x => {
            return this.gridRef?.instance.getRowIndexByKey(x) || 0;
        });

        if (rowIndexes) {
            this.gridRef?.instance.repaintRows(rowIndexes);
        }
    }
    
    public updateItem(key: string) {
        const rowIndex = this.gridRef?.instance.getRowIndexByKey(key) || 0;
        if (rowIndex) {
            this.gridRef?.instance.repaintRows([rowIndex]);
        }
    }
    
    public updateAll(items: TModel[]) {
        this._dataSource.store()?.push(items.map(i => ({ type: 'update', key: (i as any)[this.props.dataItemKey || 'id'], data: i })));
        const rowIndexes = items.map(x => {
            const key = (x as any)[this.props.dataItemKey || 'id'];
            return this.gridRef?.instance.getRowIndexByKey(key) || 0;
        });

        if (rowIndexes) {
            this.gridRef?.instance.repaintRows(rowIndexes);
        }
    }

    public deleteAll(items: string[]) {
        this._dataSource?.store().push(items.map(key => ({ type: 'remove', key })));
    }

    public getItems() {
        return this._dataSource.items();
    }
}