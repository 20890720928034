import * as React from 'react';
import { AxiosPromise } from 'axios';

import { AvailableTestReportModel, TestReportWithConfigsModel } from '@Models';

import { IModalDialogContent, ModalDialogOptions, ModalWindow } from '@Components';
import { DoCTestReportsDialogBody } from './DoCTestReportsDialogBody';
import { SourceMode } from '@Pages/Components/ManageTestDocuments/TestDocumentsGrid';


export type DoCTestReportsDialogProps = {
    doCNumber?: string;
    doCVersion?: number;
    validFrom?: Date;
    canPrincipalManage: boolean;
    technologyCenterId?: number;
    typeTestMatrixId?: number;
    sourceMode: SourceMode;
    declarationOfConformityId: number;
    loadData: () => AxiosPromise<TestReportWithConfigsModel[]>;
    loadReferenceTestReports?: (isOtherTechnologyCenter: boolean, otherTechnologyCenterId?: number) => AxiosPromise<AvailableTestReportModel[]>;
    onAddTestReport?: (testReportId: number) => AxiosPromise<void>;
    onDeleteTestReport?: (testReportId: number) => AxiosPromise<void>;
};

export class DoCTestReportsDialog extends React.Component<DoCTestReportsDialogProps, {}> implements IModalDialogContent<void> {
    render() {
        return <DoCTestReportsDialogBody {...this.props} />;
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: 'DoC Test Reports',
            width: '1040px'
        };
    }
}
