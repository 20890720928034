import { PureComponent } from 'react';

import { IDocumentStore } from '../../IDocumentStore';
import { EditDocumentDialogBody } from './EditDocumentDialogBody';

import {
    IModalDialogContent,
    ModalDialogOptions,
    ModalWindow,
} from '@Components';
import {DocumentInfoModel, DocumentState} from '@Models';
import { AxiosPromise } from 'axios';


export type EditDocumentDialogProps = {
    documentOwnerId: number;
    documentTypeName: string;
    documentId: number;
    store: IDocumentStore;
    document: DocumentInfoModel;
    defaultState?: DocumentState;
    onUpdate?: () => void;
};

export class EditDocumentDialog extends PureComponent<EditDocumentDialogProps> implements IModalDialogContent<void> {
    render() {
        return (
            <EditDocumentDialogBody
                documentOwnerId={this.props.documentOwnerId}
                documentId={this.props.documentId}
                document={this.props.document}
                documentTypeName={this.props.documentTypeName}
                store={this.props.store}
                updateDocument={this.props.store.updateDocument as unknown as () => AxiosPromise<DocumentInfoModel> }
                defaultState={this.props.defaultState}
            />
        );
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: "Manage Documents",
            width: '600px',
            bodyClassName: 'create-rule-dialog',
        };
    }
}
