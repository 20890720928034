import { apiClient } from "@Models";
import { LookupModelPicker, LookupModelPickerBaseProps } from '@Components/Pickers/LookupModelPicker/LookupModelPicker';
import {useCallback, useMemo} from "react";

type ParentClausePickerProps = {
    standardId: number
} & LookupModelPickerBaseProps;


export function ParentClausePicker({ standardId, ...props }: ParentClausePickerProps) {
    const dataParams = useMemo( () => {
        return {standardId: standardId};
    }, [standardId]);
    const loadData = useCallback( () => {
        const loadFunc = apiClient.clauseParentAllParentsGet.bind(apiClient);
        return loadFunc({standardId: standardId});
    }, [standardId]);
    return (
        <LookupModelPicker
            {...props}
            loadData={loadData}
            isParamsRequired
            loadDataParams={dataParams}
        />
    );
}