import { RELOAD_MANAGE_DOCS_GRID } from "@AppConstants";
import { ProgressBarByChunksDialog } from "@Components/Dialogs/ProgressBarDialogs/ProgressBarByChunksDialog";
import { useModalContext } from "@Components/Modal/ModalContext";
import { modalService } from "@Components/Modal/ModalService";
import { Form, useForm } from "@Libs/Form";
import { DocumentCreateModel, DocumentState } from "@Models";
import { checkFormValid } from "@Pages/Project/SubPages/ProjectDetails/Actions";
import { memo, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { DocumentOwnerFullName } from "../../../DocumentOwnerFullName/DocumentOwnerFullName";
import { FinancialEntryDocumentStore } from "../../../LabOfferRequests/FinancialEntryDocumentStore";
import { IDocumentStore } from "../../IDocumentStore";
import { DocType, DocumentForm } from "../DocumentForm/DocumentForm";
import { initCreateDocumentForm, documentFormValidationSchema, documentFormLabels, DocumentFormModel } from "../DocumentForm/DocumentFormModel";
import { AddDocumentDialogProps } from "./AddDocumentDialog";

type AddDocumentDialogBodyProps = AddDocumentDialogProps;


export const AddDocumentDialogBody = memo(({ store, documentOwnerId, documentTypeName, defaultState, onAdd }: AddDocumentDialogBodyProps) => {
    const form = useForm(() => initCreateDocumentForm(), documentFormValidationSchema, documentFormLabels);
    const [ownerName, setOwnerName] = useState('');
    const { close } = useModalContext();
    const isFinancialEntryDocument = store instanceof FinancialEntryDocumentStore;

    useEffect(() => {
        store.loadDocumentOwnerFullName(documentOwnerId).then(({ data }) => setOwnerName(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <DocumentOwnerFullName name={ownerName} />
            <DocumentForm form={form} defaultState={defaultState} isNameHidden={isFinancialEntryDocument} />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    size="sm"
                    style={{ marginRight: 10 }}
                    onClick={close}
                >
                    Close
                </Button>
                <Button
                    size="sm"
                    color="success"
                    onClick={async () => {
                        if (isFinancialEntryDocument)
                            documentFormValidationSchema.documentName = undefined;
                        if (!checkFormValid(form))
                            return
                        if (form.formFields.docType === DocType.File && !form.formFields.documentFile) {
                            return modalService.showInformation("Choose a file please");
                        }
                        await handleCreateClick(store, documentOwnerId, documentTypeName, form, defaultState, onAdd);
                        close();
                    }}
                >
                    Create New
                </Button>
            </div>
        </>
    );
});

async function handleCreateClick(store: IDocumentStore, documentOwnerId: number, documentTypeName: string, form: Form<DocumentFormModel>, defaultState?: DocumentState, onAdd?: () => void) {
    const createModel: DocumentCreateModel = {
        documentOwnerId,
        documentName: form.formFields.documentName,
        state: defaultState ?? form.formFields.state,
        isLink: form.formFields.docType === DocType.Link,
        link: form.formFields.link,
        isUploadAwaiting: !!form.formFields.documentFile,
        documentTypeName
    };

    const { data: doc } = await store.createDocument(createModel);

    if (form.formFields.docType === DocType.File && form.formFields.documentFile && doc) {
        await modalService.show(ProgressBarByChunksDialog, {
            file: form.formFields.documentFile,
            request: (lastChunk, guid) => store.uploadFile(guid, doc.id, { data: lastChunk, fileName: form.formFields.documentFile!.name })
        });
    }

    document.dispatchEvent(new Event(RELOAD_MANAGE_DOCS_GRID));
    onAdd?.();
}
