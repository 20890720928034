import {toast} from 'react-toastify';
import {SignalREvents} from '@AppConstants';
import {signalRService} from '@Services';

export class NotificationHandler {

    static subscribeToNotifications() {
        signalRService.subscribe<string>(SignalREvents.success, (eventName: string, message: string) => NotificationHandler.showSuccess(message));
        signalRService.subscribe<string>(SignalREvents.warning, (eventName: string, message: string) => NotificationHandler.showWarning(message));
        signalRService.subscribe<string>(SignalREvents.error, (eventName: string, message: string) => NotificationHandler.showError(message));
        signalRService.subscribe<string>(SignalREvents.info, (eventName: string, message: string) => NotificationHandler.showInfo(message));
    }

    public static showError(message: string, toastId?: string) {
        toast.error(message, {toastId});
    }

    public static showInfo(message: string, toastId?: string) {
        toast.info(message, {toastId});
    }

    public static showWarning(message: string, toastId?: string) {
        toast.warning(message, {toastId});
    }

    public static showSuccess(message: string, toastId?: string) {
        toast.success(message, {toastId});
    }

    public static clear() {
        toast.clearWaitingQueue();
    }
}

NotificationHandler.subscribeToNotifications();