import { memo } from 'react';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IconButtonWrapper } from "@Components/IconButtonWrapper/IconButtonWrapper";

type CloseIconButtonProps = {
    onClick?: () => void;
    disabled?: boolean;
    size?: number;
    title?: string;
    className?: string;
};

function CloseIconButton({ onClick, disabled, size, className, title }: CloseIconButtonProps) {
	return (
        <IconButtonWrapper 
            disabled={disabled}
            onClick={onClick}
            IconType={AiOutlineCloseCircle}
            size={size}
            className={className}
            title={title}
        />
	);
}

const Memoized = memo(CloseIconButton);
export { Memoized as CloseIconButton };