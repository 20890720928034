import { RefObject } from "react";
import { DataGrid } from "devextreme-react";

export const updateGridRow = async <TRow, TKey>(gridRef: RefObject<DataGrid<any, TKey>>, rowKey: TKey, newRow: TRow) => {
    const instance = gridRef.current?.instance;
    if (!instance) return;

    await instance.getDataSource().store().update(rowKey, newRow);

    const index = instance.getRowIndexByKey(rowKey);
    if (index === undefined) return;

    instance.repaintRows([index]);
}

export const removeGridRow = <TKey>(gridRef: RefObject<DataGrid<any, TKey>>, rowKey: TKey) => {
    const instance = gridRef.current?.instance;
    if (!instance) return;

    const index = instance.getRowIndexByKey(rowKey);
    if (index === undefined) return;

    instance.option('editing.confirmDelete', false);
    instance.deleteRow(index);
}

export const getAllGridOptions = async <TRow>(gridRef: RefObject<DataGrid<TRow>>) => {
    const instance = gridRef.current?.instance;
    if (!instance) return;

    const store = instance.getDataSource().store();
    const items = await store?.load();

    return items;
}
