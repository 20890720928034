import { AxiosPromise } from "axios";
import { action, makeObservable, observable, runInAction } from "mobx";
import { DocumentState, TestDocumentType, TestDocumentInfoModel, apiClient, TestDocumentUpdateModel, TestDocumentCreateModel } from "@Models";
import { modalService } from "@Components/Modal/ModalService";
import { FormModel } from "./TestDocumentFormModel";
import { ProgressBarByChunksDialog } from "@Components/Dialogs/ProgressBarDialogs/ProgressBarByChunksDialog";

export class TestDocumentFormStore {
    @observable public _document: TestDocumentInfoModel | null;
    @observable public documentType: TestDocumentType;
    @observable public documentOwnerId: number;
    @observable public labOfferRequestId?: number;
    @observable public typeTestPlanId?: number;
    @observable public typeTestMatrixId?: number;
    @observable public testReportConfigurationId?: number;
    form = new FormModel();

    constructor(documentOwnerId: number, docType: TestDocumentType, documentId?: number, labOfferRequestId?: number, typeTestPlanId?: number, typeTestMatrixId?: number, testReportConfigurationId?: number) {
        makeObservable(this);

        this.documentOwnerId = documentOwnerId;
        this.documentType = docType;
        this.labOfferRequestId = labOfferRequestId;
        this.typeTestPlanId = typeTestPlanId;
        this.typeTestMatrixId = typeTestMatrixId;
        this.testReportConfigurationId = testReportConfigurationId;


        if (documentId && this.testReportConfigurationId && !this.labOfferRequestId && !this.typeTestPlanId && !this.typeTestMatrixId) {
            apiClient.testDocumentTrdGet({ testReportConfigurationId: this.testReportConfigurationId, documentId: documentId }).then((r) => {
                runInAction(() => {
                    this._document = r.data;
                    this.initForm();
                });
            });
        }
        else if (documentId && this.labOfferRequestId && !this.typeTestPlanId && !this.typeTestMatrixId) {
            apiClient.testDocumentLorGet({ labOfferRequestId: this.labOfferRequestId, documentId: documentId }).then((r) => {
                runInAction(() => {
                    this._document = r.data;
                    this.initForm();
                });
            });
        }
        else if (documentId && !this.labOfferRequestId && this.typeTestPlanId && !this.typeTestMatrixId) {
            apiClient.testDocumentTtpGet({ typeTestPlanId: this.typeTestPlanId, documentId: documentId }).then((r) => {
                runInAction(() => {
                    this._document = r.data;
                    this.initForm();
                });
            });
        }
        else if (documentId && !this.labOfferRequestId && !this.typeTestPlanId && this.typeTestMatrixId) {
            apiClient.testDocumentTtmGet({ typeTestMatrixId: this.typeTestMatrixId, documentId: documentId }).then((r) => {
                runInAction(() => {
                    this._document = r.data;
                    this.initForm();
                });
            });
        }
        else {
            this._document = null;
            this.initForm();
        }
    }

    @action.bound
    initForm() {
        this.form.state = this._document?.state || DocumentState.Approved;
        this.form.uploadedFileName = this._document?.uploadedFileName;
    }

    handleUpdateClick = async (onUpdate?: () => void) => {
        const isFormValid = this.form.validate();
        if (!isFormValid || !this._document) return false;

        const updateModel: TestDocumentUpdateModel = {
            documentId: this._document.id,
            documentOwnerId: this.documentOwnerId,
            documentType: this.documentType,
            state: this.form.state,
            isLink: false,
            documentTypeName: this.documentType.toString(),
            isUploadAwaiting: !!this.form.documentFile
        };

        if (this.testReportConfigurationId && !this.labOfferRequestId && !this.typeTestPlanId && !this.typeTestMatrixId) {
            await apiClient.testDocumentUpdateTrdByTestReportConfigurationIdPut(this.testReportConfigurationId, updateModel);
        }
        else if (this.labOfferRequestId && !this.typeTestPlanId && !this.typeTestMatrixId) {
            await apiClient.testDocumentUpdateLorByLabOfferRequestIdPut(this.labOfferRequestId, updateModel);
        }
        else if (!this.labOfferRequestId && this.typeTestPlanId && !this.typeTestMatrixId) {
            await apiClient.testDocumentUpdateTtpByTypeTestPlanIdPut(this.typeTestPlanId, updateModel);
        }
        else if (!this.labOfferRequestId && !this.typeTestPlanId && this.typeTestMatrixId) {
            await apiClient.testDocumentUpdateTtmByTypeTestMatrixIdPut(this.typeTestMatrixId, updateModel);
        }
        else {
            return false;
        }
        if (this.form.documentFile) {
            let request: (chunk: Blob, guid: string) => AxiosPromise<void>;

            if (this.testReportConfigurationId) {
                request = (chunk, guid) => apiClient.testDocumentFileTrdByTestReportConfigurationIdPut(this.testReportConfigurationId!, this._document!.id, { data: chunk, fileName: this.form.documentFile.name }, guid);
            }
            else if (this.labOfferRequestId) {
                request = (chunk, guid) => apiClient.testDocumentFileLorByLabOfferRequestIdPut(this.labOfferRequestId!, this._document!.id, { data: chunk, fileName: this.form.documentFile.name }, guid);
            }
            else if (this.typeTestPlanId) {
                request = (chunk, guid) => apiClient.testDocumentFileTtpByTypeTestPlanIdPut(this.typeTestPlanId!, this._document!.id, { data: chunk, fileName: this.form.documentFile.name }, guid);
            }
            else if (this.typeTestMatrixId) {
                request = (chunk, guid) => apiClient.testDocumentFileTtmByTypeTestMatrixIdPut(this.typeTestMatrixId!, this._document!.id, { data: chunk, fileName: this.form.documentFile.name }, guid);
            }
            
            await modalService.show(ProgressBarByChunksDialog, {
                file: this.form.documentFile,
                request: (lastChunk, guid) => request(lastChunk, guid)
            });
        }
        return true;
    }

    handleCreateClick = async () => {
        const isFormValid = this.form.validate();
        if (!isFormValid) return false;
        if (!this.form.documentFile) {
            modalService.showInformation("Choose a file please");
            return false;
        }

        const createModel: TestDocumentCreateModel = {
            documentOwnerId: this.documentOwnerId,
            documentType: this.documentType,
            state: this.form.state,
            isFinalized: true,
            isLink: false,
            isUploadAwaiting: !!this.form.documentFile,
            documentTypeName: this.documentType.toString()
        };

        var document: TestDocumentInfoModel;
        if (this.testReportConfigurationId && !this.labOfferRequestId && !this.typeTestPlanId && !this.typeTestMatrixId) {
            document = (await apiClient.testDocumentAddTrdByTestReportConfigurationIdPost(this.testReportConfigurationId, createModel)).data;
        }
        else if (this.labOfferRequestId && !this.typeTestPlanId && !this.typeTestMatrixId) {
            document = (await apiClient.testDocumentAddLorByLabOfferRequestIdPost(this.labOfferRequestId, createModel)).data;
        }
        else if (!this.labOfferRequestId && this.typeTestPlanId && !this.typeTestMatrixId) {
            document = (await apiClient.testDocumentAddTtpByTypeTestPlanIdPost(this.typeTestPlanId, createModel)).data;
        }
        else if (!this.labOfferRequestId && !this.typeTestPlanId && this.typeTestMatrixId) {
            document = (await apiClient.testDocumentAddTtmByTypeTestMatrixIdPost(this.typeTestMatrixId, createModel)).data;
        }
        else {
            return false;
        }
        runInAction(() => {
            this._document = document;
        });
        if (this._document) {
            let request: (chunk: Blob, guid: string) => AxiosPromise<void>;

            if (this.testReportConfigurationId) {
                request = (chunk, guid) => apiClient.testDocumentFileTrdByTestReportConfigurationIdPut(this.testReportConfigurationId!, this._document!.id, { data: chunk, fileName: this.form.documentFile.name }, guid);
            }
            else if (this.labOfferRequestId) {
                request = (chunk, guid) => apiClient.testDocumentFileLorByLabOfferRequestIdPut(this.labOfferRequestId!, this._document!.id, { data: chunk, fileName: this.form.documentFile.name }, guid);
            }
            else if (this.typeTestPlanId) {
                request = (chunk, guid) => apiClient.testDocumentFileTtpByTypeTestPlanIdPut(this.typeTestPlanId!, this._document!.id, { data: chunk, fileName: this.form.documentFile.name }, guid);
            }
            else if (this.typeTestMatrixId) {
                request = (chunk, guid) => apiClient.testDocumentFileTtmByTypeTestMatrixIdPut(this.typeTestMatrixId!, this._document!.id, { data: chunk, fileName: this.form.documentFile.name }, guid);
            }

            await modalService.show(ProgressBarByChunksDialog, {
                file: this.form.documentFile,
                request: (lastChunk, guid) => request(lastChunk, guid)
            });
        }

        return true;
    }
}
