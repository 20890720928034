const DEFAULT_MAX_LENGTH = 16;

type NumberFormatOptions = {
    maxLength?: number;
    allowNegativeValue?: boolean;
    disableMaxLength?: boolean;
    disableDecimal?: boolean;
}

export const toNumberFormat = (value: string, options?: NumberFormatOptions) => {
    const inputValues = value.split('');
    const validValues = [];
    let hasDotOrComma = false;
    let isDecimal = false;

    for (let i = 0; i < inputValues.length; i++) {
        const char = inputValues[i];
        const isDotOrComma = char.includes(',') || char.includes('.');

        if (options?.allowNegativeValue && i === 0 && char.includes('-')) {
            validValues.push(char);
            continue;
        }

        if (isDotOrComma) {
            if (hasDotOrComma) continue;

            if (!(options?.disableDecimal)) {
                validValues.push('.');
                hasDotOrComma = true;
            }
            isDecimal = true;
        } else {
            if ((isNaN(Number(char)) || char.includes(' '))) continue;

            if (isDecimal && options?.disableDecimal) continue;

            if (!(options?.disableMaxLength) && !isDecimal) {
                if (validValues.length < (options?.maxLength || DEFAULT_MAX_LENGTH)) {
                    validValues.push(char);
                }
                continue;
            }
            
            validValues.push(char);
        }
    }

    return validValues.join('');
}

export const findHasValueAfterDot = (value: string) => {
    let hasDotOrComma = false;

    for (let i = 0; i < value.length; i++) {
        const char = value[i];
        const isDot = char.includes('.');

        if (isDot) {
            hasDotOrComma = true;
            continue;
        } else if (hasDotOrComma && !(char.includes('0'))) {
            return true;   
        }
    }

    return false;
}
