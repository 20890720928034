import { observable, action, computed, makeObservable } from 'mobx';

export type GlobalLoader = {
    key: string;
    message?: string;
};

class GlobalLoaderStore {
    @observable private _loaders: GlobalLoader[] = [];

    constructor() {
        makeObservable(this);
    }

    @action.bound
    public showAppLoader(key: string, message?: string) {
        if (this._loaders.find(loader => loader.key === key)) return;

        this._loaders.push({ key, message });
    }

    @action.bound
    public hideAppLoader(key: string) {
        const loaders = this._loaders.filter(loader => loader.key !== key);
        if (this._loaders.length === loaders.length) return;
        
        if (loaders.length) {
            this._loaders = loaders;
        } else {
            this._reloadApp();
        }
    }

    private _reloadApp() {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }

    @computed
    public get isLoading() {
        return !!this._loaders.length;
    }

    @computed
    public get loadingMessage() {
        return this._loaders[this._loaders.length - 1]?.message;
    }
}

export const globalLoaderStore = new GlobalLoaderStore();
