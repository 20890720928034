import React, { memo, useCallback, useEffect, useState } from 'react';
import { EditModeSwitcher } from '../EditModeSwitcher/EditModeSwitcher';
import { EditContextProvider } from './EditContext';

export type IEditSaveWrapper = {
    onEdit?: () => void;
    onCancel?: () => void;
    onSaving?: () => Promise<boolean>;
    onSave?: () => Promise<boolean> | Promise<void> | void;
    onDelete?: () => void;
    onEditingChange?: (editing: boolean) => void;
}

export type EditSaveWrapperProps = {
    hideControls?: boolean;
    hideCancel?: boolean;
    defaultEditable?: boolean;
} & IEditSaveWrapper;

export const EditSaveWrapper: React.FC<EditSaveWrapperProps> = memo((props) => {
    const { onEdit, onSave, onSaving, onCancel, onDelete, onEditingChange, hideControls, hideCancel, defaultEditable, children } = props;
  
    const [editing, _setEditing] = useState(false);
    
    useEffect(() => {
        setEditing(!!defaultEditable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultEditable]);

    const setEditing = useCallback((editing: boolean) => {
        _setEditing(editing);
        onEditingChange?.(editing);
    }, [onEditingChange]);

    const handleEditClick = useCallback(() => {
        setEditing(true);
        onEdit?.();
    }, [setEditing, onEdit])

    const handleSave = useCallback(async () => {
        if (onSaving) {
            const result = await onSaving();
            if (!result) return;
        }

        if (onSave) {
            var result = await onSave();
            if (result === false) return;
        }

        setEditing(false);
    }, [onSaving, onSave, setEditing])

    const handleCancel = useCallback(() => {
        setEditing(false);
        onCancel?.();
    }, [setEditing, onCancel])
    return (
        <>
            <EditContextProvider
                editing={editing}
                onSave={handleSave}
                onCancel={handleCancel}
                onEdit={handleEditClick}
                onDelete={onDelete}
            >
                {
                    !hideControls &&
                    <EditModeSwitcher
                        editing={editing}
                        hideCancel={hideCancel}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        onEdit={onEdit ? handleEditClick : undefined}
                        onDelete={onDelete}
                    />
                }
                { children }
            </EditContextProvider>
        </>
    );
});
