import { useCallback } from "react";
import { BaseFormModel } from "@Helpers";
import { apiClient } from "@Models";
import { LookupModelFormPicker, LookupModelFormPickerProps } from "@Components/Pickers/LookupModelPicker/LookupModelFormPicker";
import { LookupModelPicker, LookupModelPickerBaseProps } from "../LookupModelPicker/LookupModelPicker";

export function LabPicker(props: LookupModelPickerBaseProps) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.labLookupGet.bind(apiClient), []);
    return <LookupModelPicker {...props} loadData={loadData} />;
}

export function LabFormPicker<T extends BaseFormModel<T>>({...rest} : LookupModelFormPickerProps<T>) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(apiClient.labLookupGet.bind(apiClient), []);
    return (
        <LookupModelFormPicker
            {...rest}
            loadData={loadData}
        />
    );
}