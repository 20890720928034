import { useRef } from "react";
import {
    ActionButton,
    ActionCell,
    AddButton,
    CellInfo,
    DataGridColumn,
    DataGridColumnFieldType,
    DxDataGrid, ModalButtonType
} from "@Components";
import { TestDocumentType, TestDocumentInfoModel, apiClient, TestReportCategory } from '@Models';
import { useDxDatagridStore } from '@Components/DataGrid/Hooks/Hooks';
import { enumHelper } from "@Helpers";
import { modalService } from "@Components/Modal/ModalService";
import { ApiUrls, LOREvents, RELOAD_TEST_RUN, TTPEvents } from "@AppConstants";
import { TestDocumentDialog } from "@PageComponents";
import DataGrid from "devextreme-react/data-grid";
import { downloadService } from "@Services";

export enum SourceMode {
    TRD = 'TRD',
    TTP = 'TTP',
    TTM = 'TTM'
}

type TestDocumentsGridProps = {
    documentOwnerId: number;
    documentOwnerDisplayName: string;
    reportCategory: TestReportCategory;
    labOfferRequestId?: number;
    typeTestPlanId?: number;
    typeTestMatrixId?: number;
    testReportConfigurationId?: number;
    isTestRunWithoutLOR?: boolean;
    sourceMode: SourceMode;
    isEditable: boolean;
    declarationOfConformityId?: number;
    onAdd?: () => void;
    onUpdate?: () => void;
    onDelete?: () => void;
};

function TestDocumentsGrid({ documentOwnerId, documentOwnerDisplayName, reportCategory, labOfferRequestId, typeTestPlanId, typeTestMatrixId, testReportConfigurationId, isTestRunWithoutLOR, isEditable, sourceMode, declarationOfConformityId, onAdd, onUpdate, onDelete }: TestDocumentsGridProps) {
    const gridRef = useRef<DataGrid<TestDocumentInfoModel, any>>(null);

    let loadData = () => apiClient.testDocumentGridGet({ testReportId: documentOwnerId });
    if (sourceMode === SourceMode.TTM) {
        if (typeTestPlanId) {
            loadData = () => apiClient.testDocumentGridTtpGet({ typeTestPlanId: typeTestPlanId, testReportId: documentOwnerId });
        }
        else if (typeTestMatrixId) {
            loadData = () => apiClient.testDocumentGridTtmGet({ typeTestMatrixId: typeTestMatrixId, testReportId: documentOwnerId });
        }
    }

    if (sourceMode === SourceMode.TRD) {
        loadData = () => apiClient.testDocumentGridTrdGet({ testReportId: documentOwnerId, typeTestMatrixVariantDocumentId: declarationOfConformityId});
    }

    const [options, reloadGridOptions] = useDxDatagridStore<TestDocumentInfoModel>(loadData, gridRef);

    const onDocumentAdd = () => {
        reloadGridOptions();
        document.dispatchEvent(new Event(LOREvents.LOR_STATE_CHANGED));
        document.dispatchEvent(new Event(TTPEvents.TTP_REFRESH_REPORTS));
        document.dispatchEvent(new Event(TTPEvents.REVALIDATE_PRODUCT_VARIANTS));
        onAdd?.();
    }

    const onDocumentUpdate = () => {
        reloadGridOptions();
        document.dispatchEvent(new Event(TTPEvents.REVALIDATE_PRODUCT_VARIANTS));
        onUpdate?.();
    }

    const openAddDocumentDialog = async () => {
        await modalService.show(TestDocumentDialog, {
            documentOwnerId: documentOwnerId,
            documentOwnerDisplayName: documentOwnerDisplayName,
            labOfferRequestId: labOfferRequestId,
            typeTestPlanId: typeTestPlanId,
            typeTestMatrixId: typeTestMatrixId,
            testReportConfigurationId: testReportConfigurationId,
            title: 'Document Upload',
            allowedTestDocumentType: reportCategory === TestReportCategory.Fault
                ? [TestDocumentType.FailedReport]
                : labOfferRequestId || isTestRunWithoutLOR
                    ? [TestDocumentType.FullReport, TestDocumentType.ShortReport, TestDocumentType.ConfirmationLetter]
                    : [TestDocumentType.FullReport, TestDocumentType.ShortReport],
            onAdd: onDocumentAdd
        });
    };

    const openManageDocumentDialog = async (model: TestDocumentInfoModel) => {
        await modalService.show(TestDocumentDialog, {
            documentId: model.id,
            documentOwnerId: model.documentOwnerId,
            documentOwnerDisplayName: documentOwnerDisplayName,
            labOfferRequestId: labOfferRequestId,
            typeTestPlanId: typeTestPlanId,
            typeTestMatrixId: typeTestMatrixId,
            testReportConfigurationId: testReportConfigurationId,
            title: 'Document Upload',
            allowedTestDocumentType: [model.documentType],
            onUpdate: onDocumentUpdate
        });
    };

    const onRowDblClickHandler = async (model: TestDocumentInfoModel) => {
        await openManageDocumentDialog(model);
    };

    const handleAddClick = async () => {
        await openAddDocumentDialog();
    };

    const handleEditClick = async (model: TestDocumentInfoModel) => {
        await openManageDocumentDialog(model);
    };

    const handleDeleteClick = async (model: TestDocumentInfoModel) => {
        const message = `Do you really want to delete the document?`;
        const confirmed = await modalService.showConfirmation(message);
        if (confirmed === ModalButtonType.Confirm) {
            if (testReportConfigurationId && !labOfferRequestId && !typeTestPlanId && !typeTestMatrixId) {
                await apiClient.testDocumentDeleteTrdDelete({ testReportConfigurationId, documentId: model.id });
            }
            else if (labOfferRequestId && !typeTestPlanId && !typeTestMatrixId) {
                await apiClient.testDocumentDeleteLorDelete({ labOfferRequestId, documentId: model.id });
            }
            else if (!labOfferRequestId && typeTestPlanId && !typeTestMatrixId) {
                await apiClient.testDocumentDeleteTtpDelete({ typeTestPlanId, documentId: model.id });
            }
            else if (!labOfferRequestId && !typeTestPlanId && typeTestMatrixId) {
                await apiClient.testDocumentDeleteTtmDelete({ typeTestMatrixId, documentId: model.id });
            }
            reloadGridOptions();
            document.dispatchEvent(new Event(TTPEvents.TTP_REFRESH_REPORTS));
            document.dispatchEvent(new Event(TTPEvents.REVALIDATE_PRODUCT_VARIANTS));
            document.dispatchEvent(new Event(RELOAD_TEST_RUN));
            onDelete?.();
        }
    };

    const handleDownloadClick = async (model: TestDocumentInfoModel) => {
        const url = ApiUrls.TestDocumentDownloadUrl + '?id=' + model.id;
        await downloadService.downloadFile(url);
    };

    const _renderGridToolbar = () => {
        const title = 'Add Document'
        return <AddButton onClick={handleAddClick} text={title} hint={title} />;
    };

    const _stateCellRender = (cellData: CellInfo<TestDocumentInfoModel>) => {
        const { data } = cellData;
        if (!data) return null;

        return enumHelper.DocumentStateLabels[data.state];
    };

    const _isFinalizedCellRender = (cellData: CellInfo<TestDocumentInfoModel>) => {
        if (!cellData.data) return null;
        return cellData.data.isFinalized ? 'Yes' : 'No';
    };

    const _actionsCellRender = (cellData: CellInfo<TestDocumentInfoModel>) => {
        const { data } = cellData;
        if (!data) return null;

        const buttons: ActionButton[] = [
            { name: 'edit', onClick: () => handleEditClick(data), visible: isEditable, title: 'Edit' },
            { name: 'download', onClick: () => handleDownloadClick(data), title: 'Download' },
            { name: 'delete', onClick: () => handleDeleteClick(data), visible: isEditable, title: 'Delete' }
        ];

        return <ActionCell buttons={buttons} />;
    };

    const columns = [
        new DataGridColumn({
            dataFieldName: 'documentType', caption: 'Type', width: 80,
            calculateCellValue: (m: TestDocumentInfoModel) => enumHelper.TestDocumentTypeLabels[m.documentType]
        }),
        new DataGridColumn({
            dataFieldName: 'uploadedDate', caption: 'Uploaded', width: 120,
            dataFieldType: DataGridColumnFieldType.dateTime
        }),
        new DataGridColumn({
            dataFieldName: 'uploadedFileName', caption: 'Orig. File Name',
            calculateCellValue: (m: TestDocumentInfoModel) => m.uploadedFileName || ''
        }),
        new DataGridColumn({ dataFieldName: 'state', caption: 'State', cellRenderTemplate: _stateCellRender, width: 100 }),
        new DataGridColumn({ dataFieldName: 'isFinalized', caption: 'Finalized', cellRenderTemplate: _isFinalizedCellRender, width: 80 }),
        new DataGridColumn({ caption: 'Actions', cellRenderTemplate: _actionsCellRender, width: 80 })
    ];

    return (
        <DxDataGrid<TestDocumentInfoModel>
            dataGridRef={gridRef}
            dataSource={options}
            columns={columns}
            disableGrouping
            disableExporting
            disableHeaderFilter
            disableFilterRow
            disableSearchPanel
            disableColumnChooser
            onRowDoubleClick={({ data }) => isEditable && onRowDblClickHandler(data!)}
            customToolBarRender={isEditable ? _renderGridToolbar : undefined}
        />
    );
}

export { TestDocumentsGrid };
