import { memo } from "react";
import { Button } from "reactstrap";
import { Label, ModalButtonType, Textarea } from "@Components";
import { useModalContext } from "@Components/Modal/ModalContext";
import { useForm } from "@Libs/Form";
import { checkFormValid } from "@Pages/Project/SubPages/ProjectDetails/Actions";
import { ConfirmationWithCommentDialogProps } from "./ConfirmationWithCommentDialog";
import { FormModel, formValidationSchema, initForm, formLabels } from "./FormModel";

type ConfirmationWithCommentDialogBodyProps = ConfirmationWithCommentDialogProps ;

export const ConfirmationWithCommentDialogBody = memo((props: ConfirmationWithCommentDialogBodyProps) => {
    const { close, closeWithAction } = useModalContext();

    const form = useForm<FormModel>(() => initForm(), formValidationSchema, formLabels);
    const { formFields, labels, requieredFields, handleChange, getValidationErrorsFor } = form;
    const { message } = props;

    if (typeof message === 'string' || Array.isArray(message)) {
        const messages = typeof message === 'string' ? [message] : message;

        return (
            <>
                {messages && messages.length > 0 &&
                <ul className="list-unstyled white-space-pre">
                    {messages.map(m => <li key={m}>{m}</li>)}
                </ul>
                }
                <div className="form-wrapper">
                    <Label
                        text={labels.comment}
                        required={requieredFields.comment}
                    />
                    <Textarea
                        style={{ marginTop: 10 }}
                        rows={4}
                        value={formFields.comment}
                        validationErrors={!!getValidationErrorsFor('comment') ? [getValidationErrorsFor('comment') as string] : []}
                        onChange={e => handleChange('comment', e.currentTarget.value)}
                    />
                       
                    <div style={{ borderTop: '1px solid #dee2e6', margin: '20px -15px' }}/>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            size='sm'
                            color='secondary'
                            onClick={close}
                            style={{ marginRight: 10}}
                        >
                            Cancel
                        </Button>
                        <Button
                            size='sm'
                            color='success'
                            onClick={async () => {
                                if (!checkFormValid(form)) return;
                                closeWithAction(ModalButtonType.Confirm, form.formFields.comment);
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </>
        );
    }

    return message || null;
});
