import React, {memo, MutableRefObject, useCallback, useEffect, useRef} from 'react';
import { NotificationModel } from '@Models';
import styles from './NotificationsList.module.scss';
import { DateTimeService } from '@Services';
import { generateLink } from '@Pages/Dashboard/UrlHelper';
import { useNavigate } from 'react-router-dom';

type NotificationsPopupProps = {
  visible: boolean;
  onHide: () => void;
  onDismiss: (val?: number) => void;
  notifications: NotificationModel[];
}

export const NotificationsList = memo(({visible, onHide, notifications, onDismiss}: NotificationsPopupProps) => {
  const listRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const navigate = useNavigate();

  const clickOutsideHandler = useCallback((event: any) => {
    const { current } = listRef;
    if (current && !current.contains(event.target)) {
      onHide();
    }
  }, [onHide]);

  useEffect(() => {
    document.addEventListener('click', clickOutsideHandler);

    return () => document.removeEventListener('click', clickOutsideHandler);
  }, [clickOutsideHandler])


    if (!visible) {
      return null;
    }

    return (
      <div ref={listRef} className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Notifications</h1>
          {
            !!notifications.length && <div className={styles.buttonAll} onClick={() => onDismiss()}>Dismiss all</div>
          }
        </div>
        <div className={styles.list}>
          {
             notifications?.map((item, index) => {
               const isDateExist = item.createdDate ? index > 0 && notifications[index-1].createdDate ? (DateTimeService.toUiDate(item.createdDate) !== DateTimeService.toUiDate(notifications[index-1].createdDate as Date)) : true : false;
               const notificationTitle = item.projectDisplayName ? `${item.projectCodeName}-${item.projectDisplayName} - ${item.description}` : item.description;
               const link = generateLink(item);
               const handleNavigateAndDismiss = () => {
                 if (link) {
                   navigate(link);
                 }
                 onHide();
               }

               return (
                <div key={item.id} className={styles.item}>
                  {isDateExist && <span className={styles.date}>{DateTimeService.toUiDate(item.createdDate as Date)}</span>}
                  <div className={styles.description}>
                    <span className={styles.text} onClick={() => handleNavigateAndDismiss()}>{notificationTitle}</span>
                    <div className={styles.button} onClick={() => onDismiss(item.id!)}>Dismiss</div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
)
