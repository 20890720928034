import React from 'react';
import ReactSelect, { GroupBase, Props, StylesConfig } from 'react-select';

export type Option = {
	label: string; 
	value: string;
};

export type SingleSelectProps<OptionType, GroupType extends GroupBase<OptionType>> = Props<OptionType, false, GroupType> & {
	invalid?: boolean;
};

export type MultiSelectProps<OptionType, GroupType extends GroupBase<OptionType>> = Props<OptionType, true, GroupType> & {
	invalid?: boolean;
};

export function ReactSelectStyles<OptionType, IsMulti extends boolean, GroupType extends GroupBase<OptionType>>(hasError: boolean): Partial<StylesConfig<OptionType, IsMulti, GroupType>> {
	return {
		control: (base) => ({
			...base,
			...{ minHeight: 'none', borderRadius: 0, fontSize: 12 },
			...(hasError ? { border: '2px solid red' } : { border: base.border })
		}),
		dropdownIndicator: base => ({ ...base, padding: '2px' }),
		input: base => ({ ...base, lineHeight: '17px' }),
		valueContainer: base => ({ ...base, padding: '0 8px' }),
		clearIndicator: base => ({ ...base, padding: 0 }),
		multiValue: base => ({ ...base, margin: '0 2px 0 0' }),
		menu: base => ({ ...base, zIndex: 29, fontSize: 12 }),
		menuPortal: base => ({ ...base, zIndex: 99999 }),
		container: base => ({...base, width: '100%'})
	};
}

export class Select<OptionType, GroupType extends GroupBase<OptionType> = GroupBase<OptionType>> extends React.PureComponent<SingleSelectProps<OptionType, GroupType>> {
	render() {
		const { invalid, isDisabled, placeholder } = this.props;
		
		return (
			<ReactSelect<OptionType, false, GroupType>
				{...this.props}
				styles={{ ...ReactSelectStyles<OptionType, false, GroupType>(!!invalid), ...(this.props.styles || {}) }}
				menuPortalTarget={document.body}
				placeholder={placeholder ? placeholder : (isDisabled ? '' : undefined)}
			/>
		);
	}
}

export class MultiSelect<OptionType, GroupType extends GroupBase<OptionType> = GroupBase<OptionType>> extends React.PureComponent<MultiSelectProps<OptionType, GroupType>> {
	render() {
		const { invalid } = this.props;

		return (
			<ReactSelect<OptionType, true, GroupType>
				{...this.props}
				styles={{ ...ReactSelectStyles<OptionType, true, GroupType>(!!invalid), ...(this.props.styles || {}) }}
				menuPortalTarget={document.body}
			/>
		);
	}
}
