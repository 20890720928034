interface ICallbackFunction extends Function {
    selectTimeOut?: number;
    amountOfCall?: number;
}

export class Extensions {

    static executeTimeout(callBack: Function, timeout: number, scope?: unknown, ...args: any[]) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const extendedFunction = <ICallbackFunction>callBack;
        if (extendedFunction.selectTimeOut) {
            clearTimeout(extendedFunction.selectTimeOut);
        }
        extendedFunction.selectTimeOut = window.setTimeout(() => {
            callBack.apply(scope, args);
        }, timeout);
    }

    static deepCopy(obj: unknown): unknown {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        let copy: any;

        if (null == obj || 'object' !== typeof obj) return obj;

        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        if (obj instanceof Array) {
            copy = [];
            for (let i = 0; i < obj.length; i++) {
                copy[i] = Extensions.deepCopy(obj[i]);
            }
            return copy;
        }

        if (obj instanceof Object) {
            copy = {};
            for (const attr in obj) {
                // eslint-disable-next-line  no-prototype-builtins, @typescript-eslint/no-explicit-any
                if (obj.hasOwnProperty(attr)) copy[attr] = Extensions.deepCopy((obj as any)[attr]);
            }
            return copy;
        }

        return {};
    }
}