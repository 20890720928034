import { memo } from 'react';

type LabelProps = {
    text?: string;
    className?: string;
    required?: boolean;
};

export const Label = memo(({ text, required, className }: LabelProps) => {
    return (
        <span className={className}>
            {text}{required ? <span style={{color: '#e32'}}>*</span> : ''}
        </span>
    );
});