import { createContext, useContext } from 'react';
import { ModalAction } from './ModalWindow';

export type CloseWithActionCb = (action: ModalAction, result?: unknown) => void;

type ModalContext = {
    close: () => void;
    closeWithAction: CloseWithActionCb; // TODO: resut to T
    setCustomCloseHandler: (handler: () => Promise<void> | void) => void;
}

type ModalContextProps = ModalContext;

const Context= createContext<ModalContext>({ close: () => {}, closeWithAction: () => {}, setCustomCloseHandler: () => {} });
const { Provider, Consumer } = Context;

const ModalContextProvider: React.FC<ModalContextProps> = (props) => {
    const { children, ...editContex} = props;

    return (
        <Provider value={editContex}>
            {children}
        </Provider>
    )
}

const useModalContext = () => useContext(Context);

export { ModalContextProvider, Consumer as ModalContextConsumer, useModalContext };
