import React from 'react';
import { IModalDialogContent, ModalButtonOptions, ModalButtonType, ModalDialogOptions, ModalWindow } from '@Components';
import { TestDocumentsDialogBody } from './TestDocumentsDialogBody';
import { SourceMode } from '@Pages/Components/ManageTestDocuments/TestDocumentsGrid';

export type TestDocumentsDialogProps = {
    testReportId: number;
    testReportNumber: string;
    labOfferRequestId?: number;
    typeTestPlanId?: number;
    typeTestMatrixId?: number;
    testReportConfigurationId?: number;
    isTestRunWithoutLOR?: boolean;
    sourceMode: SourceMode;
    isEditable: boolean;
    declarationOfConformityId?: number;
    onAdd?: () => void;
    onUpdate?: () => void;
    onDelete?: () => void;
};

export class TestDocumentsDialog extends React.Component<TestDocumentsDialogProps> implements IModalDialogContent<void> {
    render() {
        return (
            <TestDocumentsDialogBody
                testReportId={this.props.testReportId}
                testReportNumber={this.props.testReportNumber}
                labOfferRequestId={this.props.labOfferRequestId}
                typeTestPlanId={this.props.typeTestPlanId}
                typeTestMatrixId={this.props.typeTestMatrixId}
                testReportConfigurationId={this.props.testReportConfigurationId}
                isTestRunWithoutLOR={this.props.isTestRunWithoutLOR}
                sourceMode={this.props.sourceMode}
                isEditable={this.props.isEditable}
                declarationOfConformityId={this.props.declarationOfConformityId}
                onAdd={this.props.onAdd}
                onUpdate={this.props.onUpdate}
                onDelete={this.props.onDelete}
            />
        );
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        const buttons: ModalButtonOptions<void>[] = [];

        buttons.push(
            {
                title: 'Close',
                type: ModalButtonType.Close,
                onClick: async () => {
                    window.close();
                },
            }
        )

        return {
            title: "Document Management",
            buttons: buttons,
            width: '700px',
            bodyClassName: 'create-rule-dialog',
        };
    }
}
