import { DocumentInfoModel, apiClient, DocumentUpdateModel, DocumentCreateModel, FileParameter, DocumentConfigurationModel } from "@Models";
import { AxiosPromise } from "axios";
import { ApiUrls } from "@AppConstants";
import { IDocumentStore } from "../ManageDocumentsDialog/IDocumentStore";

export class FinancialEntryDocumentStore implements IDocumentStore {
    public financialEntryId: number;

    constructor(financialEntryId: number) {
        this.financialEntryId = financialEntryId;
    }

    loadGridData = (documentOwnerId: number): AxiosPromise<DocumentInfoModel[]> => {
        return apiClient.financialEntryDocumentGridGet({ financialEntryId: documentOwnerId });
    };

    loadDocument = (documentId: number): AxiosPromise<DocumentInfoModel> => {
        return apiClient.financialEntryDocumentGet({ documentId });
    };

    createDocument = (model: DocumentCreateModel): AxiosPromise<DocumentInfoModel> => {
        return apiClient.financialEntryDocumentPost(model);
    };

    updateDocument = (model: DocumentUpdateModel): AxiosPromise<DocumentInfoModel> => {
        return apiClient.financialEntryDocumentPut(model);
    };

    uploadFile = (guid: string, fileOwnerId: number, file: FileParameter) => {
        return apiClient.financialEntryDocumentUploadFilePut(fileOwnerId, file, guid);
    };

    deleteDocument = (documentId: number): AxiosPromise<void> => {
        return apiClient.financialEntryDocumentDelete({ documentId });
    };

    loadDocumentOwnerFullName = (documentOwnerId: number): AxiosPromise<string> => {
        return apiClient.financialEntryFullNameGet({ financialEntryId: documentOwnerId });
    };

    getDocumentRef = (documentModel: DocumentInfoModel) => {
        if (documentModel.isLink) {
            return documentModel.path;
        }
        else {
            const url = ApiUrls.FinancialEntryDocumentDownloadUrl + '?documentId=' + documentModel.id;
            return url;
        }
    }

    loadDocumentConfig = (documentName: string): AxiosPromise<DocumentConfigurationModel> => {
        return apiClient.financialEntryDocumentConfigGet({ documentName });
    };
}
