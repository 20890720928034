import { Component } from 'react';
import { Header, Modals } from '@Components';
import styles from './layout.module.scss';
import { Footer } from './Components/Footer/Footer';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <>
                <div className={styles.body}>
                    <div className={styles.header}>
                        <Header/>
                    </div>
                    <div className={styles.content} id="appContent">
                        {this.props.children}
                    </div>
                    <div className={styles.footer}>
                        <Footer />
                    </div>
                </div>
                <Modals/>
            </>
        );
    }
}
