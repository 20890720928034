import { PrincipalConfigurationModel } from '@Models';
import { Extensions } from '@Services';
import { PromiseQueue } from '@Helpers';
import { apiClient } from '../ApiClient';

type ConfigurationEntryKey = {
    group: string;
    key: string;
};

type ConfigurationEntryPending = ConfigurationEntryKey & {
    resolve: (value: string | null) => void;
    reject: (error: Error) => void;
    isLoading: boolean;
};

type ConfigurationEntry = ConfigurationEntryKey & {
    value: string | null;
};

class ConfigurationStore {

    private _pendingLoadConfigurations: ConfigurationEntryPending[] = [];
    private _localConfigurations: ConfigurationEntry[] = [];
    private _saveQueue: PromiseQueue<PrincipalConfigurationModel>;

    constructor() {
        this._saveQueue = new PromiseQueue<PrincipalConfigurationModel>();
    }

    public getValue(group: string, key: string): string | null {
        const localConfigValue = this._localConfigurations.find(x => x.group === group && x.key === key);
        return localConfigValue ? localConfigValue.value : null;
    }

    public get(group: string, key: string): Promise<string | null> {
        return new Promise<string | null>((resolve, reject) => {
            const localConfigValue = this._localConfigurations.find(x => x.group === group && x.key === key);
            if (localConfigValue) {
                resolve(localConfigValue.value);
                return;
            }
            this._pendingLoadConfigurations.push({
                key: key,
                group: group,
                resolve: resolve,
                reject: reject,
                isLoading: !!this._pendingLoadConfigurations.find(x => x.group === group && x.key === key)
            });
            Extensions.executeTimeout(this._loadPendingConfiguration, 1, this);
        });
    }
    // eslint-disable-next-line @typescript-eslint/require-await
    public async set(group: string, key: string, value: string | null) {
        this._saveQueue.add({
            key: key,
            group: group,
            value: value === null ? void 0 : value
        }, this._saveConfigurationInternal);

        this._localConfigurations = this._localConfigurations.map(config => {
            if(config.group === group && config.key === key){
              config.value = value;
            }
            return config;
        })
    }

    private async _loadPendingConfiguration() {
        const configurationsToLoad = this._pendingLoadConfigurations.filter(x => !x.isLoading);
        configurationsToLoad.forEach(k => k.isLoading = true);
        const loadKeys = configurationsToLoad.map(k => k.group + '.' + k.key);
        const distinctKeys = [...Array.from(new Set(loadKeys))];
        if (!distinctKeys.length) return;
        const params = {
            keys: distinctKeys.join(',')
        };


        try {
            const {data: result} = await apiClient.principalConfigurationGet(params);
            result.forEach(r => {
                this._localConfigurations.push({
                    group: r.group,
                    key: r.key,
                    value: r.value || null
                });
            });
            configurationsToLoad.forEach(c => {
                const r = result.find(r => r.group === c.group && r.key === c.key);
                this._notifyPendingHandler(c.group, c.key, h => h.resolve((r && r.value) || null));
            });

        } catch (er) {
            const error = new Error((er as unknown as { toString: () => string}).toString());
            configurationsToLoad.forEach(c => {
                this._notifyPendingHandler(c.group, c.key, h => h.reject(error));
            });
        }
    }

    private _notifyPendingHandler(group: string, key: string, callback: (h: ConfigurationEntryPending) => void) {
        for (let i = this._pendingLoadConfigurations.length - 1; i >= 0; i--) {
            const handler = this._pendingLoadConfigurations[i];
            if (!(handler.group === group && handler.key === key)) continue;
            callback(handler);
            this._pendingLoadConfigurations.splice(i, 1);
        }
    }

    private async _saveConfigurationInternal(configuration: PrincipalConfigurationModel) {
        await apiClient.principalConfigurationPut(configuration);
    }
}

export const configurationStore = new ConfigurationStore();
