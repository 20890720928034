import { DocumentInfoModel, DocumentState } from '@Models';
import { DocType } from './DocumentForm';

import { FieldLabels, ValidationSchema } from '@Libs/Form';

export type DocumentFormModel = {
    documentName: string;
    state: DocumentState;
    documentFile: File | undefined;
    docType: DocType;
    link: string;
    uploadedFileName: string | undefined;
};

export function initCreateDocumentForm(): DocumentFormModel {
    return {
        documentName: '',
        state: DocumentState.Draft,
        documentFile: undefined,
        docType: DocType.File,
        link: '',
        uploadedFileName: undefined
    }
}

export function initUpdateDocumentForm(document: DocumentInfoModel): DocumentFormModel {
    return {
        documentName:  document.name || '',
        state: document.state,
        documentFile: undefined,
        docType: document?.isLink ? DocType.Link : DocType.File,
        link: document.path,
        uploadedFileName: document.uploadedFileName
    }
}

export const documentFormValidationSchema: ValidationSchema<DocumentFormModel> = {
    documentName: { requiered: true }
}

export const documentFormLabels: FieldLabels<DocumentFormModel> = {
    documentName: 'Name',
    state: 'State',
    docType: 'Type',
    link: 'Link'
    // documentFile: File;
}