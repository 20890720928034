import { memo } from "react";
import { Progress, Button } from "reactstrap";
import { useModalContext } from "@Components/Modal/ModalContext";
import styles from "./ProgressBarDialogBody.module.scss";

type ProgressBarDialogBodyProps = {
    value: number;
    maxValue?: number;
    isLoading?: boolean;
    message?: string;
    color?: string;
    onClose?: () => void;
};

export const ProgressBarDialogBody = memo(({ value, message, maxValue, color, isLoading, onClose }: ProgressBarDialogBodyProps) => {
    const { close, setCustomCloseHandler } = useModalContext();

    const handleClose = async () => {
        if (isLoading) return;

        onClose?.();
        close();
    }

    setCustomCloseHandler(handleClose);

    return (
        <>
            <Progress
                value={value}
                max={maxValue}
                color={color}
                animated
            />
            <div className={styles.messageWrapper}>
                {message}
            </div>
            <hr />
            <div className={styles.buttonsWrapper}>
                <Button
                    size="sm"
                    color="success"
                    disabled={isLoading}
                    onClick={handleClose}
                >
                    Close
                </Button>
            </div>
        </>
    );
});