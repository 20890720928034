import { makeObservable, observable } from 'mobx';
import { BaseFormModel, isRequired, displayName, isDate, hasMaxLength } from '@Helpers';
import { TestReportCategory } from '@Models';

export class FormModel extends BaseFormModel<FormModel> {
    constructor() {
        super();
        makeObservable(this);
    }

    @observable
    @displayName('Test Report Info')
    info?: string;

    @observable
    @isRequired()
    @displayName('Category')
    category: TestReportCategory;

    @observable
    @isRequired()
    @hasMaxLength(64)
    @displayName('Number')
    number: string = '';

    @observable
    @isDate()
    @displayName('Issued Date')
    issuedDate: string;

    @observable
    @displayName('Lab Offer Request')
    labOfferRequestId?: string;

    @observable
    @displayName('Test Lab')
    testLabId?: string;

    @observable
    @isRequired()
    @displayName('Issued by')
    issuedLabId?: string;

    @observable
    @displayName('Technology Center')
    technologyCenterId?: string;
}
