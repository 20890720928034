import React from 'react';
import { devInfoStore } from '@GlobalStores';
import styles from './Footer.module.scss';

export class Footer extends React.PureComponent {
    render() {
        return (
            <div className={styles.container}>
                <div>© 2023 ABB. All Rights Reserved.</div>
                <div>{devInfoStore.appVersion}</div>
            </div>
        );
    }
}
