/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-mixed-operators */
import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse, CancelToken, CancelTokenSource, ResponseType } from 'axios/index';
import { AuthStore, authStore } from '@GlobalStores';
// import { modalService } from '@Components/Modal/ModalService';
import { PromiseCompletion } from '@Helpers';
import { DateTimeService } from './DateTimeService';
import { errorHandleService } from './ErrorHandleService';

export type ApiHeaders = {
    [key: string]: string;
}

export interface IAjaxOptions {
    responseType?: ResponseType;
    hideModalLoader?: boolean;
    requestKey?: string;
    transformDateTime?: boolean;
    operationName?: string;
    noDateTransform?: boolean;
    suppressErrorHandling?: boolean;
    completion?: PromiseCompletion | PromiseCompletion[];
    cancelDuplicatedRequests?: boolean;
    cancellationToken?: CancelToken;
    customApiHeader?: ApiHeaders;
    timeout?: number;
}

type AxiosMethodDefinition = (url: string, data?: any, config?: AxiosRequestConfig) => AxiosPromise;
type AxiosMethodChooser = (instance: AxiosInstance) => AxiosMethodDefinition;

export type Response = AxiosResponse;
export type ResponseError = AxiosError;
export type ResponseInterceptor = {
    response: (value: Response) => Response | Promise<Response>,
    error: (err: ResponseError) => ResponseError
};

export enum AxiosMethod {
    GET = 'GET',
    DELETE = 'DELETE',
    HEAD = 'HEAD',
    OPTIONS = 'OPTIONS',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
}

export enum ApiHeadersKeys {
    CacheControl = 'Cache-Control',
    Authorization = 'Authorization',
    AppImpersonationRoles = 'App-Impersonation-Roles',
    AppImpersonationPrincipalId = 'App-Impersonation-PrincipalId'
}

export class ApiService {
    private static _responseInterceptors: ResponseInterceptor[] = [];
    private static _cancelTokenCollection: Map<string, CancelTokenSource> = new Map();

    public static addResponseInterceptor(interceptor: ResponseInterceptor) {
        ApiService._responseInterceptors.push(interceptor);
    }

    private static _addAndChechCancelationToken(tokenKey: string, source: CancelTokenSource) {
        const duplicateToken = ApiService._cancelTokenCollection.get(tokenKey);
        if (duplicateToken) {
            duplicateToken.cancel();
        }
        ApiService._cancelTokenCollection.set(tokenKey, source);
    }

    private static _callMethod<TResponse>(methodChooser: AxiosMethodChooser, url: string, data?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        const responseType = options && options.responseType || 'json';

        const requestKey = options?.requestKey ? options.requestKey : this._getAbsoluteUrl(url);

        let cancelToken = options?.cancellationToken;
        if (options?.cancelDuplicatedRequests) {
            const source = axios.CancelToken.source();
            cancelToken = source.token;
            ApiService._addAndChechCancelationToken(requestKey, source);
        }
        const instance = ApiService._getInstance(responseType, options, cancelToken);

        const result = methodChooser(instance)(this._getAbsoluteUrl(url), data);
        const isHandleError = !(options && options.suppressErrorHandling);

        if (options && options.completion) {
            if (Array.isArray(options.completion)) {
                options.completion.forEach(c => c.subscribe(result));
            } else {
                void options.completion.subscribe(result);
            }
        }
        result.then((data) => {
            try {
                let response = typeof data === 'string' ? JSON.parse(data) : data;
                return ApiService.typeCheck(response && typeof response === 'string' ? JSON.parse(response) : response);
            } catch (error) {
                throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
            }
        })
        result.catch((error: any) => {
            if (isHandleError) this.handleError(url, error);
        });

        return result;
    }

    public static getRequestUrl(baseUrl: string, operation: any) {
        console.log(baseUrl, operation);
    }

    public static sendRequest<TResponse>(method: AxiosMethod, url: string, data?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        switch (method) {
            case AxiosMethod.POST:
                return ApiService.postTypedData<TResponse>(url, data, options);
            case AxiosMethod.GET:
                return ApiService.getTypedData<TResponse>(url, data, options);
            case AxiosMethod.PUT:
                return ApiService.putTypedData<TResponse>(url, data, options);
            case AxiosMethod.DELETE:
                return ApiService.deleteData<TResponse>(url, data, options);
            case AxiosMethod.PATCH:
                return ApiService.patchTypedData<TResponse>(url, data, options);
            default:
                return Promise.reject();
        }
    }

    public static getData<TResponse = unknown>(url: string, getData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return this.getTypedData<TResponse>(url, getData, options);
    }

    public static getTypedData<TResponse>(url: string, getData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.get, url, { params: getData }, options);
    }

    public static putData<TResponse = unknown>(url: string, putData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.put, url, putData, options);
    }

    public static putTypedData<TResponse>(url: string, putData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.put, url, putData, options);
    }

    public static postData<TResponse = unknown>(url: string, postData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.post, url, postData, options);
    }

    public static postTypedData<TResponse>(url: string, postData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.post, url, postData, options);
    }

    public static patchTypedData<TResponse>(url: string, patchData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.patch, url, patchData, options);
    }

    public static deleteData<TResponse>(url: string, deleteData?: any, options?: IAjaxOptions): AxiosPromise<TResponse> {
        return ApiService._callMethod<TResponse>((instance: AxiosInstance) => instance.delete, url, { params: deleteData }, options);
    }

    public static handleError(url: string, error: ResponseError) {
        if (axios.isCancel(error)) {
            console.log(`Request canceled: ${url}\nMessage: ${error.message}`);
            return;
        }
        if (error && error.response && error.response.status === 409) {
            return;
        }
        void errorHandleService.showError(url, error);
    }

    public static toQueryString(params: { [key: string]: string }) {
        if (typeof (params) !== 'object') return '';
        return `?${Object.keys(params).map(k => `${k}=${params[k]}`).join('&')}`;
    }

    static typeCheck = (el: any, isDate?: boolean) => {
        if (!el) return el;
        let typeEl = el;
        switch (typeof el) {
            case 'string':
                typeEl = ApiService.strCheck(el, isDate);
                break;
            case 'object':
                typeEl = Array.isArray(el) ? ApiService.arrCheck(el) : ApiService.objCheck(el);
                break;
        }
        return typeEl;
    };

    private static strCheck = (str: string, isDate?: boolean) => {
        if (isDate && DateTimeService.ISO_8601_date.test(str)) return DateTimeService.fromString(str);
        return str;
    };

    private static arrCheck = (array: any) => {
        return array.map((el: any) => {
            return ApiService.typeCheck(el);
        });
    };

    private static objCheck = (obj: any) => {
        Object.keys(obj).forEach(key => {
            obj[key] = ApiService.typeCheck(obj[key], key.indexOf('date') === 0 || key.indexOf('Date') !== -1);
        });
        return obj;
    };

    private static _getInstance(responseType: ResponseType = 'json', options?: IAjaxOptions, cancelToken?: CancelToken): AxiosInstance {
        const headers = ApiService._createRequestHeaders(options);
        // tries to transform even in case of error      
        // const transformResponse = (data: unknown) => {
        //     if (options && options.noDateTransform) return data;
        //     try {
        //         let response = typeof data === 'string' ? JSON.parse(data) : data;
        //         return ApiService.typeCheck(response && typeof response === 'string' ? JSON.parse(response) : response);
        //     } catch (error) {
        //         throw Error(`[requestClient] Error parsing response JSON data - ${JSON.stringify(error)}`);
        //     }
        // };

        const axiosConfig: AxiosRequestConfig = {
            responseType,
            headers,
            // transformResponse,
            cancelToken,
            timeout: options?.timeout
        };

        const axiosInstance = axios.create(axiosConfig);

        this._useInterceptors(axiosInstance, responseType);
        ApiService._responseInterceptors.forEach(x => axiosInstance.interceptors.response.use(x.response, x.error));

        return axiosInstance;
    }

    private static _useInterceptors(axiosInstance: AxiosInstance, responseType: string) {
        axiosInstance.interceptors.response.use((res) => {
            const contentType = res.headers['content-type'];
            if (contentType && contentType.indexOf('text/html') > -1 && responseType === 'json') {
                throw new Error('API call to ' + res.config.url + ' returned not expected content type: ' + contentType);
            }

            return res;
        }, (err) => {
            if (err.response && err.response.status === 401) {
                return new Promise(() => {
                    AuthStore.login();
                });
            }
            throw err;
        });
    }

    private static _createRequestHeaders(options?: IAjaxOptions): ApiHeaders {
        const headers = options && options.customApiHeader || {} as ApiHeaders;
        headers[ApiHeadersKeys.CacheControl] = headers[ApiHeadersKeys.CacheControl] || 'no-cache';
        if (authStore.currentToken) {
            headers[ApiHeadersKeys.Authorization] = headers[ApiHeadersKeys.Authorization] || 'Bearer ' + authStore.currentToken;
        }
        if (authStore.impersonatedPrincipal && authStore.impersonatedPrincipal.principalId) {
            headers[ApiHeadersKeys.AppImpersonationPrincipalId] = authStore.impersonatedPrincipal.principalId.toString();
        }

        return headers;
    }

    private static _getAbsoluteUrl(url: string): string {
        let relariveUrl = url;
        if (relariveUrl && relariveUrl[0] !== '/') relariveUrl = '/' + url;
        if (!relariveUrl.startsWith('/api')) relariveUrl = '/api' + url;
        return window.location.origin + relariveUrl;
    }

    public static getDynamicUrl(url: string, params?: any & {}): string {
        let _url = url;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const valueRaw = params[key];
                const value = valueRaw instanceof Date ? DateTimeService.toQueryParam(valueRaw) : valueRaw;
                _url = _url.replace(`{${key}}`, encodeURIComponent(value as string));
            }
        }
        return _url;
    }
}