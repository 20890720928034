import React from 'react';
import { FiLogOut } from 'react-icons/fi';

import styles from './Userbar.module.scss';

import { Impersonation } from './Components/Impersonation/Impersonation';
import { HelpLink } from './Components/HelpLink';
import { Notifications } from "@Components/Userbar/Components/Notifications/Notifications";

export type UserbarProps = {
    name: string;
    onLogout: () => void;
};

export class Userbar extends React.Component<UserbarProps> {
    render() {
        const {name, onLogout} = this.props;

        return (
            <div className={styles.bar}>
                <div className={styles.name}>{name}</div>
                <Impersonation buttonClassName={styles.button} />
                <Notifications buttonClassName={styles.button} />
                <HelpLink buttonClassName={styles.button}/>
                <div title="Logout" className={styles.button} onClick={onLogout}>
                    <FiLogOut size={16}/>
                </div>
            </div>
        );
    }
}
