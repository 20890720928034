/* eslint-disable no-unused-vars */
import React from 'react';
import { observer} from 'mobx-react';
import {
    observable,
    action,
    makeObservable
} from 'mobx';
import {
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';

import { ModalWindow } from './ModalWindow';

export type ButtonColor = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link';

type ClickHandler<T> = (window: ModalWindow<T>) => void | Promise<void>;
type ModalAction = ModalButtonType | string;

type ModalButtonProps<T> = ModalButtonOptions<T> & {
    window: ModalWindow<T>;
    innerRef?: React.Ref<HTMLButtonElement>;
};

export type ModalButtonOptions<T> = {
    type: ModalAction;
    color?: ButtonColor;
    title?: string;
    outline?: boolean;
    isDisabled?: boolean;
    setFocus?: boolean;
    alignLeft?: boolean;
    children?: ModalButtonOptions<T>[];
    onClick: ClickHandler<T>;
};

export enum ModalButtonType {
    Ok = 'Ok',
    Cancel = 'Cancel',
    Close = 'Close',
    Save = 'Save',
    Update = 'Update',
    Remove = 'Remove',
    Confirm = 'Confirm',
    Reset = 'Reset',
    Add = 'Add',
    CreateNew = 'CreateNew',
    Send = 'Send',
    Delete = 'Delete',
    Yes = 'Yes',
    No = 'No',
    Submit = 'Submit',
    Apply = 'Apply',
    Download = 'Download',
    RunNow = 'RunNow',
    SaveAndRunNow = 'SaveAndRunNow'
}

@observer
export class ModalButton<T> extends React.Component<ModalButtonProps<T>, {}> {
    @observable private _isWorking: boolean = false;
    @observable private _isDropdownOpen: boolean = false;
    
    constructor(props: ModalButtonProps<T>){
        super(props);
        makeObservable(this);
    }

    render() {
        const {innerRef, title, isDisabled, type, color, outline, children, onClick} = this.props;

        let buttonColor: ButtonColor = 'secondary';
        if (type === ModalButtonType.Add || type === ModalButtonType.Save || type === ModalButtonType.Update || type === ModalButtonType.Download) {
            buttonColor = 'success';
        }
        if (type === ModalButtonType.Yes || type === ModalButtonType.Apply) {
            buttonColor = 'primary';
        }
        if (type === ModalButtonType.Remove || type === ModalButtonType.No || type === ModalButtonType.Reset) {
            buttonColor = 'danger';
        }
        if (color) {
            buttonColor = color;
        }

        const disabled = isDisabled || this._isWorking;

        if (children?.length) {
            return (
                <ButtonDropdown
                    isOpen={this._isDropdownOpen}
                    toggle={this._onToggleDropdown}
                    color={buttonColor}
                    disabled={disabled}
                    className="job-seft-test job-seft-test-dropdown" size="md"
                >
                    <Button
                        id="caret"
                        onClick={() => this._onClick(onClick)}
                        disabled={disabled}
                        color={buttonColor}
                        className={this._isWorking ? 'btn-modal-loader' : ''}
                    >
                        {title ?? type}
                    </Button>
                    <DropdownToggle caret color={buttonColor} size="md"/>
                    <DropdownMenu>
                        {children.map(c =>
                            <DropdownItem
                                key={c.type}
                                onClick={() => this._onClick(c.onClick)}
                                color={buttonColor}
                                outline={outline}
                                disabled={disabled}
                            >
                                {c.title ?? c.type}
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </ButtonDropdown>
            );
        }

        return (
            <Button
                className={this._isWorking ? 'btn-modal-loader' : ''}
                size="sm"
                innerRef={innerRef}
                color={buttonColor}
                outline={outline}
                disabled={isDisabled || this._isWorking}
                onClick={() => this._onClick(onClick)}
            >
                {title ?? type}
            </Button>
        );
    }

    @action.bound
    private _onToggleDropdown() {
        this._isDropdownOpen = !this._isDropdownOpen;
    }

    private async _onClick(onClick: ClickHandler<T>) {
        const {window} = this.props;
        const result = onClick(window);
        if (result) {
            this._isWorking = true;
            try {
                await result;
            } finally {
                this._isWorking = false;
            }
        }
    }
}