import { useEffect, useState } from 'react';
import { TestDocumentsGrid } from '@PageComponents/ManageTestDocuments/TestDocumentsGrid';
import { apiClient, TestReportCategory } from '@Models';
import { TestDocumentsDialogProps } from './TestDocumentsDialog';

export type TestDocumentsDialogBodyProps = TestDocumentsDialogProps;

export function TestDocumentsDialogBody({ testReportId, testReportNumber, labOfferRequestId, typeTestPlanId, typeTestMatrixId, testReportConfigurationId, isTestRunWithoutLOR, sourceMode, isEditable, declarationOfConformityId, onAdd, onUpdate, onDelete }: TestDocumentsDialogBodyProps) {
    const [reportCategory, setReportCategory] = useState<TestReportCategory>(TestReportCategory.Success);

    useEffect(() => {
        apiClient.testReportGet({ id: testReportId }).then((r) => {
            setReportCategory(r.data.category);
        });
    }, [testReportId]);

    return (
        <TestDocumentsGrid
            documentOwnerId={testReportId}
            documentOwnerDisplayName={testReportNumber}
            reportCategory={reportCategory}
            labOfferRequestId={labOfferRequestId}
            typeTestPlanId={typeTestPlanId}
            typeTestMatrixId={typeTestMatrixId}
            testReportConfigurationId={testReportConfigurationId}
            isTestRunWithoutLOR={isTestRunWithoutLOR}
            sourceMode={sourceMode}
            isEditable={isEditable}
            declarationOfConformityId={declarationOfConformityId}
            onAdd={onAdd}
            onUpdate={onUpdate}
            onDelete={onDelete}
        />
    );
}
