import {useCallback, useEffect, useState} from 'react';
import { AxiosPromise } from 'axios';
import { LookupModel } from '@Models';
import { Select } from '@Components';
import {usePrevious} from "../../../Hooks/Hooks";

export type LookupModelPickerBaseProps = {
    isParamsRequired?: boolean;
    loadDataParams?: object;
    disabled?: boolean;
    defaultValue?: number | null;
    isClearable?: boolean;
    className?: string;
    reloadOn?: string;
    onChange?: (selectedOption: LookupModel | null) => void;
    invalid?: boolean;
    onDataLoaded?: (selectedOption: LookupModel | null) => void;
};

export type LookupModelPickerProps = {
    loadData: (params?: object) => AxiosPromise<LookupModel[]>;
} & LookupModelPickerBaseProps;

export function LookupModelPicker({ isParamsRequired, loadDataParams, disabled, defaultValue, className, reloadOn, onChange, loadData, onDataLoaded, ...rest  }: LookupModelPickerProps) {
    const [selectedValue, setSelectedValue] = useState<LookupModel | null | undefined>(undefined);
    const [options, setOptions] = useState<LookupModel[]>([]);
    const prevValue = usePrevious<number | null | undefined>(defaultValue);
    const prevOptions = usePrevious<LookupModel[]>(options);
    const loadOptions = useCallback(async () => {
        if (isParamsRequired && !loadDataParams) {
            setOptions([]);
            setSelectedValue(null);
        } else {
            const { data } = await loadData(loadDataParams);
            setOptions(data);

            if (defaultValue) {
                const selected = data.find(o => o.value === defaultValue);
                if (selected) {
                    setSelectedValue(selected);
                    if (onDataLoaded) {
                        onDataLoaded(selected);
                    }
                }
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isParamsRequired, loadDataParams, loadData, onDataLoaded]);

    const handleSubscribtion = (e: CustomEvent) => {
        loadData(loadDataParams).then(({ data }) => {
            setOptions(data);
            const createdOption = data.find(o => o.value === e.detail);
            if (createdOption) {
                setSelectedValue(createdOption);
            }
        })
    };

    useEffect(() => {
        if (reloadOn) {
            document.addEventListener(reloadOn, handleSubscribtion as EventListener);
            return () => { document.removeEventListener(reloadOn, handleSubscribtion as EventListener); };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        loadOptions();
    }, [loadOptions]);

    useEffect(() => {
        if((defaultValue !== prevValue || prevOptions?.length !== options.length)){
            if (!defaultValue) {
                setSelectedValue(null);
            } else {
                const option = options.find(x => x.value === defaultValue);
                if (option) setSelectedValue(option);
            }
        }
    }, [options, defaultValue, selectedValue, prevValue, prevOptions?.length]);

    const _handleChange = (value: LookupModel | null) => {
        setSelectedValue(value)
        onChange?.(value);
    }

    return (
        <Select<LookupModel>
            isDisabled={disabled}
            value={selectedValue}
            options={options}
            onChange={_handleChange}
            classNamePrefix={className}
            {...rest}
        />
    );
}

